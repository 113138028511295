import React from 'react'
import warning from 'tiny-warning'
import type { ColumnInstance, Cell } from '../types'

type TableElements = 'Header' | 'Cell'

interface BaseProps<K extends TableElements> {
  type: K
}
interface CellProps<D> extends BaseProps<'Cell'> {
  column: Cell<D>
  children: any[]
}
interface HeaderProps<D> extends BaseProps<'Header'> {
  column: ColumnInstance<D>
  children: any[]
}

const isString = (el: any): el is string => {
  return typeof el === 'string'
}

export type Props<D> = CellProps<D> | HeaderProps<D>

const elements = {
  Header: { name: 'header', getter: 'getHeaderProps' },
  Cell: { name: 'cell', getter: 'getCellProps' },
} as const

const getElementTitle = (elementType: string, innerComponent: { props?: { title: string } }, base: Cell<any> | ColumnInstance<any>) => {
  if (elementType === 'Cell') {
    // If the value of the cell is null, we need to handle that case
    if ((base as Cell<any>).value === undefined || (base as Cell<any>).value === null) {
      return ''
    }
    return innerComponent?.props?.title ?? (base as Cell<any>).value
  }
  return innerComponent.props?.title
}

const isBuiltInElement = (component: any) => typeof component.type === 'string'

const isReactPrimitive = (innerComponent: any) => innerComponent.type === undefined

const getInnerElement = (innerComponent: any) => {
  // prettier-ignore
  return innerComponent === null || innerComponent === undefined
        ? null
        : isReactPrimitive(innerComponent)
        ? innerComponent
        : React.cloneElement(innerComponent, { title: undefined })
}

const getInnerComponent = (Cell: any) => {
  if (isBuiltInElement(Cell) || typeof Cell.type === 'object') {
    return Cell
  }
  return Boolean(Cell.type?.prototype?.isReactComponent) ? new Cell.type(Cell.props) : Cell.type({ ...Cell.props })
}

export const getElementProps = <D extends AnyObject>(base: Cell<D> | ColumnInstance<D>, children: any[], elementType: TableElements) => {
  let title: string
  let element: React.ReactNode
  const column = elementType === 'Header' ? (base as ColumnInstance<D>) : (base as Cell<D>).column
  const options = elementType === 'Header' ? (column.getSortByToggleProps ? column.getSortByToggleProps() : undefined) : undefined
  const typeData = elements[elementType]
  const el = column[elementType]
  if (isString(el)) {
    title = el
    element = column[elementType]
  } else {
    const Cell = base.render(elementType, { key: `column-${typeData.name}` }) as React.ReactComponentElement<any, { title?: string }>
    const innerComponent = getInnerComponent(Cell)
    title = isBuiltInElement(Cell) ? Cell.props.title : getElementTitle(elementType, innerComponent, base)
    element = getInnerElement(innerComponent)
    const newElement = element
    element = { ...Cell, type: () => newElement }
    // Since we're wrapping a class instance in a function component,
    // we need to set the prototype so React doesn't get sad.
    if (Boolean(Cell.type.prototype?.isReactComponent)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.type.prototype = React.Component.prototype
    }
  }

  warning(
    title !== undefined,
    `Column ${typeData.name} ${column.id} has no title. \`${elementType}\` must be a string or render a component with the HTML attribute \`title\` set for a11y.`,
  )

  const props = {
    ...(base as any)[typeData.getter](options),
    title: title,
  }
  return {
    ...props,
    column,
    children: [element, ...children],
  }
}
