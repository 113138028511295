import type { ButtonHTMLAttributes, PropsWithRef, ComponentType, Ref } from 'react'
import React from 'react'
import type { DefaultTheme, StyledComponent } from 'styled-components'
import styled, { css } from 'styled-components'
import { defaultTheme } from '@themes'
import { Palette, fontStyles } from '@style'
import tinycolor from '@ctrl/tinycolor'

type ExposedButtonAttributes = PropsWithRef<Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'autoFocus' | 'disabled' | 'children' | 'className'>>
type ButtonSize = 'small' | 'large'
type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'destructive' | 'icon'
export interface ButtonPropsBase<TButtonType extends ButtonType> extends ExposedButtonAttributes {
  /**
   * Theme-driven style of the button
   */
  buttonType?: TButtonType

  size?: ButtonSize
  onClick?: () => void
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: React.ComponentType<{}>
}

type ButtonProps<TButtonType extends ButtonType> = TButtonType extends 'icon'
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    ButtonPropsBase<TButtonType> & { icon: React.ComponentType<{}>; children?: never; 'aria-label': string }
  : ButtonPropsBase<TButtonType>

type StyleAttributes = {
  color?: string
  background?: string
  border: string
}

type ButtonStyles = {
  fontWeight: string
  normal: StyleAttributes
  hover: StyleAttributes
  active: StyleAttributes
}

const isSmall = (props: ButtonProps<ButtonType>) => {
  return props.size === 'small'
}
const buttonStyles = (style: ButtonStyles) => css`
  box-sizing: border-box;
  font-weight: ${style.fontWeight};
  &:disabled {
    opacity: 0.4;
  }
  background-color: ${style.normal.background};
  border: ${style.normal.border};
  color: ${style.normal.color};
  &:not(:disabled) {
    &:active {
      background-color: ${style.active.background};
      border: ${style.active.border};
      color: ${style.active.color ?? style.normal.color};
    }
    &:hover:not(:active) {
      background-color: ${style.hover.background};
      border: ${style.hover.border};
      color: ${style.hover.color ?? style.normal.color};
    }
  }
`

const buttonStyleLookup: Record<Exclude<ButtonType, 'destructive'>, (theme: DefaultTheme) => ButtonStyles> = {
  primary: theme => ({
    fontWeight: '500',
    normal: {
      color: theme.colors.secondary.hex,
      background: theme.colors.primary.hex,
      border: `solid 1px ${theme.colors.primary.hex}`,
    },
    hover: {
      background: theme.colors.alternate.hex,
      border: `solid 1px ${theme.colors.alternate.hex}`,
    },
    active: {
      background: theme.colors.quaternary.hex,
      border: `solid 1px ${theme.colors.quaternary.hex}`,
    },
  }),
  secondary: theme => ({
    fontWeight: 'normal',
    normal: {
      color: theme.colors.quaternary.hex,
      background: theme.neutrals.primary.hex,
      border: `solid 1px ${theme.colors.primary.hex}`,
    },
    hover: {
      background: theme.colors.septenary.hex,
      border: `solid 1px ${theme.colors.primary.hex}`,
    },
    active: {
      background: theme.colors.senary.hex,
      border: `solid 1px ${theme.colors.quaternary.hex}`,
    },
  }),
  tertiary: theme => ({
    fontWeight: 'normal',
    normal: {
      color: theme.neutrals.alternate.hex,
      background: theme.neutrals.tertiary.hex,
      border: `solid 1px ${theme.neutrals.tertiary.hex}`,
    },
    hover: {
      background: theme.neutrals.quaternary.hex,
      border: `solid 1px ${theme.neutrals.quaternary.hex}`,
    },
    active: {
      background: theme.neutrals.quinary.hex,
      border: `solid 1px ${theme.neutrals.quinary.hex}`,
    },
  }),
  quaternary: theme => ({
    fontWeight: 'normal',
    normal: {
      color: theme.neutrals.alternate.hex,
      background: theme.neutrals.primary.hex,
      border: `solid 1px ${theme.neutrals.quaternary.hex}`,
    },
    hover: {
      background: theme.neutrals.secondary.hex,
      border: `solid 1px ${theme.neutrals.senary.hex}`,
    },
    active: {
      background: theme.neutrals.septenary.hex,
      border: `solid 1px ${theme.neutrals.senary.hex}`,
    },
  }),
  icon: theme => ({
    fontWeight: 'normal',
    normal: {
      color: theme.neutrals.alternate.hex,
      background: theme.neutrals.primary.hex,
      border: `solid 1px ${theme.neutrals.primary.hex}`,
    },
    hover: {
      background: theme.neutrals.secondary.hex,
      border: `solid 1px ${theme.neutrals.secondary.hex}`,
    },
    active: {
      background: theme.neutrals.septenary.hex,
      border: `solid 1px ${theme.neutrals.septenary.hex}`,
    },
  }),
}

const getButtonStyle = (type: NonNullable<ButtonProps<ButtonType>['buttonType']>, theme: DefaultTheme) => {
  if (type === 'destructive') {
    return css`
      &:disabled {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25;
        min-width: 5.875rem;
        min-height: 2.75rem;
        color: ${Palette.neutral[0].hex};
        background-color: ${Palette.red[80].hex};
        border: none;
        opacity: 0.4;
      }
      &:not(:disabled) {
        font-size: 1rem;
        line-height: 1.25;
        min-width: 5.875rem;
        min-height: 2.75rem;
        background-color: ${Palette.red[80].hex};
        border: none;
        color: ${Palette.neutral[0].hex};
        font-weight: 500;
        &:active {
          background-color: ${Palette.red[100].hex};
          border: none;
        }
        &:hover:not(:active) {
          background-color: ${Palette.red[60].hex};
        }
      }
    `
  }
  return buttonStyles(buttonStyleLookup[type](theme))
}
const shadow = tinycolor(Palette.neutral[100].rgb).setAlpha(0.25).toRgbString()

// eslint-disable-next-line @typescript-eslint/ban-types
const bButton: StyledComponent<ComponentType<ExposedButtonAttributes & { ref: Ref<HTMLButtonElement> }>, DefaultTheme, {}, never> = styled.button``

export const Button = styled(bButton).attrs<ButtonProps<ButtonType>>(p => {
  // If there's an icon, render the icon with the children
  const children =
    p.icon !== undefined ? (
      <>
        {React.createElement(p.icon, { role: undefined } as any)}
        {p.buttonType !== 'icon' && p.children}
      </>
    ) : (
      p.children
    )
  return {
    small: isSmall(p),
    children,
  }
})<ButtonProps<ButtonType>>`
  ${({ buttonType, small = false, icon }: ButtonProps<ButtonType> & { small?: boolean }) => {
    if (buttonType === 'icon') {
      return css`
        border-radius: 1.375rem;
        ${small ? 'height: 2rem; width: 2rem;' : 'height: 2.75rem; width: 2.75rem;'};
        padding: 0;
        vertical-align: top;
        svg {
          vertical-align: middle;
          display: inline-block;
        }
        :focus {
          box-shadow: 0 0 0.25rem ${shadow};
        }
      `
    } else
      return css`
        border-radius: 0.1875rem;
        font: ${small ? fontStyles.Body : fontStyles.BodyLarge};
        line-height: ${small ? '1.43' : '1.25'};
        min-height: ${small ? '2rem' : '2.75rem'};
        min-width: ${small ? '4.625rem' : '5.875rem'};
        padding: ${small ? '0.25rem 1rem' : '0.675rem 1.5rem'};
        text-align: center;
        @media (max-width: ${p => p.theme.breakpoints.medium.max}) {
          width: 100%;
        }
        ${icon &&
        `
          padding-left: ${small ? '0.55rem' : '0.8rem'};
          svg {
            display: inline-block;
            height: ${small ? '1rem' : '1.25rem'}; 
            vertical-align: ${small ? 'text-top' : 'text-bottom'};
            margin-right: ${small ? '0.2rem' : '0.35rem'};
          }
        `}
      `
  }}
  ${({ theme, buttonType }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getButtonStyle(buttonType!, theme)
  }}
`
Button.displayName = 'Button'
Button.defaultProps = {
  buttonType: 'primary',
  size: 'small',
  type: 'button',
  theme: defaultTheme,
}
