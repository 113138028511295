import React from 'react'
const RightArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="arrow-right"
      fill="currentColor"
      fillRule="evenodd"
      d="M9.78932143,2.21554464 L9.08221429,2.92265179 C8.91485714,3.09 8.91485714,3.36136607 9.08221429,3.52875893 L14.6225714,9.06908036 L2.42857143,9.06908036 C2.19189286,9.06908036 2,9.26097321 2,9.49765179 L2,10.4976518 C2,10.7343304 2.19189286,10.9262232 2.42857143,10.9262232 L14.6225714,10.9262232 L9.08221429,16.4665804 C8.91485714,16.6339375 8.91485714,16.9052946 9.08221429,17.0726875 L9.78932143,17.7797946 C9.95667857,17.9471518 10.2280357,17.9471518 10.3954286,17.7797946 L17.8745,10.3007232 C18.0418571,10.1333661 18.0418571,9.86200893 17.8745,9.69461607 L10.3953929,2.21554464 C10.2280357,2.04815179 9.95667857,2.04815179 9.78932143,2.21554464 Z"
    ></path>
  </svg>
)

export default RightArrow
