import { useCallback, useMemo } from 'react'
import type { PluginHook, ActionType, ReducerTableState, Hooks } from 'react-table'
import { useTable as rtUseTable, useFilters as rtUseFilters, ensurePluginOrder } from 'react-table'
import { numericSort } from './_helpers'
import type { TableOptions, TableInstance, ExtendedTableOptions, TableState, Column } from './types'
import { CellType, PaginationType, OverflowType } from './types'
import { DefaultTableFilter, formatRawValue } from './shared'
export { DefaultTableFilter, NumberRangeTableFilter, CheckboxTableFilter } from './shared'
export { useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table'
export * from './types'
export { SimpleTable } from './SimpleTable'
export * from './Complex'

const numericTypes = [CellType.Currency, CellType.Decimal, CellType.Number, CellType.Percentage]

const filterReducer: (
  newState: TableState<any> & { showFilters: boolean },
  action: ActionType,
  previousState?: TableState<any>,
  instance?: TableInstance<any>,
) => ReducerTableState<any> | undefined = (newState, action) => {
  if (action.type === 'toggleFilters') {
    const localState = { ...newState, showFilters: !newState.showFilters, filters: [] }
    return localState
  }
  return { ...newState, showFilters: newState.showFilters || false }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const useInstance = <D extends AnyObjectGenericPlaceholder = {}>(instance: TableInstance<D>) => {
  instance.toggleFilters = useCallback(() => {
    instance.dispatch({ type: 'toggleFilters' })
  }, [instance])
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useFilters = <D extends AnyObjectGenericPlaceholder = {}>(hooks: Hooks<D>) => {
  type ReactTableStateReducer = Parameters<typeof hooks.stateReducers.push>[0]
  type ReactTableUseInstance = Parameters<typeof hooks.useInstance.push>[0]
  rtUseFilters(hooks)
  hooks.useInstance.push(useInstance as ReactTableUseInstance)
  hooks.stateReducers.push(filterReducer as ReactTableStateReducer)
}
useFilters.pluginName = 'useFilters'

const addCustomFilterInstance = (instance: any) => {
  ensurePluginOrder(instance.plugins, ['useFilters'], 'useCustomFilterButton')
  instance.useCustomFilterButton = true
}
// eslint-disable-next-line @typescript-eslint/ban-types
export const useCustomFilterButton = <D extends AnyObjectGenericPlaceholder = {}>(hooks: Hooks<D>) => {
  hooks.useInstance.push(addCustomFilterInstance)
}
useCustomFilterButton.pluginName = 'useCustomFilterButton'

const hasWrappableAccessor = (column: Column<any>): boolean => {
  return typeof column.accessor === 'string' || ((column.accessor === null || column.accessor === undefined) && typeof column.id === 'string')
}

const getAccessor = (column: Column<any>): ((original: any) => string) => {
  return (original: any) => {
    const value = original[column.accessor as string]
    return formatRawValue(value, column.cellType)
  }
}
const wrapAccessors = (column: Column<any>): Column<any> => {
  if (column.columns !== undefined && column.columns.length > 0) {
    const nestedColumns = column.columns.map(wrapAccessors)
    column = { ...column, columns: nestedColumns }
  }
  if (column.cellType !== null && column.cellType !== undefined) {
    let newAccessor
    if (hasWrappableAccessor(column)) {
      newAccessor = getAccessor(column)
      const id = column.accessor !== undefined && typeof column.accessor === 'string' ? column.accessor : column.id
      if (column.cellType && numericTypes.includes(column.cellType)) {
        return { sortType: 'numeric', ...column, id, accessor: newAccessor }
      }
      return { ...column, id, accessor: newAccessor }
    }
    return column
  }
  return column
}

export const useTable = <D extends AnyObjectGenericPlaceholder>(props: TableOptions<D> & ExtendedTableOptions, ...plugins: Array<PluginHook<D>>): TableInstance<D> => {
  if (!props.defaultColumn) {
    // When using the useFlexLayout:
    props.defaultColumn = {
      minWidth: 150, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }
  }
  if (plugins.includes(useFilters)) {
    props.defaultColumn = {
      ...props.defaultColumn,
      Filter: DefaultTableFilter,
    }
  }
  if (props.itemDescriptor === undefined) {
    props.itemDescriptor = 'Items'
  }

  if (props.hidePagination === undefined) {
    props.hidePagination = PaginationType.Never
  }

  if (props.noDataMessage === undefined) {
    props.noDataMessage = 'No data available.'
  }

  if (props.columnOverflow === undefined) {
    props.columnOverflow = OverflowType.Truncate
  }
  // useTable doesn't have a way to specify custom properties,
  // so we're going to confuse the compiler to get around it
  // Don't try this at home. Very not safe.
  const tableTitle: any = { tableTitle: props.title }
  delete props.title
  const superheader: any = { superheader: props.superheader }
  delete props.superheader
  const newColumns = useMemo(() => {
    return props.columns.map(wrapAccessors)
  }, [props.columns])
  props.sortTypes = { numeric: numericSort, ...props.sortTypes }
  return rtUseTable<D>({ ...props, ...tableTitle, ...superheader, columns: newColumns }, ...plugins) as TableInstance<D>
}
