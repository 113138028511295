import { useState, useMemo } from 'react'
import shortid from 'shortid'
import type { State } from '../types'

export const useListTitle = <TValue extends string | number>(state: State<TValue>, title: string, alwaysShowCount = false) => {
  const [titleId] = useState(shortid())

  const listTitle = useMemo(() => `${title}${state.allSelected === 'mixed' || alwaysShowCount ? ` (${state.selectedItems.length} of ${state.items.list.length})` : ''}`, [
    state.allSelected,
    state.items.list.length,
    state.selectedItems.length,
    title,
    alwaysShowCount,
  ])
  return [listTitle, titleId]
}
