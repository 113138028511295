import React from 'react'
const SquareChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="-2 -2 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="square-checked"
      fill="currentColor"
      fillRule="evenodd"
      d="M14.2857 16H1.71429C0.7675 16 0 15.2325 0 14.2857V1.71429C0 0.7675 0.7675 0 1.71429 0H14.2857C15.2325 0 16 0.7675 16 1.71429V14.2857C16 15.2325 15.2325 16 14.2857 16ZM6.9755 12.4979L13.5469 5.92646C13.7701 5.70332 13.7701 5.3415 13.5469 5.11836L12.7388 4.31025C12.5157 4.08711 12.1539 4.08707 11.9307 4.31025L6.57143 9.66946L4.06932 7.16736C3.84618 6.94421 3.48436 6.94421 3.26118 7.16736L2.45307 7.97546C2.22993 8.19861 2.22993 8.56043 2.45307 8.78357L6.16736 12.4979C6.39054 12.7211 6.75232 12.7211 6.9755 12.4979Z"
    ></path>
  </svg>
)

export default SquareChecked
