import React, { useMemo } from 'react'
import warning from 'tiny-warning'
import { VirtualizedCheckboxList } from './CheckboxList'
import type { LargeFacetProps, MetaCheckboxItem } from '../shared'
import { ALL_ITEMS_KEY, FacetBox, FacetListBox, FacetTitle, FilterBox, useFacet } from '../shared'
import { FacetToggle, LargeFacetTitleContainer } from './shared'

export const LargeFacet = <TValue extends number | string>({
  items,
  title,
  placeholder = `Filter ${title.toLocaleLowerCase()}...`,
  onChange,
  config: { selectionType = 'tristate', bubble = true, sort = true } = { selectionType: 'tristate', bubble: true, sort: true },
  className,
  ...rest
}: LargeFacetProps<TValue>) => {
  warning(items.length > 7, "LargeFacet is designed for more than 7 items. Are you sure you don't want to use SmallFacet?")
  const {
    allSelected,
    allVisibleItemsChecked,
    displayedItems,
    filter,
    unrenderedItemsCount,
    handleKeyDown,
    hasFilter,
    hasFilteredItems,
    isExpanded,
    listTitle,
    onFilterChange,
    selectAll,
    setIsExpanded,
    showAllCheckbox,
    titleId,
    toggleCheck,
    toggleVisible,
  } = useFacet({
    selectionType,
    bubble,
    sort,
    items,
    onChange,
    title,
    ...rest,
  })

  const checkboxItems = useMemo(() => {
    const allCheckboxItem = { instance: { value: ALL_ITEMS_KEY, label: `All ${title}` }, checked: allSelected } as MetaCheckboxItem<TValue>
    const items = !hasFilter && showAllCheckbox ? [allCheckboxItem, ...displayedItems] : displayedItems
    return items
  }, [allSelected, displayedItems, hasFilter, showAllCheckbox, title])

  return (
    <FacetBox aria-labelledby={titleId} className={className}>
      <LargeFacetTitleContainer>
        <FacetTitle id={titleId}>{listTitle}</FacetTitle>
        {hasFilteredItems && hasFilter && <FacetToggle onClick={toggleVisible}>{allVisibleItemsChecked ? 'Deselect' : 'Select results'}</FacetToggle>}
      </LargeFacetTitleContainer>
      <FacetListBox>
        <FilterBox value={filter} onChange={onFilterChange} placeholder={placeholder} aria-label={placeholder} />
        <VirtualizedCheckboxList
          displayedItems={checkboxItems}
          expanded={isExpanded}
          handleKeyDown={handleKeyDown}
          selectAll={selectAll}
          toggleCheck={toggleCheck}
          hasAllCheckbox={showAllCheckbox}
        />
      </FacetListBox>
      {!hasFilter && <FacetToggle onClick={setIsExpanded}>Show {isExpanded ? 'less' : `${unrenderedItemsCount} more`}</FacetToggle>}
    </FacetBox>
  )
}
LargeFacet.displayName = 'LargeFacet'
