import React from 'react'
import type { Cell } from '../../types'
import styled from 'styled-components'
import { Palette } from '@style'
import { defaultTheme } from '@themes'

const DefaultFilterInput = styled.input`
  border-radius: 0.1875rem;
  border: solid 0.0625rem ${Palette.neutral[50].hex};
  width: 90%;
  outline: none;
  padding: 0.125rem 0.25rem;
  :focus {
    border: ${props => (Boolean(props['aria-disabled']) ? 'none' : `solid 0.0625rem ${props.theme.colors.octonary.hex}`)};
  }
`
DefaultFilterInput.displayName = 'DefaultFilterInput'
DefaultFilterInput.defaultProps = {
  theme: defaultTheme,
}

export const DefaultTableFilter = <TData extends AnyObject>({ column: { filterValue = '', setFilter } }: Cell<TData>) => {
  return (
    <DefaultFilterInput
      value={filterValue}
      onClick={e => {
        // This prevents a focusing on the filter from propagating a click to the header sort handler
        e.stopPropagation()
      }}
      onChange={e => {
        // TODO: Fix when re-work types
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setFilter!(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}
