import type { FC, CSSProperties } from 'react'
import React, { useState, useCallback } from 'react'
import AriaModal from 'react-aria-modal'
import styled from 'styled-components'
import { Button } from '../Button'
import { BodyLarge } from '../textStyles'
import { fontStyles } from '@style'
import { defaultTheme } from '@themes'
import type { ClassNameProp } from '@components/types'

export type ConfirmationType = 'info' | 'destructive'

export interface ConfirmationModalProps extends ClassNameProp {
  /**
   * The element which will cause the Confirmation Modal to appear. It must accept an onClick prop.
   */
  actuator: JSX.Element
  /**
   * The message content to be rendered within the body of the modal.
   */
  message: string | JSX.Element
  /**
   * The title of the modal.
   */
  title: string | JSX.Element
  /**
   *
   */
  type?: ConfirmationType
  /**
   * The callback which will be called upon confirmation.
   */
  onConfirm: () => void
  /**
   * The callback which will be called upon rejection.
   */
  onReject: () => void
}

const ConfirmationButtons = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;

  button:last-child {
    margin-left: 2rem;

    @media (max-width: ${p => p.theme.breakpoints.small.max}) {
      margin-left: 0;
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: ${p => p.theme.breakpoints.small.max}) {
    flex-direction: column-reverse;
  }

  ${Button} {
    width: auto;

    @media (max-width: ${p => p.theme.breakpoints.small.max}) {
      width: 100%;
    }
  }
`
ConfirmationButtons.defaultProps = {
  theme: defaultTheme,
}

const ResponsivePaddingWrapper = styled.div`
  padding: 2rem;

  @media (max-width: ${p => p.theme.breakpoints.small.max}) {
    padding: 2rem 1rem;
  }
`
ResponsivePaddingWrapper.displayName = 'ResponsivePaddingWrapper'
ResponsivePaddingWrapper.defaultProps = {
  theme: defaultTheme,
}

const DialogStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.1875rem',
  backgroundColor: '#fff',
  boxSizing: 'border-box',
  maxHeight: '100%',
  boxShadow: '0 0.0625rem .375rem 0 rgba(37, 37, 37, 0.2)',
  width: 'min(max(min(100vw - 28.125rem, 28.125rem), 90vw), 28.125rem)',
}

const DialogUnderlay = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.25)',
}

const Message = styled(BodyLarge)`
  margin: 1rem 0 1.5rem;
`

const Header = styled.header`
  * {
    font: ${fontStyles.Header2};
    font-weight: bold;
  }
`

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ actuator, onConfirm, onReject, message, title, type = 'info', className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const primaryButtonType = type === 'info' ? 'primary' : 'destructive'

  const onClick = () => {
    setIsOpen(true)
  }

  const onCancel = useCallback(() => {
    setIsOpen(false)
    onReject()
  }, [onReject])

  const onSubmit = useCallback(() => {
    setIsOpen(false)
    onConfirm()
  }, [onConfirm])

  return (
    <>
      {isOpen && (
        <AriaModal dialogStyle={DialogStyle} underlayStyle={DialogUnderlay} dialogClass={className} titleId={'modal-title-🚲'} onExit={onCancel}>
          <ResponsivePaddingWrapper>
            <Header as="header" id={'modal-title-🚲'}>
              {typeof title === 'string' ? <div>{title}</div> : title}
            </Header>
            {typeof message === 'string' ? <Message>{message}</Message> : message}
            <ConfirmationButtons>
              <Button size={'large'} type="button" buttonType={'tertiary'} onClick={onCancel}>
                Cancel
              </Button>
              <Button size={'large'} type="button" buttonType={primaryButtonType} onClick={onSubmit}>
                Confirm
              </Button>
            </ConfirmationButtons>
          </ResponsivePaddingWrapper>
        </AriaModal>
      )}
      {React.cloneElement(actuator, { onClick })}
    </>
  )
}
