import type { FC } from 'react'
import React from 'react'
import { ToastProvider as RTProvider, DefaultToastContainer as ToastContainer } from 'react-toast-notifications'
import type { Placement } from './types'
import { Toast } from './Toast'
export const ToastProvider: FC<{ placement?: Placement }> = ({ children, placement = 'top-center', ...props }) => (
  <RTProvider placement={placement} components={{ Toast, ToastContainer }} {...props}>
    {children}
  </RTProvider>
)
