import React from 'react'
const Square = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="square-empty"
      fill="currentColor"
      fillRule="evenodd"
      d="M14.2857 0H1.71429C0.767857 0 0 0.767857 0 1.71429V14.2857C0 15.2321 0.767857 16 1.71429 16H14.2857C15.2321 16 16 15.2321 16 14.2857V1.71429C16 0.767857 15.2321 0 14.2857 0ZM14.0714 14.2857H1.92857C1.81071 14.2857 1.71429 14.1893 1.71429 14.0714V1.92857C1.71429 1.81071 1.81071 1.71429 1.92857 1.71429H14.0714C14.1893 1.71429 14.2857 1.81071 14.2857 1.92857V14.0714C14.2857 14.1893 14.1893 14.2857 14.0714 14.2857Z"
    ></path>
  </svg>
)

export default Square
