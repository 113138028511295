import React from 'react'
const Filter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="1rem" width={'1rem'} {...props}>
    <path
      d="M14.4985 0H1.50178C0.169343 0 -0.502939 1.61659 0.441124 2.56065L5.5 7.62131V13C5.5 13.4721 5.72228 13.9167 6.1 14.25L8.1 15.7208C9.07728 16.4038 10.5 15.7689 10.5 14.5208V7.62131L15.5592 2.56065C16.5013 1.6185 15.8336 0 14.4985 0ZM9 7V14.5L7 13V7L1.5 1.5H14.5L9 7Z"
      fill="currentColor"
    />
  </svg>
)

export default Filter
