import styled from 'styled-components'
import { defaultTheme } from '@themes'

export const ComboBoxContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.large.min}) {
    max-width: 20rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.medium.max}) {
    width: 100%;
  }
`

ComboBoxContainer.defaultProps = {
  theme: defaultTheme,
}
