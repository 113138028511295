import React from 'react'

const sizes = {
  large: '1.25rem',
  small: '1rem',
} as const

const viewBoxes = {
  large: '0 0 20 20',
  small: '0 0 16 16',
} as const
export const SVG = (props: React.SVGProps<SVGSVGElement> & { size: 'large' | 'small' }) => (
  <svg role="img" display="block" width={sizes[props.size]} height={sizes[props.size]} viewBox={viewBoxes[props.size]} xmlns="http://www.w3.org/2000/svg" {...props}>
    {props.children}
  </svg>
)
