import React, { useState } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'

import { Palette, fontStyles } from '@style'
import { defaultTheme } from '@themes'
import { Square, SquareChecked } from '@assets'
import type { MetaCheckboxItem } from './types'
import MinusSquare from '@assets/images/MinusSquare'

export const FacetBoxItem = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  margin: 0.25rem 0 0.25rem 0.1rem;
  padding: 0.04rem 0.2rem 0.02rem 0.1rem;
  width: 98%;

  label {
    color: ${Palette.neutral[80].hex};
    font: ${fontStyles.BodySmall};
    line-height: 1.25rem;
  }
`

export const Checkbox = styled.span`
  height: 1.25rem;
  outline: none;
`
Checkbox.defaultProps = {
  role: 'checkbox',
}

const CIcon = ({ checkedType, className }: { checkedType: MetaCheckboxItem<unknown>['checked']; className?: string }): JSX.Element => {
  const Icon = checkedType === 'true' ? SquareChecked : checkedType === 'mixed' ? MinusSquare : Square
  return <Icon className={className} viewBox={'-2 -2 20 20'} />
}
export const CheckIcon = styled(CIcon)`
  color: ${({ checkedType, theme }) => (checkedType ? theme.colors.primary.hex : Palette.neutral[80].hex)};
  font-size: 1.143rem;
  margin-right: 0.25rem;
`

CheckIcon.defaultProps = {
  theme: defaultTheme,
}

interface FacetCheckboxProps<TValue> {
  className?: string
  expanded?: boolean
  item: MetaCheckboxItem<TValue>
  toggleIt(): void
  handleKeyDown(cb: () => void): (e: React.KeyboardEvent) => void
  style?: any
}

const FacetCheckboxLabel = styled.label`
  word-break: break-word;
  line-height: 1rem;
  padding-top: 0.125rem;
`
FacetCheckboxLabel.displayName = 'FacetCheckboxLabel'

export const FacetCheckbox = <TValue extends number | string>({ item: { instance, checked }, toggleIt, handleKeyDown, style, className }: FacetCheckboxProps<TValue>) => {
  const [id] = useState(shortid())
  return (
    <FacetBoxItem
      style={{ ...style, width: '98%', marginTop: '0.0625rem' }}
      className={className}
      tabIndex={0}
      key={instance.value}
      onClick={toggleIt}
      onKeyDown={handleKeyDown(toggleIt)}
    >
      <Checkbox aria-labelledby={id} aria-checked={checked} style={{ paddingTop: '0.0625rem' }}>
        <CheckIcon checkedType={checked} />
      </Checkbox>
      <FacetCheckboxLabel id={id}>{instance.label}</FacetCheckboxLabel>
    </FacetBoxItem>
  )
}
