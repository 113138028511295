import styled from 'styled-components'
import { Palette, fontStyles } from '@style'
import { Body } from '../../textStyles'
import { defaultTheme } from '@themes'
import { Warning, ChevronDown } from '@assets'
import type { FC } from 'react'
import React from 'react'
import type { InputValidation, Validation } from './types'
import { ValidationType } from './types'
export * from './types'
export * from './hooks'
export * from './ComboBoxContainer'
export * from './FieldContainer'
export * from './FormLabel'

export const DropdownButton = styled.div.attrs<{ isOpen: boolean; validation: InputValidation }>(props => ({
  borderColor: getValidationBorderColor(props, props.theme.neutrals.senary.hex),
  focusBorderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex),
  role: 'button',
}))<{ isOpen: boolean; validation: InputValidation; borderColor: string; focusBorderColor: string }>`
  align-items: center;
  background-color: ${props => {
    if (props.validation?.type === ValidationType.Error) {
      return Palette.red[5].hex
    }
    return props.theme.neutrals.primary.hex
  }};
  border-radius: 0.1875rem;
  border: solid 0.0625rem ${p => p.borderColor};
  box-sizing: border-box;
  display: flex;
  height: 2.75rem;
  outline: none;
  padding: 0;

  :focus {
    border: solid 0.0625rem ${p => p.focusBorderColor};
    box-shadow: 0 0 0 0.0625rem ${props => props.focusBorderColor};
  }

  &[aria-expanded='true'] {
    border-radius: 0.1875rem 0.1875rem 0 0;
    border: solid 0.0625rem ${props => props.focusBorderColor};
    box-shadow: 0 0 0 0.0625rem ${props => props.focusBorderColor};
    border-bottom: solid 0.0625rem ${Palette.neutral[40].hex};
  }

  &:not(&[aria-disabled='true']) {
    outline: none;
  }

  &[aria-disabled='true'] {
    background-color: ${Palette.neutral[15].hex};
    color: dimgrey;
  }

  svg {
    height: 1.25rem;
  }
`

DropdownButton.defaultProps = {
  theme: defaultTheme,
}

export const ChevronButton = styled.button.attrs(() => ({ children: <ChevronDown role={undefined} /> }))<{ disabled: boolean }>`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${props => (props.disabled ? Palette.neutral[50].hex : Palette.neutral[70].hex)};
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  height: 2.75rem;
  outline: none;
  padding: 0;
  width: 2.75rem;
  cursor: pointer;
  svg {
    flex-grow: 1;
  }
`

export const ClearButton = styled.div`
  align-items: center;
  background: transparent;
  border: solid 0.0625rem transparent;
  color: ${props => (props['aria-disabled'] === true ? Palette.neutral[50].hex : Palette.neutral[70].hex)};
  display: flex;
  flex-grow: 0;
  line-height: 1.25rem;
  outline: none;
  padding: 0;

  :focus {
    border: ${props => (props['aria-disabled'] === true ? 'none' : `solid 0.0625rem ${props.theme.colors.octonary.hex}`)};
  }

  &:disabled {
    color: ${Palette.neutral[50].hex};
  }
`
ClearButton.defaultProps = {
  tabIndex: 0,
  theme: defaultTheme,
  role: 'button',
}
ClearButton.displayName = 'ClearButton'

export const getValidationBorderColor = (props: any, defaultColor: string) => {
  switch (props.validation?.type) {
    case ValidationType.Error:
      return Palette.red[80].hex
    case ValidationType.Warning:
      return Palette.yellow[80].hex
    default:
      return defaultColor
  }
}

export const OptionsList = styled.ul.attrs<{ isOpen: boolean; validation: InputValidation; listWidth: number }>(props => ({
  borderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex),
  listWidth: props.listWidth || 320,
  theme: props.theme,
}))<{ isOpen: boolean; validation: InputValidation; borderColor: string; listWidth: number }>`
  ${props => !props.isOpen && 'display: none;'}
  box-sizing: border-box;
  margin: 0;
  max-height: 13.5rem;
  outline: none;
  overflow: auto;
  padding-bottom: 0.5rem;
  padding-left: 0.0625rem;
  padding-top: 0.6875rem;
  padding-right: 0.0625rem;
  position: absolute;
  font: ${fontStyles.Body};
  /* TODO: When we do responsive support, this needs to be extract to a value
     and used in the isOpen width calculation */
  width: 20rem;
  list-style-type: none;

  background-color: ${Palette.neutral[0].hex};
  box-shadow: -1px 1px 0px 1px ${props => props.borderColor}, 0px 1px 0px 1px ${props => props.borderColor}, 1px 1px 0px 1px ${props => props.borderColor};
  border: solid 0.0625rem transparent;
  border-top: none;
  border-radius: 0 0 0.1875rem 0.1875rem;

  // TODO: This stuff is kinda nasty. If we can refactor it out when we do responsive support, that'd be greaaaaaaaaaaaaaaaaaat. :lumbergh:
  width: calc(${props => props.listWidth}px - 0.125rem);
  @media (max-width: ${props => props.theme.breakpoints.medium.max}) {
    width: 100% - 0.125rem;
  }
  margin-left: 1px;

  z-index: 100;
`
OptionsList.displayName = 'OptionsList'
OptionsList.defaultProps = {
  theme: defaultTheme,
}

export const OptionListItem = styled.li<{ isHighlighted: boolean }>`
  cursor: default;
  padding: 0.25rem 0.0625rem 0.25rem 1rem;
  position: relative;
  outline: none;
  &[aria-disabled='true'] {
    color: ${Palette.neutral[100].disabled};
  }
  ${props => (props.isHighlighted ? `background-color: ${Palette.neutral[10].hex};` : ``)}
`
OptionListItem.displayName = 'OptionListItem'

export const HelpText = styled(Body).attrs(() => ({ as: 'span' }))`
  color: ${Palette.neutral[80].hex};
  min-height: 1.3125rem;
`
HelpText.displayName = 'HelpText'

export const ErrorIcon = styled(Warning)`
  color: ${Palette.red[80].hex};
  padding-right: 0.25rem;
`
ErrorIcon.displayName = 'ErrorIcon'
export const WarningIcon = styled(Warning)`
  color: ${Palette.yellow[80].hex};
  padding-right: 0.25rem;
`
WarningIcon.displayName = 'WarningIcon'
export const ValidationText = styled(Body)<{ type: Validation }>`
  ${({ type }) => {
    if (type === ValidationType.Error) {
      return `color: ${Palette.red[80].hex};`
    }
    return `color: inherit;`
  }}
  align-items: center;
`
ValidationText.displayName = 'ValidationText'

export const Centered = styled.span`
  display: flex;
  align-items: center;
`
Centered.displayName = 'Centered'

interface ValidationProps {
  helpText?: string
  name: string
  validation?: InputValidation
}

export const ValidationMessage: FC<ValidationProps> = ({ validation, name, helpText }) => {
  return (
    <Centered aria-live="polite">
      {validation ? (
        <>
          {validation.type === ValidationType.Error && <ErrorIcon />}
          {validation.type === ValidationType.Warning && <WarningIcon />}
          <ValidationText id={`${name}_error`} type={validation.type} as="span">
            {validation.message}
          </ValidationText>
        </>
      ) : (
        <HelpText id={`${name}_helptext`}>{helpText}</HelpText>
      )}
    </Centered>
  )
}
