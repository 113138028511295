import styled from 'styled-components'
import { textStyles } from '@style'

export const Header1 = styled.h1`
  ${textStyles.Header1}
`
export const Header2 = styled.h2`
  ${textStyles.Header2}
`
export const Header3 = styled.h3`
  ${textStyles.Header3}
`
