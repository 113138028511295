import React from 'react'
const SpinsLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="inline-block" width="88" height="20" viewBox="0 0 88 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.3818 14.9745L29.0494 12.6564C30.0537 13.6795 31.6153 14.5566 33.5818 14.5566C35.2691 14.5566 36.0615 13.8259 36.0615 13.0122C36.0615 10.5903 27.8508 12.2804 27.8508 7.04052C27.8508 4.72275 29.9038 2.80166 33.2601 2.80166C35.5267 2.80166 37.4071 3.47144 38.8179 4.74343L37.1296 6.9577C35.9762 5.91302 34.4359 5.43271 32.9824 5.43271C31.7002 5.43271 30.9734 5.97639 30.9734 6.81073C30.9734 9.00313 39.161 7.52088 39.161 12.7189C39.161 15.2664 37.2798 17.1885 33.453 17.1885C30.6959 17.1885 28.7277 16.2903 27.3818 14.9745Z"
      fill="white"
    />
    <path d="M53.4834 3.01206H56.5177V16.937H53.4834V3.01206Z" fill="white" />
    <path
      d="M72.3555 14.9745L74.0237 12.6564C75.0276 13.6795 76.5894 14.5565 78.5544 14.5565C80.2427 14.5565 81.0324 13.8259 81.0324 13.0122C81.0324 10.5903 72.8262 12.2804 72.8262 7.0405C72.8262 4.72274 74.8774 2.80161 78.2339 2.80161C80.5008 2.80161 82.3811 3.47142 83.7918 4.74341L82.1019 6.95766C80.9483 5.913 79.4097 5.43267 77.9562 5.43267C76.6746 5.43267 75.9458 5.97638 75.9458 6.81069C75.9458 9.00312 84.1334 7.52083 84.1334 12.7189C84.1334 15.2664 82.2521 17.1885 78.4254 17.1885C75.6697 17.1885 73.7021 16.2903 72.3555 14.9745Z"
      fill="white"
    />
    <path
      d="M2.84229 12.1382C3.16572 12.1382 3.49842 12.1218 3.83024 12.088C4.32407 12.0385 4.80902 11.9525 5.27327 11.8355C4.99715 11.0119 4.72075 9.80754 4.6098 9.09141C4.34315 9.14274 3.95455 9.20828 3.51326 9.25272C2.84843 9.3212 2.26782 9.31519 1.84022 9.23951C1.73226 9.80282 1.63872 11.1802 1.72057 12.0614C2.06063 12.1115 2.43797 12.1382 2.84229 12.1382Z"
      fill="#64C432"
    />
    <path
      d="M4.11256 15.7102C4.47171 15.7102 4.83822 15.6904 5.20171 15.6546C5.78967 15.5949 6.34835 15.4887 6.82313 15.3438C6.55527 14.8496 5.85139 13.5261 5.56296 12.7391C5.06614 12.8591 4.48432 12.9544 3.91481 13.0122C3.09908 13.0943 2.3568 13.092 1.83984 13.0078C1.9478 13.6748 2.29041 14.8985 2.68305 15.5848C3.03513 15.6638 3.55388 15.7102 4.11256 15.7102Z"
      fill="#A3D83E"
    />
    <path
      d="M1.15741 12.8328C0.870756 12.7451 0.469597 12.5181 0.138672 12.1834C0.359395 13.1279 0.653052 13.7538 0.893882 14.2651C0.934749 14.356 0.976936 14.4402 1.01476 14.5223C1.23486 14.7972 1.48606 15.079 1.87792 15.3008C1.54823 14.4916 1.24959 13.4195 1.15741 12.8328Z"
      fill="#64C432"
    />
    <path
      d="M8.95117 18.1784C8.37988 17.5902 7.69192 16.7094 7.31807 16.1441C6.75968 16.3014 6.03674 16.4323 5.32369 16.5035C4.51744 16.5853 3.78532 16.5834 3.22559 16.5003C3.69479 17.1866 4.36802 17.9992 4.94578 18.3703C5.26394 18.4412 5.77966 18.5227 6.46538 18.5227H6.46631C6.75726 18.5227 7.05547 18.5075 7.35379 18.479C7.88201 18.4247 8.41889 18.3241 8.95117 18.1784Z"
      fill="#F9DD1B"
    />
    <path
      d="M10.3394 9.64422C10.0586 9.2447 9.72381 8.78613 9.42546 8.38061C9.11467 7.95297 8.84592 7.58557 8.6617 7.31674C7.81021 7.83666 6.47363 8.50409 5.31738 8.87103C5.43179 9.48834 5.75009 10.7682 6.04273 11.6191C7.26652 11.2334 9.15775 10.3637 10.3394 9.64422Z"
      fill="#BBDF2F"
    />
    <path
      d="M12.6303 12.8166C12.0734 12.0757 11.2669 10.9676 10.8878 10.4334C9.75072 11.0969 7.81719 12.0235 6.37012 12.4849C6.57913 13.0132 7.19756 14.3246 7.63536 15.094C8.95543 14.6913 11.0497 13.8183 12.6303 12.8166Z"
      fill="#F9DD1B"
    />
    <path
      d="M7.66862 19.1705C7.42071 19.196 7.03831 19.2256 6.61999 19.2256C6.43983 19.2256 6.26521 19.2206 6.10156 19.2096C7.10556 19.7415 8.15174 20 9.2888 20C9.54224 20 9.80229 19.9877 10.064 19.961C10.397 19.9273 10.7383 19.8722 11.0836 19.7941C10.547 19.5204 9.93051 19.0907 9.584 18.7854C9.1409 18.9499 8.41391 19.0957 7.66862 19.1705Z"
      fill="#F9B219"
    />
    <path
      d="M14.7558 15.6794C14.508 15.3491 14.1298 14.8405 13.7867 14.3774L13.7612 14.3437C13.5376 14.041 13.3307 13.7632 13.1879 13.5734C11.9857 14.3231 9.81042 15.3797 8.14258 15.9071C8.52726 16.4837 9.26222 17.3651 9.79778 17.9441C11.0697 17.5484 13.2402 16.608 14.7558 15.6794Z"
      fill="#F9B219"
    />
    <path
      d="M16.205 17.6306C15.9433 17.281 15.585 16.8264 15.3441 16.5196L15.2197 16.363C14.2201 16.9874 11.8444 18.1436 10.4844 18.5323C10.9374 18.9057 11.5809 19.3612 12.0544 19.5356C13.4483 19.0975 14.6936 18.5255 16.205 17.6306Z"
      fill="#F25829"
    />
    <path
      d="M1.04455 11.8367C1.03508 11.1306 1.07801 9.74911 1.23987 9.07421C0.880288 8.92138 0.433315 8.38137 0.418432 7.58662C-0.0234392 8.91992 -0.0510523 10.1305 0.045776 10.8121C0.065734 10.9544 0.465876 11.5636 1.04455 11.8367Z"
      fill="#2D8C3C"
    />
    <path
      d="M1.91504 16.0622C2.31382 16.575 2.74159 17.0103 3.1309 17.3596C2.74159 16.8519 2.48858 16.4322 2.38545 16.2543C2.23815 16.22 2.07285 16.1458 1.91504 16.0622Z"
      fill="#C4E013"
    />
    <path
      d="M16.5943 18.1292C15.4602 18.8153 14.1167 19.4655 13.0771 19.8342C13.3693 19.8812 13.6402 19.8956 13.868 19.8732C13.9562 19.8648 14.0381 19.8491 14.1128 19.8298C14.7951 19.6474 15.7416 19.2247 16.9303 18.5704C16.8119 18.4196 16.7159 18.2905 16.6402 18.1906L16.5943 18.1292Z"
      fill="#F25A27"
    />
    <path
      d="M1.6025 8.61225C1.01454 8.32625 1.12161 7.5865 1.52895 7.07466C2.18957 6.26921 3.53909 4.92992 5.21621 3.74644C5.5785 4.31807 5.98928 4.93171 6.33147 5.41199C5.38225 6.08175 3.58653 7.48106 2.569 8.69158C2.25895 8.75378 1.99771 8.68938 1.6025 8.61225Z"
      fill="#1575C5"
    />
    <path
      d="M4.34076 2.2893C3.42202 2.90668 2.02403 4.30922 1.62586 4.79281C1.37494 5.14732 1.19238 5.94741 1.19238 6.5791C2.1668 5.3473 4.028 3.77933 4.8146 3.15919C4.61071 2.82154 4.49825 2.60884 4.34076 2.2893Z"
      fill="#1575C5"
    />
    <path
      d="M12.0679 2.45772C11.5148 1.86729 10.7592 1.21509 10.4852 1.05629C9.15897 1.51583 7.28848 2.32739 5.94531 3.25159C6.2058 3.62199 6.73927 4.35822 7.12244 4.8772C8.84382 3.80276 10.5052 2.98886 12.0679 2.45772Z"
      fill="#57B6E9"
    />
    <path
      d="M5.53975 2.67065C6.44724 2.121 8.2272 1.16252 9.68389 0.614664C9.26257 0.424213 8.73002 0.24468 8.412 0.233485C7.29555 0.600567 5.93027 1.26176 4.99023 1.88591C5.10194 2.08106 5.28048 2.32195 5.43976 2.53632C5.4737 2.58262 5.50719 2.62767 5.53975 2.67065Z"
      fill="#57B6E9"
    />
    <path
      d="M9.30469 6.93933C9.7099 7.44209 10.6291 8.64513 11.0408 9.19409C11.9829 8.59496 13.7261 7.21445 14.7119 6.06008C13.9708 5.49066 12.7904 4.78013 12.1622 4.45022C11.5586 5.25073 9.89415 6.50435 9.30469 6.93933Z"
      fill="#F9DD1B"
    />
    <path
      d="M13.4281 12.2935C14.6833 11.4563 16.875 9.68878 17.6131 8.69632C17.0851 8.128 16.1199 7.16385 15.4646 6.66197C14.7074 7.58256 12.8233 9.20448 11.6289 9.96274C12.0188 10.4743 12.8662 11.5707 13.4281 12.2935Z"
      fill="#F9B219"
    />
    <path
      d="M15.5248 15.1868C16.5165 14.5561 18.9149 12.6253 19.6959 11.5503C19.2289 10.6876 18.5758 9.80971 18.268 9.41123C17.601 10.1511 15.7614 11.8723 13.9883 13.0356C14.1922 13.3237 14.5505 13.8238 14.8978 14.3096C15.1353 14.6421 15.3643 14.9609 15.5248 15.1868Z"
      fill="#F35D29"
    />
    <path
      d="M16.9296 17.1726C18.3378 16.3215 19.7347 15.0124 20.457 14.1357C20.3782 13.4147 20.2083 12.7826 20.0621 12.3914C19.1625 13.4244 17.4546 14.8807 16.0117 15.8413C16.1577 16.0519 16.3877 16.3851 16.603 16.6995L16.9296 17.1726Z"
      fill="#E32033"
    />
    <path
      d="M17.5811 18.1453C18.6849 17.3934 19.4598 16.6937 19.7434 16.3294C20.0407 15.8739 20.1994 15.5409 20.3043 15.2344C19.6029 15.9836 18.4267 16.9987 17.2988 17.6926C17.3909 17.8364 17.491 17.9981 17.5811 18.1453Z"
      fill="#C91B96"
    />
    <path
      d="M18.1799 8.0083C18.7763 7.25227 19.7499 5.59457 19.722 4.72234C19.1915 4.23723 18.1428 3.38462 17.4384 3.03467C17.2757 3.95113 16.8166 4.87036 15.9609 5.9998C16.4744 6.3867 17.4732 7.29098 18.1799 8.0083Z"
      fill="#F25829"
    />
    <path
      d="M19.6916 3.49023C19.809 2.99946 19.9047 1.92986 19.6968 1.38532C19.3369 1.09688 18.6476 0.599605 18.235 0.405007C18.3598 0.982166 18.3451 1.57301 18.1885 2.30676C18.5187 2.53259 19.1969 3.0658 19.6916 3.49023Z"
      fill="#F0451D"
    />
    <path
      d="M21.9307 5.62769C22.0158 5.27926 22.1006 4.49382 21.981 4.04326C21.7593 3.75703 21.3359 3.24829 21.0788 3.02025C21.1462 3.48006 21.1231 3.92123 21.0088 4.44365C21.2111 4.67736 21.6271 5.21043 21.9307 5.62769Z"
      fill="#DB1C43"
    />
    <path
      d="M22.9574 2.82104C22.9949 2.5456 23.0024 1.93651 22.8814 1.59915C22.6948 1.39488 22.341 1.03471 22.1328 0.877291C22.2161 1.22544 22.2307 1.56736 22.1812 1.97604C22.3514 2.14148 22.7001 2.521 22.9574 2.82104Z"
      fill="#C91B96"
    />
    <path
      d="M22.8551 8.96753C22.9065 8.66351 23.0242 8.12439 22.9695 7.58381C22.8939 7.15613 22.6573 6.51846 22.4109 6.20006C22.4557 6.63284 22.3991 6.97313 22.3291 7.42993C22.5464 7.6778 22.7612 8.41373 22.8551 8.96753Z"
      fill="#C91B96"
    />
    <path
      d="M21.7292 7.65954C21.514 6.96512 20.9343 6.10303 20.4648 5.52697C20.2926 6.31877 19.7727 7.5692 18.8105 8.71651C19.2517 9.2445 19.8658 10.1164 20.2536 10.8784C20.8981 10.0926 21.7439 8.63571 21.7292 7.65954Z"
      fill="#DA1E41"
    />
    <path
      d="M22.0948 9.03074C21.7892 10.065 21.1791 10.9452 20.6143 11.6999C20.7126 11.9525 20.9657 12.6491 21.0721 13.3545C21.4752 12.7732 21.7483 12.1546 21.9331 11.7339C21.9884 11.611 22.0339 11.5043 22.0767 11.416C22.2356 11.0837 22.2648 10.0392 22.0948 9.03074Z"
      fill="#C91B96"
    />
    <path
      d="M47.3221 3.03672H40.6826V16.8979H43.704V12.2764V11.952V9.35508V8.99943V5.63511H46.8958C48.0861 5.63511 49.0004 6.34096 49.0004 7.50512C49.0004 8.64893 48.0861 9.35508 46.8958 9.35508H45.1887V11.952H47.3221C50.385 11.952 52.0883 9.91547 52.0883 7.50512C52.0883 5.07469 50.4058 3.03672 47.3221 3.03672Z"
      fill="white"
    />
    <path
      d="M86.7471 3.34795H86.3146V3.91564H86.7471C86.8826 3.91564 87.0311 3.79578 87.0311 3.6381C87.0311 3.46776 86.8826 3.34795 86.7471 3.34795ZM87.0052 4.70403L86.5986 4.09853H86.3146V4.70403H86.1078V3.17134H86.7471C87.0117 3.17134 87.2444 3.35423 87.2444 3.6381C87.2444 3.97866 86.9345 4.0922 86.8438 4.0922L87.2638 4.70403H87.0052ZM86.631 2.78026C85.9788 2.78026 85.4491 3.28488 85.4491 3.93452C85.4491 4.56529 85.9788 5.08879 86.631 5.08879C87.2833 5.08879 87.813 4.56529 87.813 3.93452C87.813 3.28488 87.2833 2.78026 86.631 2.78026ZM86.631 5.27173C85.8751 5.27173 85.2617 4.67255 85.2617 3.93452C85.2617 3.19022 85.8751 2.59732 86.631 2.59732C87.3929 2.59732 88.0003 3.19022 88.0003 3.93452C88.0003 4.67255 87.3929 5.27173 86.631 5.27173Z"
      fill="white"
    />
    <path d="M68.227 16.9373L61.4042 7.83427V16.9373H58.3828V3.01225H61.4892L68.0622 11.7212V3.01225H71.0848V16.9373H68.227Z" fill="white" />
  </svg>
)

export default SpinsLogo
