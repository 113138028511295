import React from 'react'
const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="search-icon"
      fill="currentColor"
      fillRule="evenodd"
      d="M17.8898 16.6524L14.0963 12.8588C14.0244 12.787 13.9307 12.7495 13.8307 12.7495H13.4182C14.4025 11.6089 14.9994 10.1246 14.9994 8.49968C14.9994 4.90923 12.0901 2 8.49968 2C4.90923 2 2 4.90923 2 8.49968C2 12.0901 4.90923 14.9994 8.49968 14.9994C10.1246 14.9994 11.6089 14.4025 12.7495 13.4182V13.8307C12.7495 13.9307 12.7901 14.0244 12.8588 14.0963L16.6524 17.8898C16.7993 18.0367 17.0368 18.0367 17.1836 17.8898L17.8898 17.1836C18.0367 17.0368 18.0367 16.7993 17.8898 16.6524ZM8.49968 13.4994C5.73732 13.4994 3.49993 11.262 3.49993 8.49968C3.49993 5.73732 5.73732 3.49993 8.49968 3.49993C11.262 3.49993 13.4994 5.73732 13.4994 8.49968C13.4994 11.262 11.262 13.4994 8.49968 13.4994Z"
    />
  </svg>
)

export default Search
