import React from 'react'
import styled, { keyframes } from 'styled-components'
import { defaultTheme } from '@themes'
import type { ClassNameProp } from '@components/types'

const LoadingComponent = styled.svg`
  width: 3.75rem;
  height: 3.75rem;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;
  align-self: center;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const internalCircle = keyframes`
  0% {
    stroke-dashoffset: 93.5;
  }

  25% {
    stroke-dashoffset: 40;
  }

  100% {
    stroke-dashoffset: 93.5;
    transform: rotate(360deg);
  }
`

const externalCircle = keyframes`
  0% {
    stroke-dashoffset: 156;
    transform: rotate(70deg);
  }

  60% {
    stroke-dashoffset: -156;
  }

  100% {
    stroke-dashoffset: -156;
    transform: rotate(450deg);
  }
`

const LoadingInnerCircle = styled.circle`
  stroke: ${props => props.theme.colors.alternate.hex};
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke-dasharray: 93.5;
  animation: ${internalCircle} 1s ease-in-out infinite;
`
LoadingInnerCircle.defaultProps = {
  theme: defaultTheme,
}

const LoadingOuterCircle = styled.circle`
  stroke: ${props => props.theme.colors.primary.hex};
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke-dasharray: 156;
  animation: ${externalCircle} 1s linear infinite;
`
LoadingOuterCircle.defaultProps = {
  theme: defaultTheme,
}

export const LoadingSpinner = (props: ClassNameProp) => (
  <LoadingWrapper className={props.className}>
    <LoadingComponent viewBox="0 0 60 60">
      <LoadingInnerCircle cx="30" cy="30" r="15" />
      <LoadingOuterCircle cx="30" cy="30" r="25" />
    </LoadingComponent>
  </LoadingWrapper>
)
LoadingSpinner.displayName = 'LoadingSpinner'
