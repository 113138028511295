import type { ClassNameProp, SelectItem } from '../../types'
export type { SelectItem } from '../../types'

export interface BaseDropdownProps<TSelectItem> extends ClassNameProp {
  name: string
  /**
   * Array of item objects to include in dropdown.
   */
  items: Array<TSelectItem>
  /**
   * Text appearing directly above `ComboBox`
   */
  label?: string
  disabled?: boolean

  /**
   * Assistive text to display below the input
   */
  helpText?: string

  /**
   * Instructional text to display when no item is selected
   */
  placeholder?: string
  validation?: InputValidation
}
export const ValidationType: Record<string, Validation> = {
  Error: 'error',
  Warning: 'warning',
}
export type Validation = 'error' | 'warning'

export interface InputValidation {
  message: string
  type: Validation
}

export type Selections<TData, TSelectItem extends SelectItem<TData>> =
  | {
      selectionType: 'single'
      onChange: (value: TSelectItem) => void

      /**
       * The initally selected item
       */
      defaultValue?: TSelectItem
    }
  | {
      selectionType: 'multiple'
      onChange: (values: TSelectItem[]) => void

      /**
       * The initally selected item(s)
       */
      defaultValue?: TSelectItem[]
    }

export interface FuzzySearchOptions {
  /**
   * Fuzzy search options
   */
  distance?: number
  ignoreLocation?: boolean
  keys?: Array<string>
  location?: number
  maxPatternLength?: number
  minMatchCharLength?: number
  shouldSort?: boolean
  threshold?: number
}
