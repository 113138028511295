import { useCallback } from 'react'

export const useOnClick = (cb: () => void) => {
  return useCallback(
    (e: React.SyntheticEvent<any>) => {
      e.preventDefault()
      e.stopPropagation()
      cb()
    },
    [cb],
  )
}
