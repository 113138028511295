import type { UseComboboxStateChangeOptions, UseComboboxState } from 'downshift'
import { useCombobox } from 'downshift'
import type { SelectItem } from '@components/types'

export const stateReducer: (state: UseComboboxState<SelectItem<any>>, actionAndChanges: UseComboboxStateChangeOptions<SelectItem<any>>) => UseComboboxState<SelectItem<any>> = (
  state,
  actionAndChanges,
) => {
  switch (actionAndChanges.type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...actionAndChanges.changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      }
    default:
      return actionAndChanges.changes
  }
}
