import React, { useMemo } from 'react'
import { getValidationBorderColor, ClearButton } from '@components/forms/shared'
import styled from 'styled-components'
import { fontStyles } from '@style'
import { Times } from '@assets'
import { defaultTheme } from '@themes'
import { useKeyDown } from '../shared/hooks'
import { Key } from 'ts-keycode-enum'
import type { SelectItem } from '@components/types'

interface PillBoxProps<TSelectItem> {
  items: TSelectItem[]
  onRemove: (item: TSelectItem) => void
}

const PillBoxContainer = styled.div.attrs(props => ({
  borderColor: getValidationBorderColor(props, props.theme.neutrals.senary.hex),
  focusBorderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex),
}))`
  align-items: center;
  background-color: ${props => props.theme.neutrals.primary.hex};
  border: solid 0.0625rem ${props => props.borderColor};
  border-bottom: solid 0.0625rem transparent;
  border-radius: 0.1875rem 0.1875rem 0 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  min-height: 2.75rem;
  outline: none;
  padding: 0.25rem 0.125rem;

  :focus,
  :focus-within {
    position: relative;
    border: solid 0.0625rem ${props => props.focusBorderColor};
    box-shadow: 0 0 0 0.0625rem ${props => props.focusBorderColor};
  }
`
PillBoxContainer.displayName = 'PillBoxContainer'
PillBoxContainer.defaultProps = {
  theme: defaultTheme,
}

export const Pill = styled.span.attrs(props => ({ focusBorderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex) }))`
  align-items: center;
  border: solid 0.0625rem ${props => props.theme.neutrals.quinary.hex};
  border-radius: 0.75rem;
  display: flex;
  font: ${fontStyles.BodySmall};
  height: 1.5rem;
  margin: 0.125rem;
  padding: 0 1rem;
  outline: none;
  :focus {
    border: solid 0.0625rem ${props => props.focusBorderColor};
  }
`
Pill.displayName = 'Pill'
Pill.defaultProps = {
  theme: defaultTheme,
}

const SmallClearButton = styled(ClearButton)`
  border: none;
  cursor: pointer;
  height: 1rem;
  margin-left: 0.1275rem;
  outline: none;

  :focus {
    border: none;
  }
`

const PillLabel = styled.span`
  font: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  white-space: nowrap;
`

const keyCodes = [Key.Space, Key.Backspace, Key.Delete]
export const PillBox = <TSelectItem extends SelectItem<any>>({ items, onRemove }: PillBoxProps<TSelectItem>) => {
  const handleKeyDown = useKeyDown(keyCodes)
  const callbacks = useMemo(() => {
    return items.map(i => [() => onRemove(i), handleKeyDown(() => onRemove(i))] as const)
  }, [handleKeyDown, items, onRemove])
  return (
    <PillBoxContainer>
      {items.map((item, i) => (
        <Pill key={item.label} tabIndex={0} onKeyDown={callbacks[i][1]}>
          <PillLabel>{item.label}</PillLabel>
          <SmallClearButton tabIndex={-1} onClick={callbacks[i][0]}>
            <Times size={'small'} height={'0.75rem'} width={'0.75rem'} />
          </SmallClearButton>
        </Pill>
      ))}
    </PillBoxContainer>
  )
}
