import { Key } from 'ts-keycode-enum'
import type { ElementRef, MutableRefObject } from 'react'
import { useCallback } from 'react'

const mod = (x: number, m: number) => ((x % m) + m) % m
// const keys = [Key.UpArrow, Key.DownArrow, Key.LeftArrow, Key.RightArrow]
// I would like to use typeof keys[number] instead of this union, but that breaks the useKeyDown call
type NavigationKeys = Key.UpArrow | Key.DownArrow | Key.LeftArrow | Key.RightArrow
const handleArrows = (keyCode: NavigationKeys) => {
  switch (keyCode) {
    case Key.DownArrow:
    case Key.RightArrow: {
      return 1
    }
    case Key.UpArrow:
    case Key.LeftArrow: {
      return -1
    }
  }
}

export const useCircularNavigation = <T extends ElementRef<'button'> | ElementRef<'a'>>({
  refs,
  currentIndex,
  autoClick = true,
}: {
  refs: MutableRefObject<T[]>
  currentIndex: number
  autoClick?: boolean
}) => {
  return useCallback(
    (keyCode: number) => {
      const direction = handleArrows(keyCode)
      const newIndex = mod(currentIndex + direction, refs.current.length)
      autoClick && refs.current[newIndex].click()
      refs.current[newIndex].focus()
    },
    [currentIndex, refs, autoClick],
  )
}
