import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Palette, fontStyles, textStyles } from '@style'
import { numberNegativeThreshold, percentageNegativeThreshold } from '../shared/Cells'
import type { Column, CellFormat, ColumnInstance, Cell, TableInstance, Overflow } from '../types'
import { DownArrow, UpArrow } from '../shared'
import { getElementProps } from '../shared/validation'

export const TableTitleStyle = styled.h3`
  font: ${fontStyles.Header1};
  margin-bottom: 1rem;
`

export const TableStyle = styled.table<{ minimumWidth: number }>`
  box-shadow: rgba(37, 37, 37, 0.05) 0 0 0 0.0625rem, rgba(37, 37, 37, 0.15) 0 0.0625rem 0.1875rem 0;
  border-radius: 0.1875rem;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.minimumWidth}px;
`

TableStyle.displayName = 'TableStyle'

export const TableRow = styled.tr<{ enableSuperheader: boolean }>`
  background: ${Palette.neutral[0].hex};
`
TableRow.displayName = 'TableRow'

const rowPadding = css`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`

export const TableFilters = styled.div.attrs(() => ({ title: '' }))`
  height: 1.625rem;
  padding-bottom: 0.5rem;
`

const TableColumnHeaderWrapper = styled.th<{ column: Column<any>; showFilters: boolean }>`
  background-color: ${Palette.neutral[0].hex};
  border: none;
  color: ${Palette.neutral[80].hex};
  ${props => {
    switch (props.column.cellType) {
      case 'percentage':
      case 'currency':
      case 'number':
      case 'decimal':
        return 'text-align: right;'
      default:
        return 'text-align: left;'
    }
  }}
  font: ${fontStyles.Body};
  height: ${p => (p.showFilters ? 'auto' : '3rem')};
  text-overflow: ellipsis;
  padding-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  ${rowPadding}
`
TableColumnHeaderWrapper.displayName = 'TableColumnHeaderWrapper'

export const TableColumnHeader: FC<{ column: ColumnInstance<any>; showFilters?: boolean }> = ({ column, ...props }) => {
  const children = [
    column.isSorted === true && (column.isSortedDesc === true ? <DownArrow key="downArrow" /> : <UpArrow key="upArrow" />),
    props.showFilters === true && column.canFilter && <TableFilters key="filter">{column.render('Filter')}</TableFilters>,
  ].filter(c => c !== false)
  const newProps = getElementProps(column, children, 'Header')
  return <TableColumnHeaderWrapper scope="col" {...props} {...newProps} />
}

export const TableHead = styled.thead`
  border-radius: 0.1875rem 0.1875rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > ${TableRow}:first-of-type {
    border-radius: 0.1875‬rem 0.1875‬rem 0 0;
    border-bottom: 0.0625rem solid ${Palette.neutral[40].hex};

    & > ${TableColumnHeaderWrapper}:first-of-type {
      border-radius: 0.1875‬rem 0 0 0;
    }

    & > ${TableColumnHeaderWrapper}:last-of-type {
      border-radius: 0 0.1875‬rem 0 0;
    }
  }
`
TableHead.displayName = 'TableHead'

const TableDataWrapper = styled.td<{ columnOverflow?: Overflow; cellType?: CellFormat; rawValue: number | string }>`
  color: ${Palette.neutral[100].hex};
  font: ${fontStyles.Body};
  line-height: 3.2rem;
  ${({ columnOverflow }) => {
    switch (columnOverflow) {
      case 'wrap':
        return `height: auto;
        white-space: normal;
        line-height: 1.2rem;
        margin: 1rem 0;`
      case 'truncate':
      default:
        return `height: 3rem;
        text-overflow: ellipsis;
        white-space: nowrap;`
    }
  }}

  overflow: hidden;
  padding: 0 1rem 0 1rem;

  & > a {
    ${textStyles.Link};
  }

  ${({ cellType, rawValue }) => {
    switch (cellType) {
      case 'percentage':
        return `text-align: right;
        color: ${rawValue <= percentageNegativeThreshold ? `${Palette.red[80].hex}` : 'inherit'};`
      case 'currency':
      case 'decimal':
      case 'number':
        return `text-align: right;
        color: ${rawValue <= numberNegativeThreshold ? `${Palette.red[80].hex}` : 'inherit'};`
      default:
        return 'text-align: left;'
    }
  }}
  ${rowPadding}
`

TableDataWrapper.displayName = 'TableDataWrapper'

export const TableData: FC<{ cell: Cell<any>; instance: TableInstance<any> }> = ({ cell, instance, ...props }) => {
  const newProps = useMemo(() => {
    const columnOverflow = cell.column.columnOverflow ?? instance.columnOverflow
    const newProps = { ...getElementProps(cell, [], 'Cell'), ...{ rawValue: cell.row.original[cell.column.id], cellType: cell.column.cellType, columnOverflow } }
    return { ...props, ...newProps }
  }, [cell, props, instance.columnOverflow])
  return <TableDataWrapper {...newProps} />
}

export const TableBody = styled.tbody`
  border-radius: 0 0 0.1875rem 0.1875rem;

  & > ${TableRow} {
    &:not(:last-of-type) {
      border-bottom: 0.0625rem solid ${Palette.neutral[15].hex};
    }

    &:last-of-type {
      & > ${TableDataWrapper}:first-of-type {
        border-radius: 0 0 0.1875‬rem 0;
      }

      & > ${TableDataWrapper}:last-of-type {
        border-radius: 0 0 0 0.1875rem;
      }

      border-radius: 0 0 0.1875rem 0.1875rem;
    }
  }
`
TableBody.displayName = 'TableBody'

export const AmethystHeader = styled.span`
  padding: 0 0 0 1rem;
`
