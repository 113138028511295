import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'
import { Palette } from '@style'

export const NoDataMessage = styled.div`
  font: 500 1.125rem/1.5rem Roboto, sans-serif;
  color: ${Palette.neutral[70].hex};
  padding: 4rem 1.25rem;
  background-color: ${Palette.neutral[0].hex};
  text-align: center;
`

const NoDataTableRow = styled.tr`
  display: flex;
`
const NoDataTableData = styled.td`
  display: flex;
  flex: 1;

  & > * {
    flex: 1;
  }
`

export const NoData: FC = ({ children }) => (
  <NoDataTableRow>
    <NoDataTableData>{typeof children === 'string' ? <NoDataMessage>{children}</NoDataMessage> : children}</NoDataTableData>
  </NoDataTableRow>
)
