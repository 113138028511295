import React, { useCallback } from 'react'
import styled from 'styled-components'
import type { Cell } from '../../types'
import { defaultTheme } from '@themes'
import { Palette } from '@style'

const NumberInput = styled.input`
  border-radius: 0.1875rem;
  border: solid 0.0625rem ${Palette.neutral[50].hex};
  height: 1.25rem;
  outline: none;
  padding: 0.125rem 0.25rem;
  width: 2.5rem;

  :focus {
    border: ${props => (Boolean(props['aria-disabled']) ? 'none' : `solid 0.0625rem ${props.theme.colors.octonary.hex}`)};
  }
`
NumberInput.defaultProps = {
  theme: defaultTheme,
}
const InterstitialText = styled.span`
  height: 1.625rem;
  margin: 0 0.5rem;
  padding-top: 0.25rem;
`

const onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void = event => event.stopPropagation()
type OnChange = (event: React.ChangeEvent<HTMLInputElement>) => void

const Filter = styled.div`
  height: 1.25rem;
  display: flex;
  justify-content: flex-end;
  box-sizing: content-box;
`

export const NumberRangeTableFilter = <TData extends AnyObject>({ column: { filterValue = [], setFilter } }: Cell<TData>) => {
  const minOnChange: OnChange = useCallback(
    e => {
      const val = e.target.value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setFilter!((old: any = []) => [val ? Number(val) : undefined, old[1]])
    },
    [setFilter],
  )
  const maxOnChange: OnChange = useCallback(
    e => {
      const val = e.target.value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setFilter!((old: any = []) => [old[0], val ? Number(val) : undefined])
    },
    [setFilter],
  )
  return (
    <Filter>
      <NumberInput value={filterValue[0]} type="number" aria-label="Min" onClick={onClick} onChange={minOnChange} />
      <InterstitialText>to</InterstitialText>
      <NumberInput value={filterValue[1]} type="number" aria-label="Max" onClick={onClick} onChange={maxOnChange} />
    </Filter>
  )
}
