import React from 'react'
import { SVG } from './SVG'
const MinusSquare = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size={'large'} viewBox={'-2 -2 20 20'}>
    <path
      d="M3.85714 9C3.62143 9 3.42857 8.80714 3.42857 8.57143V7.42857C3.42857 7.19286 3.62143 7 3.85714 7H12.1429C12.3786 7 12.5714 7.19286 12.5714 7.42857V8.57143C12.5714 8.80714 12.3786 9 12.1429 9H3.85714ZM16 1.71429V14.2857C16 15.2321 15.2321 16 14.2857 16H1.71429C0.767857 16 0 15.2321 0 14.2857V1.71429C0 0.767857 0.767857 0 1.71429 0H14.2857C15.2321 0 16 0.767857 16 1.71429ZM14.2857 14.0714V1.92857C14.2857 1.81071 14.1893 1.71429 14.0714 1.71429H1.92857C1.81071 1.71429 1.71429 1.81071 1.71429 1.92857V14.0714C1.71429 14.1893 1.81071 14.2857 1.92857 14.2857H14.0714C14.1893 14.2857 14.2857 14.1893 14.2857 14.0714Z"
      fill="currentColor"
    />
  </SVG>
)

export default MinusSquare
