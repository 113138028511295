import styled from 'styled-components'
import { textStyles } from '@style'

export const Body = styled.div`
  ${textStyles.Body}
`
export const BodyHeavy = styled.div`
  ${textStyles.BodyHeavy}
`
export const BodyLarge = styled.div`
  ${textStyles.BodyLarge}
`
export const BodyLargeHeavy = styled.div`
  ${textStyles.BodyLargeHeavy}
`
export const BodyLargeSubdued = styled.div`
  ${textStyles.BodyLargeSubdued}
`
export const BodyLargeReversed = styled.div`
  ${textStyles.BodyLargeReversed}
`
export const BodyLargeMedium = styled.div`
  ${textStyles.BodyLargeMedium}
`
export const BodyLargeMediumSubdued = styled.div`
  ${textStyles.BodyLargeMediumSubdued}
`
export const BodyLargeMediumReversed = styled.div`
  ${textStyles.BodyLargeMediumReversed}
`

export const BodySmall = styled.div`
  ${textStyles.BodySmall}
`
export const BodySmallCaps = styled.div`
  ${textStyles.BodySmallCaps}
`
export const BodySmallHeavy = styled.div`
  ${textStyles.BodySmallHeavy}
`
export const BodySmallSubdued = styled.div`
  ${textStyles.BodySmallSubdued}
`
export const BodySmallRed = styled.div`
  ${textStyles.BodySmallRed}
`
export const BodySmallReversed = styled.div`
  ${textStyles.BodySmallReversed}
`
