import styled from 'styled-components'
import { TinyColor } from '@ctrl/tinycolor'
import { Palette, textStyles } from '@style'

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${Palette.neutral[70].hex};
  display: flex;
  height: 1.25rem;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  width: 1.25rem;
`
UnstyledButton.displayName = 'UnstyledButton'

export const TooltipWrapper = styled.div`
  & div.rpt-container {
    margin: -0.75rem 0 0 2.875rem;
  }

  & div.rpt-arrow {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  & div.rpt-textbox-container {
    top: 1rem !important;
    left: -2.25rem !important;
  }

  & div.rpt-shadow-container {
    box-shadow: 0 0.0625rem 0.3125rem 0 rgba(37, 37, 37, 0.12), 0 0 0 0.0625rem rgba(37, 37, 37, 0.1) !important;
  }
`
TooltipWrapper.displayName = 'TooltipWrapper'

export const TooltipText = styled.div`
  ${textStyles.BodySmall}
`
TooltipText.displayName = 'TooltipText'

const hoverBackgroundColor = new TinyColor(Palette.neutral[80].hex).setAlpha(0.1).toRgbString()
export const PopoverText = styled.div`
  padding: 0 !important;
  background-color: ${Palette.neutral[0].hex} !important;

  > span {
    ${textStyles.BodyLarge}
    padding: 0.75rem 1rem;

    :hover {
      background-color: ${hoverBackgroundColor};
    }
  }
`
PopoverText.displayName = 'PopoverText'
