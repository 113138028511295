import type { ReactNode, ComponentProps } from 'react'
import React from 'react'
import { useRouteMatch } from 'react-router'
import type { NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { defaultTheme } from '@themes'
import { Palette, textStyles } from '@style'
import { NavLink } from '@internal'
import type { ClassNameProp } from '@components/types'

const NavigationLink = styled(NavLink).attrs(p => ({
  as: Boolean(p.href) ? 'a' : NavLink,
  to: p.to ?? '/',
}))<NavLinkProps & ReactRouterLink>`
  border-bottom-color: ${props => props.theme.neutrals.quaternary.hex};
  padding: 1rem;
  user-select: none;
  text-decoration: none;
  ${textStyles.BodySmall};

  &.active {
    ${textStyles.BodySmallHeavy};
    background-color: ${Palette.blue[5].hex};
  }

  &:focus,
  &:hover {
    background-color: ${props => props.theme.neutrals.septenary.hex};

    &.active {
      background-color: ${Palette.blue[5].hex};
    }
  }

  &:focus {
    outline: 0.0625rem solid ${props => props.theme.neutrals.quinary.hex};

    &.active {
      outline: none;
    }
  }

  &:hover {
    outline: none;
  }
`
NavigationLink.displayName = 'NavigationLink'
NavigationLink.defaultProps = {
  theme: defaultTheme,
}

const getPath = (path: ComponentProps<typeof NavLink>['to'], prefix: string) => {
  if (typeof path !== 'string') {
    return path
  }
  const matchedPath = prefix === '/' ? '' : prefix
  return path.startsWith('/') ? `${matchedPath}${path}` : `${matchedPath}/${path}`
}

export interface NavBarItemOptions {
  label: string | ReactNode
}

const isReactRouterLink = (props: AnchorLink | ReactRouterLink): props is ReactRouterLink => 'to' in props

export const NavBarItem = (props: NavBarItemOptions & (AnchorLink | ReactRouterLink)): JSX.Element => {
  return isReactRouterLink(props) ? <NavBarLink {...props} /> : <NavBarAnchor {...props} />
}
NavBarItem.displayName = 'NavBarItem'

const NavBarLink = ({ to, label, ...props }: NavBarItemOptions & ReactRouterLink): JSX.Element => {
  const match = useRouteMatch()
  const navPath = getPath(to, match.url)
  return (
    <NavigationLink to={navPath} {...props}>
      {label}
    </NavigationLink>
  )
}
NavBarLink.displayName = 'NavBarLink'

const NavBarAnchor = ({ label, ...props }: NavBarItemOptions & AnchorLink): JSX.Element => {
  return <NavigationLink {...props}>{label}</NavigationLink>
}
NavBarAnchor.displayName = 'NavBarAnchor'

export type NavBarProps = NavBarChildrenProps | NavBarConfigProps
export type NavBarConfigItem = {
  label: string
} & (
  | {
      href: string
    }
  | { to: NavLinkProps['to'] }
)

export interface NavBarConfigProps extends ClassNameProp {
  children?: never
  items: NavBarConfigItem[]
}

export interface NavBarChildrenProps extends ClassNameProp {
  children: JSX.Element | JSX.Element[]
  items?: never
}

export interface ReactRouterLink extends NavLinkProps {
  /**
   * Route for the title and text if using react-router
   */
  to: NavLinkProps['to']
}
export interface AnchorLink {
  /**
   * Route for the title and text if it's an absolute URI or not using react-router
   */
  href: ComponentProps<'a'>['href']
}

const Nav = styled.nav`
  display: flex;
  border-bottom: 0.0625rem solid ${props => props.theme.neutrals.tertiary.hex};
  margin-bottom: 1.25rem;
  padding: 0 1.25rem;
  position: relative;
`
Nav.displayName = 'Nav'
Nav.defaultProps = {
  theme: defaultTheme,
}

export const NavBar = ({ className, ...props }: NavBarProps): JSX.Element => {
  const children = React.Children.toArray(props.children || props.items.map(p => <NavBarItem {...p} />))
  return <Nav className={className}>{children}</Nav>
}
NavBar.displayName = 'NavBar'
