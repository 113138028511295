import { createGlobalStyle } from 'styled-components'
import { fontStyles } from '../constants'

export const GlobalStyles = createGlobalStyle`
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    /* stylelint-disable-next-line unit-blacklist */
    font-size: 16px;

    * {
      font: ${fontStyles.Body};
    }
  }
`
