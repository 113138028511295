import type { SyntheticEvent } from 'react'
import { useCallback, useState } from 'react'

type Values<TValue> = { value: TValue } | { defaultValue?: TValue; value?: never }
export type TValueType<TValue> = TValue extends string
  ? 'string'
  : TValue extends number
  ? 'number'
  : TValue extends FileList
  ? 'file'
  : // eslint-disable-next-line @typescript-eslint/ban-types
  TValue extends object
  ? 'object'
  : TValue extends Array<infer _U>
  ? 'array'
  : never
export type ValueProps<TValue> = { onChange: (value: TValue) => void } & Values<TValue>
export type ValuePropsWithType<TValue> = ValueProps<TValue> & { type: TValueType<TValue> }

const getValue = <TValue extends SupportedTypes>(props: Values<TValue>) => {
  if ('value' in props) {
    return [props.value]
  } else {
    return [undefined, props.defaultValue]
  }
}

const coerceValue = <TValue extends SupportedTypes>(event: React.ChangeEvent<any>, type: TValueType<TValue>): TValue => {
  if (type === 'file') {
    return ((event as unknown) as React.ChangeEvent<HTMLInputElement>).target.files as TValue
  }
  const { value } = event.target
  if (value === null || value === undefined) {
    return value
  }
  switch (type) {
    case 'number':
      return Number(value) as TValue
    default:
      return value
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type SupportedTypes = string | number | FileList | object
export type UseControlledProps<TValue extends SupportedTypes> = ValuePropsWithType<TValue>

const isSyntheticEvent = <TValue extends SupportedTypes>(e: SyntheticEvent<any> | TValue | null): e is SyntheticEvent => {
  return Boolean(e !== null && 'nativeEvent' in e && e.nativeEvent instanceof Event)
}

export const useControlled = <TValue extends SupportedTypes>({ onChange, ...props }: UseControlledProps<TValue>) => {
  const [value, defaultValue] = getValue<TValue>(props)
  const [valueState, setValue] = useState(defaultValue)
  const handleChange = useCallback(
    // The event type might be different from the type passed in as value,
    // so we need to do some coercion.
    (event: React.SyntheticEvent<any> | TValue | null) => {
      const newValue = isSyntheticEvent(event) ? coerceValue<TValue>(event, props.type) : (event as TValue)

      if ('value' in props && props.value !== undefined) {
        if (value === newValue) {
          return
        }
        onChange(newValue)
        return
      }
      if (valueState === newValue) {
        return
      }
      setValue(newValue)
      onChange(newValue)
    },
    [onChange, props, value, valueState],
  )
  return [value !== undefined ? value : valueState, handleChange, value !== undefined] as const
}
