import type { ColorInput } from '@ctrl/tinycolor'
import { TinyColor } from '@ctrl/tinycolor'

const Purple1000 = '#5c1249'
const Purple900 = '#831f67'
const Purple800 = '#9e1279'
const Purple600 = '#c93494'
const Purple400 = '#da78c2'
const Purple200 = '#eeb5e0'
const Purple100 = '#fadff3'
const Purple50 = '#ffeefb'
const Blue1000 = '#0e5484'
const Blue900 = '#136199'
const Blue800 = '#1271b2'
const Blue600 = '#0c84d6'
const Blue400 = '#2db2e6'
const Blue200 = '#89ceeb'
const Blue100 = '#c7e3f4'
const Blue50 = '#e6f5ff'
const BlueWashed = '#f4f6f8'
const Teal1000 = '#015c57'
const Teal900 = '#00736c'
const Teal800 = '#018e86'
const Teal600 = '#00b8b0'
const Teal400 = '#6cd5cf'
const Teal200 = '#9fe9e3'
const Teal100 = '#dbfaf7'
const Teal50 = '#f2fffd'
const TealWashed = '#f4f8f7'
const Green1000 = '#397a11'
const Green800 = '#449711'
const Green600 = '#6fbe46'
const Green400 = '#95e969'
const Green200 = '#c1f8a4'
const Green100 = '#e6fbe8'
const Green50 = '#f1fff2'
const Red1000 = '#a7000d'
const Red900 = '#ba0916'
const Red800 = '#cf1220'
const Red600 = '#e32836'
const Red400 = '#fa7978'
const Red200 = '#fdc2c2'
const Red100 = '#ffe9e9'
const Red50 = '#fff4f4'
const Orange1000 = '#cf3c00'
const Orange800 = '#f15722'
const Orange600 = '#ff8a50'
const Orange400 = '#ffba88'
const Orange200 = '#fcd5ad'
const Orange100 = '#fef1ec'
const Orange50 = '#fdf7f5'
const Yellow1000 = '#be8307'
const Yellow800 = '#f1a103'
const Yellow600 = '#fcb92a'
const Yellow400 = '#fadb7a'
const Yellow200 = '#f9eabf'
const Yellow100 = '#fcf4e1'
const Yellow50 = '#fffbf1'
const Neutral1000 = '#252525'
const Neutral900 = '#474747'
const Neutral800 = '#666666'
const Neutral700 = '#808080'
const Neutral600 = '#929292'
const Neutral500 = '#b1b1b1'
const Neutral400 = '#c7c7c7'
const Neutral300 = '#d9d9d9'
const Neutral200 = '#e2e2e2'
const Neutral150 = '#e9e9e9'
const Neutral100 = '#f2f2f2'
const Neutral50 = '#fafafa'
const Neutral0 = '#ffffff'

const buildColors = (value: ColorInput) => {
  const color = new TinyColor(value)
  const disabledRgbColor = color.setAlpha(0.4).toRgbString()
  return {
    rgb: color.toRgb(),
    hsla: color.toHsl(),
    hex: color.toHexString(),
    disabled: disabledRgbColor,
  }
}

export const Palette = {
  purple: {
    100: buildColors(Purple1000),
    90: buildColors(Purple900),
    80: buildColors(Purple800),
    60: buildColors(Purple600),
    40: buildColors(Purple400),
    20: buildColors(Purple200),
    10: buildColors(Purple100),
    5: buildColors(Purple50),
  },
  blue: {
    100: buildColors(Blue1000),
    90: buildColors(Blue900),
    80: buildColors(Blue800),
    60: buildColors(Blue600),
    40: buildColors(Blue400),
    20: buildColors(Blue200),
    10: buildColors(Blue100),
    5: buildColors(Blue50),
    washed: buildColors(BlueWashed),
  },
  teal: {
    100: buildColors(Teal1000),
    90: buildColors(Teal900),
    80: buildColors(Teal800),
    60: buildColors(Teal600),
    40: buildColors(Teal400),
    20: buildColors(Teal200),
    10: buildColors(Teal100),
    5: buildColors(Teal50),
    washed: buildColors(TealWashed),
  },
  green: {
    100: buildColors(Green1000),
    80: buildColors(Green800),
    60: buildColors(Green600),
    40: buildColors(Green400),
    20: buildColors(Green200),
    10: buildColors(Green100),
    5: buildColors(Green50),
  },
  yellow: {
    100: buildColors(Yellow1000),
    80: buildColors(Yellow800),
    60: buildColors(Yellow600),
    40: buildColors(Yellow400),
    20: buildColors(Yellow200),
    10: buildColors(Yellow100),
    5: buildColors(Yellow50),
  },
  orange: {
    100: buildColors(Orange1000),
    80: buildColors(Orange800),
    60: buildColors(Orange600),
    40: buildColors(Orange400),
    20: buildColors(Orange200),
    10: buildColors(Orange100),
    5: buildColors(Orange50),
  },
  red: {
    100: buildColors(Red1000),
    90: buildColors(Red900),
    80: buildColors(Red800),
    60: buildColors(Red600),
    40: buildColors(Red400),
    20: buildColors(Red200),
    10: buildColors(Red100),
    5: buildColors(Red50),
  },
  neutral: {
    100: buildColors(Neutral1000),
    90: buildColors(Neutral900),
    80: buildColors(Neutral800),
    70: buildColors(Neutral700),
    60: buildColors(Neutral600),
    50: buildColors(Neutral500),
    40: buildColors(Neutral400),
    30: buildColors(Neutral300),
    20: buildColors(Neutral200),
    15: buildColors(Neutral150),
    10: buildColors(Neutral100),
    5: buildColors(Neutral50),
    0: buildColors(Neutral0),
  },
  transparentDark: { 100: buildColors('rgba(0, 0, 0, 0.86)') },
  interactionStateHover: { 100: buildColors('rgba(226, 226, 226, 0.21)') },
  interactionStateSelected: { 100: buildColors('rgba(0, 115, 108, 0.06)') },
}
