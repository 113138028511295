import Fuse from 'fuse.js'
import type { FuzzySearchOptions } from './forms/shared/types'

const DEFAULT_KEYS = ['label']
export const fuseSearch = <TData extends AnyObjectGenericPlaceholder>(inputItems: Array<TData>, options?: FuzzySearchOptions) => {
  const keys = options?.keys || DEFAULT_KEYS

  const defaultFuseOptions = {
    distance: 100,
    keys,
    location: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.2,
  }

  const fuseOptions = { ...defaultFuseOptions, ...options }
  return new Fuse(inputItems, fuseOptions)
}
