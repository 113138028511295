import React, { useRef, useMemo } from 'react'
import styled from 'styled-components'
import { MD5 } from 'object-hash'
import { Palette, fontStyles } from '@style'
import { Times, ChevronDown, SquareChecked, Square } from '@assets'
import { defaultTheme } from '@themes'
import { useCheckboxFilter } from './useCheckboxFilter'
import type { Cell } from '../../../types'
import type { FuzzySearchOptions } from '@components/forms'

const ChevronButton = styled.button.attrs(() => ({ children: <ChevronDown /> }))<{ disabled: boolean }>`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${props => (props.disabled ? Palette.neutral[50].hex : Palette.neutral[70].hex)};
  display: flex;
  flex-basis: 10%;
  flex-shrink: 0;
  flex-grow: 0;
  height: auto;
  outline: none;
  padding: 0;
  width: auto;

  svg {
    flex-grow: 1;
  }
`

const ClearButton = styled.div`
  align-items: center;
  background: transparent;
  border: solid 0.0625rem transparent;
  color: ${props => (props['aria-disabled'] === true ? Palette.neutral[50].hex : Palette.neutral[70].hex)};
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  height: 1rem;
  line-height: 1.25rem;
  outline: none;
  padding: 0;
  width: 1rem;

  :focus {
    border: ${props => (props['aria-disabled'] === true ? 'none' : `solid 0.0625rem ${props.theme.colors.octonary.hex}`)};
  }

  &:disabled {
    color: ${Palette.neutral[50].hex};
  }
`
ClearButton.defaultProps = {
  theme: defaultTheme,
}

const OptionsList = styled.div<{ isOpen: boolean; width: number }>`
  box-sizing: border-box;
  margin-top: 0.35rem;
  max-height: 13.5rem;
  outline: none;
  overflow: auto;
  padding-bottom: 0.5rem;
  padding-left: 0.0625rem;
  padding-top: 0.6875rem;
  padding-right: 0.0625rem;
  position: absolute;
  font: ${fontStyles.Body};
  width: ${p => `${p.width + 32}px`};
  margin-left: -1rem;

  ${props =>
    props.isOpen &&
    `
    display: flex;
    flex-direction: column;
    background-color: ${Palette.neutral[0].hex};
    border: solid 0.0625rem ${props.theme.colors.octonary.hex};
    border-radius: 0.1875rem; 
  `}
  z-index: 100;
`
OptionsList.displayName = 'OptionsList'
OptionsList.defaultProps = {
  theme: defaultTheme,
}
const OptionListItem = styled.label`
  word-break: break-all;
  position: relative;
  outline: none;
  &[aria-disabled='true'] {
    color: ${Palette.neutral[100].disabled};
  }
`
OptionListItem.displayName = 'OptionListItem'

const Container = styled.div<{ isOpen: boolean }>`
  align-items: center;
  border-radius: 0.1875rem;
  border: solid 0.0625rem ${props => props.theme.neutrals.senary.hex};
  box-sizing: border-box;
  display: flex;
  height: auto;
  outline: none;
  padding-right: 0.25rem;
  width: auto;

  :focus,
  :focus-within {
    border: solid 0.0625rem ${props => props.theme.colors.octonary.hex};
  }
  /* This ensure the borders are always applied at the same time
  / as the OptionsList relies on isOpen to color the border
  */
  ${props =>
    props.isOpen &&
    `border: solid 0.0625rem ${props.theme.colors.octonary.hex};
  `}
`

const CheckBoxInput = styled.input`
  border: none;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  flex-basis: 75%;
  flex-grow: 1;
  min-width: 0;
  outline: none;
  padding: 0.125rem 0.25rem;
`

Container.displayName = 'Container'
Container.defaultProps = {
  theme: defaultTheme,
}

const Checkbox = styled.span`
  height: 1.25rem;
  outline: none;
`
Checkbox.defaultProps = {
  role: 'checkbox',
}

const CheckIcon = styled(({ checked, className }: { checked: boolean; className?: string }) => {
  const Icon = checked ? SquareChecked : Square
  return <Icon className={className} />
})`
  color: ${({ checked, theme }) => (checked ? theme.colors.primary.hex : Palette.neutral[80].hex)};
  font-size: 1.143rem;
  margin-right: 0.25rem;
`

CheckIcon.defaultProps = {
  theme: defaultTheme,
}

const CheckboxBoxItem = styled.div<{ isHighlighted: boolean }>`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  margin: 0.25rem 0.1rem;
  padding: 0.04rem 0.2rem 0.02rem 0.1rem;
  ${props => (props.isHighlighted ? `background-color: ${Palette.neutral[10].hex};` : ``)}
  label {
    color: ${Palette.neutral[80].hex};
    font: ${fontStyles.BodySmall};
    line-height: 1.25rem;
  }
`

export const CheckboxTableFilter = <TData extends Record<string, unknown>>({ fuzzySearchOptions, ...cell }: Cell<TData> & { fuzzySearchOptions?: FuzzySearchOptions }) => {
  const {
    column: { id },
  } = cell

  const containerRef = useRef<HTMLDivElement>(null)
  const clientWidth = containerRef.current?.clientWidth
  const width = useMemo(() => clientWidth ?? 160, [clientWidth])
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    // setInputValue,
    optionItems,
    selectedItems,
    reset,
  } = useCheckboxFilter(cell, fuzzySearchOptions)
  return (
    <>
      <div ref={containerRef} title={undefined}>
        <Container
          isOpen={isOpen}
          {...getComboboxProps()}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <CheckBoxInput {...getInputProps()} />
          {selectedItems.length > 0 && (
            <ClearButton
              onClick={() => {
                reset()
              }}
            >
              <Times />
            </ClearButton>
          )}
          <ChevronButton {...getToggleButtonProps()} />
        </Container>
      </div>
      <OptionsList
        width={width}
        isOpen={isOpen}
        {...getMenuProps()}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {isOpen &&
          optionItems.map((item, index) => {
            const check = !!selectedItems.find(si => si.value === item.value)
            // Maybe try and re-use the checkbox list from facets, especially the virtualization, if possible per standup
            return (
              <CheckboxBoxItem isHighlighted={highlightedIndex === index} {...getItemProps({ item, index, isSelected: selectedItems.includes(item) })} key={MD5(item)}>
                <Checkbox aria-labelledby={id} aria-checked={check} style={{ paddingTop: '0.0625rem' }}>
                  <CheckIcon checked={check} />
                </Checkbox>
                <OptionListItem
                  onMouseEnter={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  {item.label}
                </OptionListItem>
              </CheckboxBoxItem>
            )
          })}
      </OptionsList>
    </>
  )
}
