import type { FC, ComponentProps } from 'react'
import React from 'react'
import styled from 'styled-components'
import { Palette } from '@style'
import SpinsLogo from '@assets/images/SpinsLogo'
import { defaultTheme } from '@themes'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { ClassNameProp } from '@components/types'

const DIVIDER_COLOR = '#85898d'
const HEADER_COLOR = '#30373E'
const HEADER_FONT = '300 1.125rem/1.5rem Roboto, sans-serif'

const HeaderLine = styled.div`
  height: 0.125rem;
  background-color: ${p => p.theme.colors.primary.hex};
  flex-basis: 100%;
`

HeaderLine.defaultProps = {
  theme: defaultTheme,
}

const Logo = styled(SpinsLogo)`
  padding-right: 0.5rem;
`

const HeaderTitle = styled.div`
  color: ${Palette.neutral[0].hex};
  font: ${HEADER_FONT};
  padding-left: 0.5rem;
`

const LogoAndTitle = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 2rem;
  padding: 0.4375rem 0;
  padding-left: 1.25rem;
`

const LogoAndTitleContainer = styled.header`
  background-color: ${HEADER_COLOR};
  height: 3rem;
`
const Divider = styled.div`
  background-color: ${DIVIDER_COLOR};
  height: 2rem;
  width: 0.0625rem;
`

const HeaderLink = styled(Link).attrs(p => ({
  as: Boolean(p.href) ? 'a' : Link,
  to: p.to ?? '/',
}))<LinkProps & ReactRouterLink>`
  align-items: center;
  display: flex;
  cursor: pointer;
  text-decoration: none;
`
interface ReactRouterLink {
  /**
   * Route for the title and text if using react-router
   */
  to?: LinkProps['to']
}
interface AnchorLink {
  /**
   * Route for the title and text if it's an absolute URI or not using react-router
   */
  href?: ComponentProps<'a'>['href']
}
export interface HeaderProps extends ClassNameProp {
  title: React.ReactNode
}

export const Header: FC<HeaderProps & (ReactRouterLink | AnchorLink)> = ({ children, className, title, ...props }) => {
  return (
    <LogoAndTitleContainer role="banner" className={className}>
      <HeaderLine />
      <LogoAndTitle>
        <HeaderLink {...props}>
          <Logo />
          <Divider />
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderLink>
        {children}
      </LogoAndTitle>
    </LogoAndTitleContainer>
  )
}
