import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '@themes'
import type { InputValidation, SupportedTypes, TValueType, UseControlledProps } from '../shared'
import { HelpText, ValidationText, ErrorIcon, WarningIcon, ValidationType, Centered, getValidationBorderColor, useControlled } from '../shared'
import { Palette, fontStyles } from '@style'
import { FormLabel } from '../shared/FormLabel'
import type { SupportedValueTypes, BaseInputProps, ElementProps } from './types'

const InputBox = styled.input.attrs(props => ({
  type: props.type === 'textarea' ? undefined : props.type,
  as: props.type === 'textarea' ? 'textarea' : undefined,
}))<{ validation?: InputValidation }>`
  width: 20rem;
  min-height: 2.75rem;
  box-sizing: border-box;
  padding: 0.75rem 0 0.75rem 1rem;
  border-radius: 0.1875rem;
  border: solid 0.0625rem ${props => getValidationBorderColor(props, props.theme.neutrals.senary.hex)};
  background-color: ${props => {
    if (props.validation?.type === ValidationType.Error) {
      return Palette.red[5].hex
    }
    return props.theme.neutrals.primary.hex
  }};
  font: ${fontStyles.Body};
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &:not(disabled) {
    outline: none;
  }

  &:focus {
    box-sizing: border-box;
    box-shadow: 0 0 0 0.0625rem ${props => getValidationBorderColor(props, props.theme.colors.octonary.hex)};
    border-color: ${props => getValidationBorderColor(props, props.theme.colors.octonary.hex)};
  }

  &:disabled {
    background-color: ${props => props.theme.neutrals.tertiary.hex};
  }
`
InputBox.displayName = 'InputBox'
InputBox.defaultProps = {
  theme: defaultTheme,
}

const getType = <TType extends AnyObject>(type: SupportedValueTypes): TValueType<TType> => {
  switch (type) {
    case 'text':
    case 'textarea':
      return 'string' as TValueType<TType>
    case 'number':
      return 'number' as TValueType<TType>
    case 'file':
      return 'file' as TValueType<TType>
    default:
      throw new Error('Invalid type')
  }
}

type LooseUseControlledProps = {
  onChange: (value: any) => void
  type: any
} & ({ value: any } | { defaultValue?: any })

// We coerce these types to help TS out a bit as they're a bit convoluted.
// This is likely our failing, not tsc.
const getUseControlledProps = <TType extends SupportedTypes>(props: LooseUseControlledProps): UseControlledProps<TType> =>
  ({ ...props, type: getType(props.type) } as UseControlledProps<TType>)

export type InputProps<TType extends SupportedValueTypes> = BaseInputProps<TType> & ElementProps<TType>

export const Input = <TType extends SupportedValueTypes = 'text'>({ label, helpText, validation, className, name, ...props }: InputProps<TType> & { className?: string }) => {
  const [value, handler] = useControlled<TType>(getUseControlledProps(props))
  const valueProps = 'value' in props ? { value } : { defaultValue: value }
  const { onChange: _, ...spreadProps } = props
  return (
    <div className={className} style={{ boxSizing: 'border-box' }}>
      <FormLabel as="label" id={`${name}_label`}>
        {label}
        <InputBox
          aria-describedby={validation ? `${name}_error` : `${name}_helptext`}
          aria-invalid={!!validation}
          aria-labelledby={`${name}_label`}
          name={name}
          onChange={handler}
          validation={validation}
          {...valueProps}
          {...spreadProps}
        />
      </FormLabel>
      <Centered aria-live="polite">
        {validation ? (
          <span style={{ display: 'flex' }}>
            {validation.type === ValidationType.Error && <ErrorIcon />}
            {validation.type === ValidationType.Warning && <WarningIcon />}
            <ValidationText id={`${name}_error`} type={validation.type} as="span">
              {validation.message}
            </ValidationText>
          </span>
        ) : (
          <HelpText as="span" id={`${name}_helptext`}>
            {helpText}
          </HelpText>
        )}
      </Centered>
    </div>
  )
}

Input.defaultProps = {
  type: 'text' as SupportedValueTypes,
}
