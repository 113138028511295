import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useA11yToggleButton } from '@accessible/toggle-button'
import { Check } from '@assets'
import { Body } from '@components/textStyles'
import { Palette } from '@style'
import tinycolor from '@ctrl/tinycolor'
import type { ChipProps } from './types'

const shadowColor = tinycolor(Palette.neutral[100].hex).setAlpha(0.3).toRgbString()
const ChipContainer = styled(Body)`
  background-color: ${Palette.neutral[15].hex};
  border-radius: 16px;
  margin-left: 8px;
  padding: 0.375rem 12px;
  display: flex;
  user-select: none;
  cursor: pointer;

  :focus {
    outline: none;
    box-shadow: 0px 0px 6px rgba(37, 37, 37, 0.3);
    box-shadow: 0px 0px 6px ${shadowColor};
  }

  &[aria-pressed='true'] {
    background-color: ${Palette.neutral[80].hex};
    color: ${Palette.neutral[0].hex};

    :hover {
      background-color: ${Palette.neutral[90].hex};
    }

    :active {
      background-color: ${Palette.neutral[100].hex};
    }

    svg {
      width: 1rem;
      margin-right: 0.375rem;
    }
  }
  &[aria-pressed='false'] {
    background-color: ${Palette.neutral[15].hex};

    :hover {
      background-color: ${Palette.neutral[30].hex};
    }

    :active {
      background-color: ${Palette.neutral[50].hex};
    }
  }
`

export const Chip = ({ checked, onChange: propOnChange, children }: ChipProps): JSX.Element => {
  const ref = useRef(null)
  const onChange = useCallback((v: boolean) => propOnChange(v), [propOnChange])
  const a11yProps = useA11yToggleButton(ref, {
    active: checked,
    onChange,
  })

  return (
    <ChipContainer ref={ref} {...a11yProps}>
      {checked && <Check aria-hidden={true} />}
      {children}
    </ChipContainer>
  )
}
