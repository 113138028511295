export const NotificationType: Record<string, NotificationType> = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
}
export type NotificationType = 'error' | 'info' | 'success' | 'warning'

export interface Options {
  appearance: NotificationType
  autoDismiss?: boolean
  autoDismissTimeout?: number
  onDismiss?: (id: string) => void
  smallWidth?: number
  mediumWidth?: number
  largeWidth?: number
}

export type UseToasts = () => {
  addToast: (content: React.ReactNode, options?: Options, callback?: (id: string) => void) => void
  removeToast: (id: string, callback?: () => void) => void
  removeAllToasts: () => void
  updateToast: (id: string, options?: Options, callback?: (id: string) => void) => void
  toastStack: {
    content: React.ReactNode
    id: string
    appearance: NotificationType
  }[]
}

export type Placement = 'bottom-left' | 'bottom-center' | 'bottom-right' | 'top-left' | 'top-center' | 'top-right'
