import React from 'react'
import styled from 'styled-components'
import { Times, Search } from '@assets'
import { Palette } from '@style'

export const FacetCheckboxContainer = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => (expanded ? 'overflow-y: auto; max-height: 12.5rem;' : '')}
`

const FilterBoxInput = styled.input`
  border: none;
  height: 2rem;
  box-sizing: border-box;
  background: transparent;
  outline: none;
  width: 8.5625rem;
`

const FilterBoxIcon = styled.span`
  border: none;
  color: ${Palette.neutral[80].hex};
  display: inline-block;
  height: 2rem;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 1.143rem;
  padding-top: 0.4rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;

  button {
    color: ${Palette.neutral[80].hex};
  }
`
const ClearIcon = styled(FilterBoxIcon)`
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
`

const FilterBoxContainer = styled.div`
  display: inline-block;
  border: 0.0625rem solid ${Palette.neutral[80].hex};
  border-radius: 0.1875rem;
  box-sizing: border-box;
  margin-bottom: 0.25rem;

  &:focus-within {
    border-color: ${Palette.blue[40].hex};

    span:first-of-type {
      color: ${Palette.blue[40].hex};
    }
  }

  width: 100%;
`

const ClearButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.05rem;
  padding-top: 0.1rem;
  vertical-align: top;
  font-size: 1.143rem;
`
export const FilterBox = ({ onChange, placeholder, value, ...props }: { onChange: (value: string) => void; placeholder: string; value: string }) => {
  return (
    <FilterBoxContainer>
      <FilterBoxIcon>
        <Search />
      </FilterBoxIcon>
      <FilterBoxInput {...props} value={value} onChange={e => onChange(e.currentTarget.value)} placeholder={placeholder} />
      <ClearIcon>
        {value && (
          <ClearButton onClick={() => onChange('')} aria-label="Clear filter">
            <Times size={'small'} />
          </ClearButton>
        )}
      </ClearIcon>
    </FilterBoxContainer>
  )
}
