import type { Dispatch } from 'react'
import { useCallback, useMemo, useReducer } from 'react'
import _ from 'lodash'
import type { State, Action, MetaCheckboxItem, CheckboxItem, LargeFacetConfig, SelectionType } from '../types'
import { useOnChange } from './useOnChange'
import Fuse from 'fuse.js'

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 64,
  minMatchCharLength: 1,
  keys: ['label'],
  findAllMatches: true,
  caseSensitive: false,
}

export const getInitialState = <TValue extends number | string>(
  selectedValues: TValue[],
  items: CheckboxItem<TValue>[],
  sort: boolean,
  selectionType: SelectionType = 'tristate',
  filter = '',
): State<TValue> => ({
  allSelected:
    selectionType === 'tristate'
      ? selectedValues.length > 0 && selectedValues.length !== items.length
        ? 'mixed'
        : selectedValues.length === items.length
        ? 'true'
        : 'false'
      : 'false',
  filter: filter,
  items: { list: sort ? _.sortBy(items, ['label']) : items, fuse: new Fuse(items, fuseOptions) },
  // TODO: This filter on items should be revisted at some point so we don't have to iterate. Maybe a map?
  selectedItems: selectionType === 'tristate' && items.length === selectedValues.length ? items : items.filter((item: CheckboxItem<TValue>) => selectedValues.includes(item.value)),
  selectionType: selectionType,
})

// TODO: Add proper types for the actions

const allSelectedState = (selectedItemsCount: number, totalItemsCount: number) => (selectedItemsCount === totalItemsCount ? 'true' : selectedItemsCount === 0 ? 'false' : 'mixed')

export type FacetReducer<TValue> = (state: State<TValue>, action: Action<any>) => State<TValue>

export const effectfulReducer = <TValue extends number | string>(onChange: (values: TValue[]) => void) => (state: State<TValue>, action: Action<any>): State<TValue> => {
  switch (action.type) {
    case 'allSelected':
      onChange(state.items.list.map(i => i.value))
      return { ...state, allSelected: 'true' }
    case 'allDeselected':
      onChange([])
      return { ...state, allSelected: 'false' }
    case 'itemSelected': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const withNewItem = [...state.selectedItems, action.payload!]
      onChange(withNewItem.map(i => i.value))
      return { ...state, allSelected: allSelectedState(withNewItem.length, state.items.list.length) }
    }
    case 'itemDeselected': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const withItemRemoved = state.selectedItems.filter(i => i.value !== action.payload!.value)
      onChange(withItemRemoved.map(i => i.value))
      return { ...state, allSelected: allSelectedState(withItemRemoved.length, state.items.list.length) }
    }
    case 'filter':
      return { ...state, filter: action.payload }
    case 'selectVisible': {
      const values = _.union(
        state.selectedItems.map(i => i.value),
        (action.payload as MetaCheckboxItem<TValue>[]).map(i => i.instance.value),
      )
      onChange(values)
      return state
    }
    case 'deselectVisible': {
      const values = state.selectedItems.filter(item => !(action.payload as MetaCheckboxItem<TValue>[]).some(i => i.instance.value === item.value)).map(i => i.value)
      onChange(values)
      return state
    }
    default:
      return state
  }
}

export const facetReducer = <TValue extends number | string>(state: State<TValue>, action: Action<any>): State<TValue> => {
  switch (action.type) {
    case 'reset':
      return {
        ...action.payload,
      }
    case 'allSelected':
      return { ...state, allSelected: 'true', selectedItems: [...state.items.list] }
    case 'allDeselected':
      return { ...state, allSelected: 'false', selectedItems: [] }
    case 'itemSelected': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const withNewItem = [...state.selectedItems, action.payload!]
      return { ...state, allSelected: allSelectedState(withNewItem.length, state.items.list.length), selectedItems: withNewItem }
    }
    case 'itemDeselected': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const withItemRemoved = state.selectedItems.filter(i => i.value !== action.payload!.value)
      return { ...state, allSelected: allSelectedState(withItemRemoved.length, state.items.list.length), selectedItems: withItemRemoved }
    }
    case 'filter':
      return { ...state, filter: action.payload }
    case 'selectVisible':
      return state.allSelected === 'true'
        ? { ...state, selectedItems: action.payload.map((i: MetaCheckboxItem<TValue>) => i.instance), allSelected: 'false' }
        : {
            ...state,
            selectedItems: _.union(
              state.selectedItems,
              action.payload.map((i: MetaCheckboxItem<TValue>) => i.instance),
            ),
          }
    case 'deselectVisible':
      return {
        ...state,
        selectedItems: state.selectedItems.filter(item => !(action.payload as MetaCheckboxItem<TValue>[]).some(i => i.instance.value === item.value)),
      }
    default:
      return state
  }
}

export interface ControlledFacetValues<TValue extends number | string> {
  items: CheckboxItem<TValue>[]
  onChange: (values: TValue[]) => void
  value: TValue[]
}
export interface UncontrolledFacetValues<TValue extends number | string> {
  items: CheckboxItem<TValue>[]
  defaultValue: TValue[]
  onChange: (values: TValue[]) => void
}

const getValues = <TValue extends number | string>(params: ControlledFacetValues<TValue> | UncontrolledFacetValues<TValue>) =>
  'value' in params ? ([params['value'], true as const, params.onChange] as const) : ([params['defaultValue'], false as const, params.onChange] as const)

export const useFacetReducer = <TValue extends string | number>(
  state: ControlledFacetValues<TValue> | UncontrolledFacetValues<TValue>,
  config: LargeFacetConfig = { bubble: false, sort: false, selectionType: 'tristate' },
): [State<TValue>, Dispatch<Action<any>>] => {
  const [values, isControlled, onChange] = getValues<TValue>(state)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const initialState = useMemo(() => getInitialState(values, state.items, config.sort!, config.selectionType), [values, state.items, config.sort, config.selectionType])
  if (isControlled) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const red = effectfulReducer(onChange!)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useCallback(
      (action: Action<any>) => {
        red(initialState, action)
      },
      [initialState, red],
    )
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return [initialState, dispatch]
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = useReducer(facetReducer, initialState) as [State<TValue>, Dispatch<Action<any>>]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOnChange?.(result[0].selectedItems, onChange)
    return result
  }
}
