import { Palette } from './colors'
/**
 * CSS `font` property values that should be interpolated into CSS
 */
export const fontStyles = {
  Body: 'normal 0.875rem/1.25rem Roboto, sans-serif',
  BodyHeavy: 'bold 0.875rem/1.25rem Roboto, sans-serif',
  BodySmall: 'normal 0.75rem/1rem Roboto, sans-serif',
  BodySmallCaps: 'normal 0.75rem/1rem Roboto, sans-serif',
  BodySmallHeavy: '500 0.75rem/1rem Roboto, sans-serif',
  BodySmallSubdued: 'normal 0.75rem/1rem Roboto, sans-serif',
  BodySmallRed: 'normal 0.75rem/1rem Roboto, sans-serif',
  BodySmallReversed: 'normal 0.75rem/1rem Roboto, sans-serif',
  BodyLarge: 'normal 1rem/1.25rem Roboto, sans-serif',
  BodyLargeHeavy: '500 1rem/1.25rem Roboto, sans-serif',
  BodyLargeReversed: 'normal 1rem/1.25rem Roboto, sans-serif',
  BodyLargeSubdued: 'normal 1rem/1.25rem Roboto, sans-serif',
  BodyLargeMedium: '500 1rem/1.25rem Roboto, sans-serif',
  BodyLargeMediumSubdued: '500 1rem/1.25rem Roboto, sans-serif',
  BodyLargeMediumReversed: '500 1rem/1.25rem Roboto, sans-serif',
  Display1: 'lighter 2.625rem/3.25rem Roboto, sans-serif',
  Display2: '500 2rem/2.5rem Roboto, sans-serif',
  Header1: 'lighter 1.5rem/2rem Roboto, sans-serif',
  Header2: '500 1.25rem/1.75rem Roboto, sans-serif',
  Header3: 'lighter 1.125rem/1.5rem Roboto, sans-serif',
  Link: 'normal 0.875rem/1.25rem Roboto, sans-serif',
}

/**
 * CSS strings that can be used directly or interpolated into any CSS
 */
export const textStyles: typeof fontStyles = {
  Body: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Body};
  `,
  BodyHeavy: `
  color: ${Palette.neutral[100].hex};
  font: ${fontStyles.BodyHeavy};
`,
  BodySmall: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodySmall};`,
  BodySmallCaps: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodySmall};
    text-transform: uppercase;`,
  BodySmallHeavy: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodySmallHeavy};`,
  BodySmallSubdued: `
    color: ${Palette.neutral[80].hex};
    font: ${fontStyles.BodySmall};`,
  BodySmallRed: `
    color: ${Palette.red[80].hex};
    font: ${fontStyles.BodySmall};`,
  BodySmallReversed: ` 
    color: ${Palette.neutral[0].hex};
    font: ${fontStyles.BodySmallReversed};`,
  BodyLarge: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodyLarge};`,
  BodyLargeHeavy: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodyLargeHeavy};`,
  BodyLargeReversed: ` 
    color: ${Palette.neutral[0].hex};
    font: ${fontStyles.BodyLarge};`,
  BodyLargeSubdued: `
    color: ${Palette.neutral[80].hex};
    font: ${fontStyles.BodyLarge};`,
  BodyLargeMedium: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.BodyLargeMedium};`,
  BodyLargeMediumSubdued: `
    color: ${Palette.neutral[80].hex};
    font: ${fontStyles.BodyLargeSubdued};`,
  BodyLargeMediumReversed: ` 
    color: ${Palette.neutral[0].hex};
    font: ${fontStyles.BodyLargeMedium};`,
  Display1: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Display1};`,
  Display2: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Display2};`,
  Header1: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Header1};`,
  Header2: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Header2};`,
  Header3: `
    color: ${Palette.neutral[100].hex};
    font: ${fontStyles.Header3};`,
  Link: `
    text-decoration: none;
    color: ${Palette.blue[80].hex};
    :hover {
      text-decoration: underline;
      color: ${Palette.blue[90].hex};
    }`,
}
