import type { ElementRef } from 'react'
import { useCallback, useRef } from 'react'
import { Key } from 'ts-keycode-enum'
import type { ValueProps } from './useControlled'
import { useControlled } from './useControlled'
import { useKeyDown } from './useKeyDown'
import { useCircularNavigation } from './useCircularNavigation'

const keys = [Key.UpArrow, Key.DownArrow, Key.LeftArrow, Key.RightArrow]
type RadioRef = ElementRef<'button'>
export const useRadioGroup = (props: { values: string[]; disabled?: boolean } & ValueProps<string>) => {
  const refs = useRef<RadioRef[]>([])
  const [value, handleChange] = useControlled({ ...props, type: 'string' })
  const { values, disabled } = props
  const getRadioProps = useCallback(
    (idx: number) => ({
      'aria-checked': value === values[idx],
      // aria-value isn't necessary for button
      // 'aria-value': values[idx],
      value: values[idx],
      disabled,
      onClick: handleChange,
      ref: (ref: RadioRef) => (refs.current[idx] = ref),
      tabIndex: values.findIndex(i => i === value) === idx ? 0 : -1,
    }),
    [disabled, handleChange, value, values],
  )

  const handleKeyDown = useKeyDown(keys)
  const callback = useCircularNavigation({ refs, currentIndex: values.findIndex(i => i === value) })
  const getRadioGroupProps = useCallback(
    () => ({
      disabled,
      onKeyDown: handleKeyDown(callback),
    }),
    [disabled, handleKeyDown, callback],
  )

  return { getRadioProps, getRadioGroupProps }
}
