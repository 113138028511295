import React from 'react'
import type { SVGPropsWithSize } from './types'
import SmallTimes from './SmallTimes'
const Times = (props: SVGPropsWithSize<SVGSVGElement>) => {
  const { size = 'large', ...rest } = props
  const dimension = size === 'small' ? '1rem' : '1.25rem'
  if (size === 'small') {
    return <SmallTimes {...props} />
  }
  return (
    <svg role="img" display="block" width={dimension} height={dimension} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M11.273 10.0002L15.1191 6.15407L15.9122 5.36092C16.0293 5.24391 16.0293 5.05378 15.9122 4.93678L15.0636 4.08813C14.9466 3.97112 14.7565 3.97112 14.6395 4.08813L10.0002 8.7274L5.36092 4.08775C5.24391 3.97075 5.05378 3.97075 4.93678 4.08775L4.08775 4.9364C3.97075 5.05341 3.97075 5.24354 4.08775 5.36054L8.7274 10.0002L4.08775 14.6395C3.97075 14.7565 3.97075 14.9466 4.08775 15.0636L4.9364 15.9122C5.05341 16.0293 5.24354 16.0293 5.36054 15.9122L10.0002 11.273L13.8463 15.1191L14.6395 15.9122C14.7565 16.0293 14.9466 16.0293 15.0636 15.9122L15.9122 15.0636C16.0293 14.9466 16.0293 14.7565 15.9122 14.6395L11.273 10.0002Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default Times
