import styled from 'styled-components'
import { fontStyles } from '@style'
import { defaultTheme } from '@themes'
export * from './hooks'
export * from './FacetCheckbox'
export * from './FilterBox'
export * from './types'

export const ALL_ITEMS_KEY = 'ALL_ITEMS'
export const FacetTitle = styled.span`
  ${({ theme }) => `
padding: 0;
color: ${theme.neutrals.alternate.hex};
  font: ${fontStyles.BodyHeavy};`}
`
FacetTitle.displayName = 'FacetTitle'
FacetTitle.defaultProps = {
  theme: defaultTheme,
}

export const FacetBox = styled.div`
  border: none;
  padding: 0;
  width: 12.5rem;
  text-align: left;
`
FacetBox.defaultProps = {
  role: 'group',
}
export const FacetListBox = styled.div`
  display: inline-block;
  font: inherit;
  width: 100%;
`
