import { useToasts } from 'react-toast-notifications'
import type { UseToasts } from './types'
export type { Placement, Options } from './types'
export { NotificationType } from './types'
export { ToastProvider } from './ToastProvider'

// This hides the internal API that exists within useToasts
const ut: UseToasts = (useToasts as unknown) as UseToasts

export { ut as useToasts }
