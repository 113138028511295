import React from 'react'
const CircleChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="circle-checked"
      fill="currentColor"
      fillRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58171 18 2 14.4183 2 10C2 5.58171 5.58171 2 10 2C14.4183 2 18 5.58171 18 10ZM9.07464 14.2359L15.0101 8.30045C15.2117 8.0989 15.2117 7.7721 15.0101 7.57055L14.2802 6.84064C14.0787 6.63906 13.7519 6.63906 13.5503 6.84064L8.70968 11.6812L6.44971 9.42126C6.24816 9.21971 5.92135 9.21971 5.71977 9.42126L4.98987 10.1512C4.78832 10.3527 4.78832 10.6795 4.98987 10.8811L8.34471 14.2359C8.54629 14.4375 8.87306 14.4375 9.07464 14.2359Z"
    />
  </svg>
)

export default CircleChecked
