import { useRef, useCallback, useEffect } from 'react'
import type { CheckboxItem } from '../types'

export const useOnChange = <TValue extends string | number>(selectedItems: CheckboxItem<TValue>[], onChange?: (values: TValue[]) => void) => {
  const firstCall = useRef(true)
  const count = useRef(selectedItems.length)

  const callback = useCallback(
    (items: CheckboxItem<TValue>[]) => {
      if (count.current !== items.length) {
        count.current = items.length
        onChange?.(items.map(i => i.value))
      }
    },
    [onChange],
  )

  useEffect(() => {
    if (firstCall.current) {
      firstCall.current = false
      return
    }
    callback(selectedItems)
  }, [selectedItems, callback])
}
