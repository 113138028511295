import React, { useRef, useCallback, useMemo } from 'react'
import type { MutableRefObject } from 'react'
import Downshift from 'downshift'
import type { SelectItem } from '@components/forms'
import { useControlled, ComboBoxContainer, OptionsList, OptionListItem, ValidationMessage, useWindowResizeHandler, FieldContainer } from '../shared'

import { FilterBox } from './FilterBox'
import { PillBox } from './PillBox'
import type { ControllerStateAndHelpers, DownshiftProps } from 'downshift'
import { fuseSearch } from '@components/fuseSearch'
import type { ComboBoxProps, SelectItemType, SelectValuesType } from './types'

// eslint-disable-next-line @typescript-eslint/ban-types
const DefaultTemplate = <D extends {}>({ item }: { item: SelectItemType<D> }) => <>{item.label}</>

interface ComboBoxComponentProps<TData> {
  comboBoxProps: ComboBoxProps<TData>
  downshiftProps: ControllerStateAndHelpers<SelectItem<TData>>
  unselectItem: (item: SelectItemType<TData>) => void
  rootProps: any
}
type ExtendedProps<TData> = { selectionType: 'single'; value: SelectItemType<TData> } | { selectionType: 'multiple'; value: SelectItemType<TData>[] }

const ComboBoxComponent = <TData extends AnyObjectGenericPlaceholder>(props: ComboBoxComponentProps<TData> & ExtendedProps<TData>) => {
  const { className, disabled = false, helpText, items, label, name, placeholder, validation, template: ItemTemplate = DefaultTemplate, fuzzySearchOptions } = props.comboBoxProps
  const validItems: SelectItemType<TData>[] = useMemo(
    () =>
      (props.selectionType === 'multiple'
        ? (items as SelectItemType<TData>[]).filter(i => !props.value.some(v => v.value === i.value))
        : props.value !== undefined && props.value !== null
        ? [props.value]
        : items) as SelectItemType<TData>[],
    [items, props.selectionType, props.value],
  )
  const fuse = useMemo(() => fuseSearch(validItems, fuzzySearchOptions), [fuzzySearchOptions, validItems])

  const { getItemProps, getInputProps, getLabelProps, getMenuProps, getToggleButtonProps, inputValue, openMenu, reset, highlightedIndex, isOpen } = props.downshiftProps
  const toggleButtonProps = getToggleButtonProps({
    tabIndex: -1,
    'aria-expanded': isOpen,
  })
  const visibleItems = useMemo(() => {
    const newItems = inputValue !== null && inputValue ? fuse.search(inputValue).map(r => r.item) : validItems
    return newItems
  }, [fuse, inputValue, validItems])

  const containerRef: MutableRefObject<HTMLDivElement | undefined> = useRef()
  const refCallback = useCallback(
    r => {
      containerRef.current = r
      toggleButtonProps.ref = r
    },
    [toggleButtonProps],
  )

  const resizeHandler = useCallback(() => {
    isOpen && openMenu()
  }, [isOpen, openMenu])
  useWindowResizeHandler(resizeHandler)
  const width = containerRef.current?.offsetWidth
  const filterBoxInputProps = {
    name,
    getInputProps: getInputProps,
    getToggleButtonProps: () => toggleButtonProps,
    placeholder,
    isOpen,
    reset,
    disabled,
    validation,
    selectionType: props.selectionType,
  }
  return (
    <ComboBoxContainer {...props.rootProps} className={className}>
      <FieldContainer>
        <label {...getLabelProps()}>{label}</label>
        {props.selectionType === 'multiple' && <PillBox items={props.value} onRemove={props.unselectItem} />}
        <FilterBox {...filterBoxInputProps} isClearable={Boolean(props.selectionType === 'single' && props.value)} isOpen={isOpen} ref={refCallback} />
      </FieldContainer>
      <OptionsList validation={validation} isOpen={isOpen} {...getMenuProps({ 'aria-multiselectable': props.selectionType === 'multiple' })} listWidth={width}>
        {isOpen &&
          visibleItems.map((item: any, index) => (
            <OptionListItem isHighlighted={highlightedIndex === index} key={`${item.value}${index}`} {...getItemProps({ item, index })}>
              <ItemTemplate item={item} />
            </OptionListItem>
          ))}
      </OptionsList>
      <ValidationMessage name={name} helpText={helpText} validation={validation} />
    </ComboBoxContainer>
  )
}

const getDefaultValue = <TData extends AnyObjectGenericPlaceholder>(props: ComboBoxProps<TData>): SelectValuesType<TData> | undefined => {
  if ('value' in props) {
    return undefined
  } else {
    return props.defaultValue
  }
}

const isSelectItemArray = <TData extends any>(value: SelectItemType<TData> | SelectItem<TData> | SelectItem<TData>[] | undefined): boolean => Array.isArray(value)

export const ComboBox = <TData extends AnyObjectGenericPlaceholder>(comboBoxProps: ComboBoxProps<TData>): JSX.Element => {
  const itemToString = useCallback((item: SelectItemType<TData> | null) => item?.label ?? '', [])
  const defaultValue = getDefaultValue(comboBoxProps)
  const [value, handleChange, isControlled] = useControlled<SelectValuesType<TData>>({
    ...comboBoxProps,
    defaultValue,
    type: 'object',
  } as any)
  const selectionType = !Array.isArray(value) ? 'single' : 'multiple'
  const downshiftValue = !Array.isArray(value) ? (value as SelectItemType<TData>) : null
  // TODO: The containing div needs to be removed and the label/button/options wrapped by FieldContainer
  const controlledParams = isControlled ? { selectedItem: downshiftValue } : { initialSelectedItem: downshiftValue as NonNullable<typeof downshiftValue> }
  // TODO: These assertions are bad and should feel badB
  const multiOnChange = useCallback(
    (selectedItem: SelectItemType<TData>) => {
      if (isSelectItemArray(value)) {
        const valArr = value as SelectItemType<TData>[]
        if (valArr.some(i => i.value === selectedItem.value)) {
          const values = valArr.filter(v => v.value !== selectedItem.value) as SelectValuesType<TData>
          handleChange(values)
        } else {
          handleChange([...valArr, selectedItem] as SelectValuesType<TData>)
        }
      } else {
        handleChange(selectedItem as SelectValuesType<TData>)
      }
    },
    [handleChange, value],
  )
  const StateReducer = useCallback<NonNullable<DownshiftProps<any>['stateReducer']>>(
    (state, changes) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
          multiOnChange(changes.selectedItem)
          return {
            ...changes,
            selectedItem: null,
            isOpen: state.isOpen,
            // If the last item is selected, nothing will be highlighted after this selection.
            // We could fix this by knowing how many valid items there are in the filtered collection,
            // but that requires knowledge of the downshift props, and I don't think that's worth it.
            highlightedIndex: state.highlightedIndex,
            inputValue: '',
          }
        default:
          return changes
      }
    },
    [multiOnChange],
  )
  return (
    <Downshift {...controlledParams} onChange={handleChange as any} itemToString={itemToString} stateReducer={selectionType === 'multiple' ? StateReducer : undefined}>
      {(props: ControllerStateAndHelpers<SelectItemType<TData>>) => {
        // We are applying the ref correctly within SelectComponent, so we're gonna suppress the error
        // https://github.com/downshift-js/downshift/issues/235
        return (
          <ComboBoxComponent
            rootProps={props.getRootProps({} as any, { suppressRefError: true })}
            comboBoxProps={comboBoxProps}
            selectionType={selectionType}
            downshiftProps={props as any}
            unselectItem={multiOnChange}
            // This is a hack to get around downshift's conflicting types for controlled/uncontrolled
            // We should attempt to clean it up, but it's hidden from our consumers
            value={value as any}
          />
        )
      }}
    </Downshift>
  )
}
ComboBox.displayName = 'ComboBox'
