import 'styled-components'
import { Palette } from '@style'

export const isTheme = (obj?: Record<string, unknown>) => {
  if (!obj) return false
  const hasKeys = Object.keys(defaultTheme).map(k => Object.prototype.hasOwnProperty.call(obj, k))
  return !hasKeys.includes(false)
}

export const defaultTheme = {
  name: 'Default',
  breakpoints: {
    small: { min: '576px', max: '767.98px' },
    medium: { min: '768px', max: '991.98px' },
    large: { min: '992px', max: '1199.98px' },
    extraLarge: { min: '1200px', max: Infinity },
  },
  colors: {
    primary: Palette.teal[90],
    secondary: Palette.neutral[0],
    tertiary: Palette.neutral[5],
    quaternary: Palette.teal[100],
    quinary: Palette.green[80],
    senary: Palette.teal[10],
    septenary: Palette.teal[5],
    octonary: Palette.teal[80],
    alternate: Palette.teal[60],
  },
  neutrals: {
    primary: Palette.neutral[0],
    secondary: Palette.neutral[5],
    tertiary: Palette.neutral[15],
    quaternary: Palette.neutral[30],
    quinary: Palette.neutral[40],
    senary: Palette.neutral[50],
    septenary: Palette.neutral[10],
    alternate: Palette.neutral[100],
  },
}

type Theme = typeof defaultTheme

declare module 'styled-components' {
  // eslint-disable-next-line
  export interface DefaultTheme extends Theme {}
}
