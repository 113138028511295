import React from 'react'
const SmallChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1rem" height="1rem" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.27806 4.06351L5.92061 4.42119C5.83601 4.50584 5.83601 4.64311 5.92061 4.72778L9.18289 8L5.92063 11.2722C5.83603 11.3569 5.83603 11.4942 5.92063 11.5788L6.27807 11.9365C6.36267 12.0212 6.49984 12.0212 6.58446 11.9365L10.3651 8.15329C10.4497 8.06863 10.4497 7.93137 10.3651 7.84669L6.58444 4.06351C6.49983 3.97883 6.36265 3.97883 6.27806 4.06351Z"
    ></path>
  </svg>
)

export default SmallChevronRight
