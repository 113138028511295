import { useEffect } from 'react'

export const useWindowResizeHandler = (handler: (ev: UIEvent) => any) => {
  useEffect(() => {
    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  })
}
