import _ from 'lodash'
import type { CheckboxItem, CheckType, MetaCheckboxItem, State } from './types'

const MAX_DISPLAYED_ITEMS = 7

const getTopItems = <TValue extends string | number>(
  items: CheckboxItem<TValue>[],
  selectedItems: CheckboxItem<TValue>[],
  allSelected: CheckType,
  { bubble, sort }: { bubble: boolean; sort: boolean },
): MetaCheckboxItem<TValue>[] => {
  const compare = (a: CheckboxItem<TValue>, b: CheckboxItem<TValue>) => a.value === b.value
  if (bubble) {
    const diff = _.differenceWith(items, selectedItems, compare)
    const sortedUnselected = sort ? _.sortBy(diff, ['label']) : diff
    const sortedSelected = sort ? _.sortBy(selectedItems, ['label']) : selectedItems
    // we only want to display unselected items when less than seven items are selected
    const numberOfUnselectedItemsToDisplay = Math.max(0, MAX_DISPLAYED_ITEMS - selectedItems.length)
    return allSelected === 'true'
      ? _.take(sortedSelected, MAX_DISPLAYED_ITEMS).map(i => ({ instance: i, checked: 'true' } as const))
      : [
          ...sortedSelected.map(i => ({ instance: i, checked: 'true' } as const)),
          ..._.take(sortedUnselected, numberOfUnselectedItemsToDisplay).map(i => ({ instance: i, checked: 'false' } as const)),
        ]
  } else {
    const sorted = sort ? _.sortBy(items, ['label']) : items
    return _.take(sorted, MAX_DISPLAYED_ITEMS).map(i => ({ instance: i, checked: selectedItems.find(si => si === i) !== undefined ? 'true' : 'false' } as const))
  }
}

const getAllItems = <TValue extends string | number>(items: CheckboxItem<TValue>[], selectedItems: CheckboxItem<TValue>[]): MetaCheckboxItem<TValue>[] =>
  items.map(item => {
    let itemChecked: MetaCheckboxItem<TValue>['checked']
    if (selectedItems.some(i => i.value === item.value)) {
      itemChecked = 'true'
    } else {
      itemChecked = 'false'
    }
    return {
      instance: item,
      checked: itemChecked,
    }
  })

export const displayItemsSelector = (config: { bubble: boolean; sort: boolean }) => <TValue extends string | number>(
  { filter, ...state }: State<TValue>,
  isExpanded = false,
): MetaCheckboxItem<TValue>[] => {
  if (filter !== '') {
    return state.items.fuse.search(filter).map(fuseResult => ({
      instance: fuseResult.item,
      checked: state.allSelected === 'true' || state.selectedItems.find(i => i.value === fuseResult.item.value) ? 'true' : 'false',
    }))
  } else if (isExpanded) {
    return getAllItems(state.items.list, state.selectedItems)
  } else {
    const sortedSelectedItems = config.sort ? _.sortBy(state.selectedItems, ['label']) : state.selectedItems
    const topItems = getTopItems(state.items.list, sortedSelectedItems, state.allSelected, config)
    return topItems
  }
}
