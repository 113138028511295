import React from 'react'
import { SVG } from './SVG'
const Edit = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size={'large'}>
    <path
      d="M13.5721 12.7789L14.5722 11.779C14.7284 11.6228 15.0003 11.7322 15.0003 11.9571V16.5002C15.0003 17.3282 14.3284 18 13.5003 18H2.50003C1.67189 18 1 17.3282 1 16.5002V5.50183C1 4.67383 1.67189 4.00205 2.50003 4.00205H11.0471C11.269 4.00205 11.3815 4.27076 11.2252 4.43011L10.2252 5.42997C10.1783 5.47683 10.1158 5.50183 10.0471 5.50183H2.50003V16.5002H13.5003V12.9539C13.5003 12.8882 13.5253 12.8258 13.5721 12.7789ZM18.466 6.47356L10.2596 14.6786L7.43451 14.9911C6.61575 15.0817 5.91886 14.3912 6.00948 13.5663L6.32199 10.7417L14.5284 2.53664C15.2441 1.82112 16.4003 1.82112 17.1128 2.53664L18.4629 3.88644C19.1785 4.60196 19.1785 5.76117 18.466 6.47356ZM15.3784 7.43905L13.5628 5.62369L7.7564 11.4322L7.52827 13.4725L9.56894 13.2444L15.3784 7.43905ZM17.4035 4.94879L16.0535 3.59898C15.9253 3.47088 15.7159 3.47088 15.5909 3.59898L14.6253 4.56447L16.441 6.37983L17.4066 5.41434C17.5316 5.28311 17.5316 5.07689 17.4035 4.94879Z"
      fill="currentColor"
    />
  </SVG>
)

export default Edit
