import type { Dispatch } from 'react'
import { useCallback } from 'react'
import type { MetaCheckboxItem } from '../types'

export const useToggleVisible = <TValue extends string | number>(dispatch: Dispatch<any>, displayedItems: MetaCheckboxItem<TValue>[], allVisibleItemsChecked: boolean) =>
  useCallback(() => {
    if (allVisibleItemsChecked) {
      dispatch({ type: 'deselectVisible', payload: displayedItems })
    } else {
      dispatch({ type: 'selectVisible', payload: displayedItems })
    }
  }, [allVisibleItemsChecked, dispatch, displayedItems])
