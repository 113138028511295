/* eslint-disable @typescript-eslint/ban-types */
import type * as ReactTable from 'react-table'
import type { ReactElement } from 'react'
import type { ClassNameProp } from '@components/types'

export interface ExtendedTableOptions {
  itemDescriptor?: string
  totalItems?: number
  hidePagination?: PaginationType
  noDataMessage?: string | ReactElement
  columnOverflow?: Overflow
  tableTitle?: JSX.Element | string
  superheader?: { enable: false } | { enable: true; startingIndex?: number }
}

export interface TableState<D extends {}>
  extends ReactTable.TableState,
    Partial<ReactTable.UsePaginationState<D>>,
    Partial<ReactTable.UseSortByState<D>>,
    Partial<ReactTable.UseFiltersState<D>>,
    Partial<{ showFilters: boolean }> {}

export interface TableInstance<D extends {}>
  extends ReactTable.TableInstance<D>,
    Partial<ReactTable.UsePaginationInstanceProps<D>>,
    Partial<ReactTable.UseSortByInstanceProps<D>>,
    Partial<ReactTable.UseFiltersInstanceProps<D>>,
    Partial<{ toggleFilters: () => void }>,
    ExtendedTableOptions {
  flatRows: Array<Row<D>>
  headers: Array<ColumnInstance<D>>
  headerGroups: Array<HeaderGroup<D>>
  initialState: Partial<TableState<D>>
  rows: Array<Row<D>>
  rowsById: Record<string, Row<D>>
  state: TableState<D>
  tableTitle?: JSX.Element | string
  totalColumnsMinWidth: number
  useCustomFilterButton: boolean
}

export interface TableOptions<D extends {}>
  extends ReactTable.TableOptions<D>,
    Partial<ReactTable.UseSortByOptions<D>>,
    Partial<ReactTable.UsePaginationOptions<D>>,
    Partial<ReactTable.UseFiltersOptions<D>> {
  columns: Array<Column<D>>
  defaultColumn?: Partial<Column<D>>
  initialState?: Partial<TableState<D>>
  title?: JSX.Element | string
}

export interface TableProps<D extends {}> extends ClassNameProp {
  instance: TableInstance<D>
}

export enum OverflowType {
  Wrap = 'wrap',
  Truncate = 'truncate',
}
export type Overflow = 'wrap' | 'truncate'

export enum PaginationType {
  Never = 'never',
  NoData = 'noData',
  SinglePage = 'singlePage',
}
export type Pagination = 'never' | 'noData' | 'singlePage'
export const CellType: Record<string, CellFormat> = {
  Currency: 'currency',
  Decimal: 'decimal',
  Number: 'number',
  Percentage: 'percentage',
  Text: 'text',
}
export type CellFormat = 'currency' | 'decimal' | 'number' | 'percentage' | 'text'

export interface HeaderGroup<D extends {}> extends ReactTable.HeaderGroup<D> {
  headers: Array<ColumnInstance<D>>
}

export interface TableHeaderProps extends ReactTable.TableHeaderProps {
  colSpan: number
  title?: string
}
export interface ColumnInstance<D extends {}>
  extends ReactTable.ColumnInstance<D>,
    Partial<ReactTable.UseResizeColumnsColumnProps<D>>,
    Partial<ReactTable.UseSortByColumnProps<D>>,
    Partial<ReactTable.UseFiltersColumnProps<D>> {
  cellType?: CellFormat
  columnOverflow?: Overflow
  getHeaderProps: (propGetter?: ReactTable.HeaderPropGetter<D>) => TableHeaderProps
}

export interface Cell<D extends {}> extends ReactTable.Cell<D> {
  column: ColumnInstance<D>
}

export interface Column<D extends {}> extends ReactTable.Column<D>, Partial<ReactTable.UseFiltersColumnOptions<D>>, Partial<ReactTable.UseSortByColumnOptions<D>> {
  cellType?: CellFormat
  columnOverflow?: Overflow
  columns?: Array<Column<D>>
}

export interface Row<D extends {}> extends ReactTable.Row<D> {
  cells: Array<Cell<D>>
}

export interface TemporaryDataCellType extends Partial<ReactTable.TableCellProps> {
  value: string | number
  title: string
  cellType?: CellFormat
  headerGroups?: number[]
}
