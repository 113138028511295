import styled from 'styled-components'
import React from 'react'
import { ArrowUp as LongArrowUp, ArrowDown as LongArrowDown, Filter, Times } from '@assets'
import { Palette } from '@style'

export const UpArrow = styled(({ theme, ...props }) => <LongArrowUp {...props} />)`
  color: ${Palette.neutral[70].hex};
  display: unset;
  height: 0.875rem;
  padding-left: 0.5rem;
`
UpArrow.displayName = 'UpArrow'
export const DownArrow = styled(({ theme, ...props }) => <LongArrowDown {...props} />)`
  color: ${Palette.neutral[70].hex};
  display: unset;
  height: 0.875rem;
  padding-left: 0.5rem;
`
DownArrow.displayName = 'DownArrow'

export const TableTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
TableTitleRow.displayName = 'TableTitle'

export const TableFilterButton = styled.button.attrs<{ isFilterVisible?: boolean }>(props => ({
  children: Boolean(props.isFilterVisible) ? <Times /> : <Filter />,
  title: Boolean(props.isFilterVisible) ? 'Close and Reset Filters' : undefined,
}))<{
  isFilterVisible?: boolean
}>`
  color: ${Palette.neutral[80].hex};
  background-color: transparent;
  border: none;
  display: flex;
  margin-bottom: 0.25rem;
  margin-left: auto;
  margin-right: 0.5rem;
  padding: 0.125rem;
  width: 1.25rem;
`
