import styled from 'styled-components'
import { Palette, fontStyles } from '@style'
import type { AppearanceTypes } from 'react-toast-notifications'
import type { FC } from 'react'
import React from 'react'
import { Times, Warning, CircleChecked, Ban, Info } from '@assets'
import { defaultTheme } from '@themes'
import type { ClassNameProp } from '@components/types'

const UnstyledButton = styled.button`
  @media (max-width: ${p => p.theme.breakpoints.medium.max}) {
    height: 1.75rem;
    width: 2.25rem;
  }
  @media (min-width: ${p => p.theme.breakpoints.large.min}) {
    height: 2.25rem;
    width: 2.25rem;
  }
  background-color: transparent;
  border: none;
  color: ${Palette.neutral[100].hex};
  display: flex;
  justify-content: center;
  margin: 0.5rem;
  padding: 0;
  align-items: center;
`
UnstyledButton.defaultProps = {
  theme: defaultTheme,
}

const StyledToastChildren = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem 0 0.75rem 1rem;
`
StyledToastChildren.defaultProps = {
  theme: defaultTheme,
}

const ToastContent = styled.div<{ notificationType: AppearanceTypes; transitionDuration?: number; small?: number; medium?: number; large?: number }>`
  @media (max-width: ${p => p.theme.breakpoints.small.max}) {
    width: ${p => (Boolean(p.small) ? `${p.small}px` : '280px')};
  }
  @media (min-width: ${p => p.theme.breakpoints.medium.min}) and (max-width: ${p => p.theme.breakpoints.medium.max}) {
    width: ${p => (Boolean(p.medium) ? `${p.medium}px` : '728px')};
  }
  @media (min-width: ${p => p.theme.breakpoints.large.min}) {
    width: ${p => (Boolean(p.large) ? `${p.large}px` : '952px')};
  }
  display: flex;
  justify-content: space-between;
  background-color: ${Palette.neutral[0].hex};
  box-sizing: border-box;
  align-items: stretch;
  border-radius: 0.1875rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(71, 71, 71, 0.2);
  border: 0.0625rem solid ${({ notificationType }) => notificationColors[notificationType].hex};
  pointer-events: auto;
  color: ${Palette.neutral[100].hex};
  font: ${fontStyles.BodyLarge};
  margin-bottom: 0.5rem;
  ${props => props.transitionDuration !== undefined && `transition: height ${props.transitionDuration - 100}ms 100ms;`}
`
ToastContent.defaultProps = {
  theme: defaultTheme,
}

const CloseButton: FC<{ onClose: () => void }> = ({ onClose }) => (
  <UnstyledButton onClick={onClose}>
    <Times />
  </UnstyledButton>
)

const Icon = styled(({ className, notificationType }: { notificationType: AppearanceTypes; className?: string }) => <div className={className}>{icons[notificationType]}</div>)`
  @media (max-width: ${p => p.theme.breakpoints.medium.max}) {
    width: 1rem;

    svg {
      display: none;
    }
  }
  @media (min-width: ${p => p.theme.breakpoints.large.min}) {
    width: 3.25rem;
  }
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Palette.neutral[0].hex};
  background-color: ${({ notificationType }) => notificationColors[notificationType].hex};
`
Icon.defaultProps = {
  theme: defaultTheme,
}

interface ToastProps extends ClassNameProp {
  appearance: AppearanceTypes
  onDismiss: (id?: string) => void
  smallWidth?: number
  mediumWidth?: number
  largeWidth?: number
}
export const Toast: FC<ToastProps> = ({ appearance, children, onDismiss, smallWidth, mediumWidth, largeWidth, ...props }) => (
  <ToastContent notificationType={appearance} large={largeWidth} medium={mediumWidth} small={smallWidth} {...props}>
    <Icon notificationType={appearance} />
    <StyledToastChildren>{children}</StyledToastChildren>
    <CloseButton onClose={onDismiss} />
  </ToastContent>
)

const notificationColors = { success: Palette.green[80], warning: Palette.yellow[60], info: Palette.blue[80], error: Palette.red[90] }
const icons = { success: <CircleChecked />, warning: <Warning />, info: <Info />, error: <Ban /> }
