import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Times } from '@assets'
import type { InputValidation } from '../shared'
import { ClearButton, getValidationBorderColor, ValidationType, ChevronButton } from '../shared'
import { Palette } from '@style'
import { defaultTheme } from '@themes'
import type { UseMultipleSelectionActions, UseMultipleSelectionPropGetters } from 'downshift'

export interface FilterBoxProps<TSelectItem extends AnyObjectGenericPlaceholder>
  extends Partial<Pick<UseMultipleSelectionActions<TSelectItem>, 'removeSelectedItem'>>,
    Partial<Pick<UseMultipleSelectionPropGetters<TSelectItem>, 'getSelectedItemProps'>> {
  name: string
  disabled: boolean
  placeholder?: string
  selectionType: 'single' | 'multiple'
  isClearable: boolean
  isOpen: boolean
  validation?: InputValidation
  reset: (value: { selectedItem: null; inputValue: '' }) => void
  getInputProps: () => any
  getToggleButtonProps: () => any
}

export const FilterBoxContainer = styled.div.attrs<{ isOpen: boolean; validation?: InputValidation; isDisabled: boolean; isMultipleSelect: boolean }>(props => ({
  borderColor: getValidationBorderColor(props, props.theme.neutrals.senary.hex),
  focusBorderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex),
  borderRadiusTop: props.isMultipleSelect ? '0 0' : '0.1875rem 0.1875rem',
  theme: props.theme,
}))<{ isOpen: boolean; validation?: InputValidation; borderColor?: string; focusBorderColor?: string; isDisabled: boolean; borderRadiusTop?: string; isMultipleSelect: boolean }>`
  align-items: center;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadiusTop} 0.1875rem 0.1875rem;
  border: solid 0.0625rem ${props => props.borderColor};

  :focus-within,
  :focus {
    border: solid 0.0625rem ${props => props.focusBorderColor};
    box-shadow: 0 0 0 0.0625rem ${props => props.focusBorderColor};
  }

  ${p => p.isMultipleSelect && 'margin-top: -1px;'}

  /* This ensures the borders are always applied at the same time
     as the OptionsList relies on isOpen to color the border */
  ${p =>
    p.isOpen &&
    `border: solid 0.0625rem ${p.focusBorderColor};
  box-shadow: 0 0 0 0.0625rem ${p.focusBorderColor};
  `}

  background-color: ${props => {
    if (props.validation?.type === ValidationType.Error) return Palette.red[5].hex
    if (props.isDisabled) return Palette.neutral[15].hex
    return props.theme.neutrals.primary.hex
  }};
  display: flex;
  @media (min-width: ${props => props.theme.breakpoints.large.min}) {
    max-width: 20rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.medium.max}) {
    width: 100%;
  }
  height: 2.75rem;
  outline: none;

  &[aria-expanded='true'] {
    border-bottom: solid 0.0625rem ${Palette.neutral[40].hex};
    border-radius: ${props => props.borderRadiusTop} 0 0;
  }

  input {
    border: none;
    height: 2.375rem;
    background: transparent;
    border-radius: ${props => props.borderRadiusTop} 0.1875rem 0.1875rem;
    box-sizing: border-box;
    flex-basis: 90%;
    flex-grow: 1;
    outline: none;
    padding: 0.7rem 0 0.7rem 1rem;
  }
`
FilterBoxContainer.displayName = 'FilterBoxContainer'
FilterBoxContainer.defaultProps = {
  theme: defaultTheme,
}

const FilterBoxInput = styled.input`
  min-width: 0rem;
`

export const FilterBox = React.forwardRef(
  <TData extends AnyObject>(
    {
      name,
      validation,
      placeholder = 'Select an item',
      disabled,
      isOpen,
      reset,
      getInputProps,
      getToggleButtonProps,
      isClearable,
      selectionType,
      removeSelectedItem,
      getSelectedItemProps,
      ...props
    }: FilterBoxProps<TData>,
    ref?: React.Ref<HTMLDivElement>,
  ) => {
    const clearOnClick = useCallback(() => {
      !disabled && reset({ selectedItem: null, inputValue: '' })
    }, [reset, disabled])
    const clearButtonLabel = useMemo(() => (selectionType === 'multiple' ? 'clear filter' : 'clear selected item'), [selectionType])
    const describedBy = validation ? `${name}_error` : `${name}_helptext`
    const isMultipleSelect = selectionType === 'multiple'
    return (
      <FilterBoxContainer
        validation={validation}
        isOpen={!disabled && isOpen}
        isDisabled={disabled}
        isMultipleSelect={isMultipleSelect}
        {...props}
        ref={ref}
        aria-expanded={isOpen}
      >
        <FilterBoxInput aria-describedby={describedBy} aria-invalid={!!validation} {...getInputProps()} placeholder={placeholder} disabled={disabled} />
        {isClearable && (
          <ClearButton aria-label={clearButtonLabel} role="button" aria-disabled={disabled} onClick={clearOnClick} tabIndex={-1}>
            <Times role={undefined} />
          </ClearButton>
        )}
        <ChevronButton {...getToggleButtonProps()} disabled={disabled} />
      </FilterBoxContainer>
    )
  },
)
