import React from 'react'
import { SVG } from './SVG'

const TrashAlt = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size={'large'}>
    <path
      d="M11.375 15H12.125C12.2245 15 12.3198 14.9605 12.3902 14.8902C12.4605 14.8198 12.5 14.7245 12.5 14.625V7.875C12.5 7.77554 12.4605 7.68016 12.3902 7.60984C12.3198 7.53951 12.2245 7.5 12.125 7.5H11.375C11.2755 7.5 11.1802 7.53951 11.1098 7.60984C11.0395 7.68016 11 7.77554 11 7.875V14.625C11 14.7245 11.0395 14.8198 11.1098 14.8902C11.1802 14.9605 11.2755 15 11.375 15ZM16.5 4.5H13.9247L12.8622 2.72813C12.7288 2.50594 12.5402 2.32209 12.3147 2.19449C12.0892 2.06688 11.8344 1.99988 11.5753 2H8.42469C8.16567 1.99998 7.91106 2.06704 7.68566 2.19464C7.46025 2.32224 7.27172 2.50603 7.13844 2.72813L6.07531 4.5H3.5C3.36739 4.5 3.24021 4.55268 3.14645 4.64645C3.05268 4.74022 3 4.86739 3 5V5.5C3 5.63261 3.05268 5.75979 3.14645 5.85355C3.24021 5.94732 3.36739 6 3.5 6H4V16.5C4 16.8978 4.15804 17.2794 4.43934 17.5607C4.72064 17.842 5.10218 18 5.5 18H14.5C14.8978 18 15.2794 17.842 15.5607 17.5607C15.842 17.2794 16 16.8978 16 16.5V6H16.5C16.6326 6 16.7598 5.94732 16.8536 5.85355C16.9473 5.75979 17 5.63261 17 5.5V5C17 4.86739 16.9473 4.74022 16.8536 4.64645C16.7598 4.55268 16.6326 4.5 16.5 4.5ZM8.37 3.59094C8.38671 3.56312 8.41035 3.54012 8.43862 3.52418C8.46688 3.50824 8.4988 3.49991 8.53125 3.5H11.4688C11.5011 3.49996 11.533 3.50832 11.5612 3.52426C11.5894 3.54019 11.613 3.56317 11.6297 3.59094L12.1753 4.5H7.82469L8.37 3.59094ZM14.5 16.5H5.5V6H14.5V16.5ZM7.875 15H8.625C8.72446 15 8.81984 14.9605 8.89016 14.8902C8.96049 14.8198 9 14.7245 9 14.625V7.875C9 7.77554 8.96049 7.68016 8.89016 7.60984C8.81984 7.53951 8.72446 7.5 8.625 7.5H7.875C7.77554 7.5 7.68016 7.53951 7.60984 7.60984C7.53951 7.68016 7.5 7.77554 7.5 7.875V14.625C7.5 14.7245 7.53951 14.8198 7.60984 14.8902C7.68016 14.9605 7.77554 15 7.875 15Z"
      fill="currentColor"
    />
  </SVG>
)

export default TrashAlt
