import numbro from 'numbro'
import type { CellFormat } from '../types'
export const numberNegativeThreshold = -0.06
export const percentageNegativeThreshold = -0.0006
const formatValue = (value: number | string, format: AnyObject) => (value === null || value === undefined ? '-' : `${numbro(value).format(format)}`)
const formatCurrency = (value: number | string, format: AnyObject) => (value === null || value === undefined ? '-' : `${numbro(value).formatCurrency(format)}`)
export const formatRawValue = (value: string | number, cellType?: CellFormat): string => {
  switch (cellType) {
    case 'percentage':
      return formatValue(value, { output: 'percent', mantissa: 1 })
    case 'currency':
      return formatCurrency(value, { thousandSeparated: true, mantissa: 0 })
    case 'number':
      return formatValue(value, { thousandSeparated: true })
    case 'decimal':
      return formatValue(value, { thousandSeparated: true, mantissa: 1 })
    default:
      return value as string
  }
}
