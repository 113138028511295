import { useCallback } from 'react'
import type { Key } from 'ts-keycode-enum'

export const useKeyDown = (keyCodes: Key[]) => {
  return useCallback(
    (cb: (keyCode: number) => void) => (e: React.KeyboardEvent) => {
      if (keyCodes.includes(e.keyCode)) {
        e.preventDefault()
        e.stopPropagation()
        cb(e.keyCode)
      }
    },
    [keyCodes],
  )
}
