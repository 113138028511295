import type { ComponentProps, FC } from 'react'
import React from 'react'
import styled from 'styled-components'
import { percentageNegativeThreshold, numberNegativeThreshold } from '../shared/Cells'
import { Palette, textStyles } from '@style'
import type { CellFormat, Column, Overflow, ColumnInstance, Cell, TableInstance } from '../types'

import { getElementProps } from '../shared/validation'
import { DownArrow, UpArrow } from '../shared'
import { TableFilters } from '../SimpleTable/styles'

export const Edge: FC<{ column: ColumnInstance<any> }> = ({ column }) => {
  const resizeProps = column.getResizerProps ? { ...column.getResizerProps(), className: `resizer ${Boolean((column as any).isResizing) ? 'isResizing' : ''}` } : {}
  return <div {...resizeProps} />
}

export const TableStyle = styled.table<{ minimumWidth: number }>`
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.minimumWidth}px;
`
TableStyle.displayName = 'TableStyle'

export const TableRow = styled.tr<{ enableSuperheader: boolean }>`
  border: none;
  ${props => !props.enableSuperheader && `display: none;`}

  &:nth-child(odd) {
    background: ${Palette.neutral[0].hex};
  }

  &:nth-child(even) {
    background: ${Palette.neutral[5].hex};
  }
`
TableRow.displayName = 'TableRow'

const TableHeaderWrapper = styled.th<{ headerGroups: number[]; column: Column<any>; showFilters?: boolean }>`
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(71, 71, 71, 0.15), 0 0 0 0.0625rem rgba(37, 37, 37, 0.05);
  background-color: ${Palette.blue.washed.hex};
  color: ${Palette.neutral[100].hex};
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: ${p => (Boolean(p.showFilters) ? 'auto' : '2.5rem')};
  letter-spacing: normal;
  line-height: 2.5rem;
  overflow: hidden;
  padding: 0 1rem 0 1rem;
  position: relative;
  ${props => {
    switch (props.column.cellType) {
      case 'percentage':
      case 'currency':
      case 'number':
      case 'decimal':
        return 'text-align: right;'
      default:
        return 'text-align: left;'
    }
  }}
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  ${props =>
    props.headerGroups.map(v => {
      return `&:nth-of-type(${v}) {
        border-right: 0.0625rem solid ${Palette.neutral[40].hex};
      }`
    })}
  &:last-child {
    border-right: none;
  }

  div.resizer {
    background: transparent;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    width: 0.5rem;
    z-index: 1;
  }
`
TableHeaderWrapper.displayName = 'TableHeaderWrapper'

export const TableHeader: FC<{
  column: ColumnInstance<any>
  enableSuperheader: boolean
  showFilters?: boolean
  headerGroups: ComponentProps<typeof TableHeaderWrapper>['headerGroups']
}> = ({ column, ...props }) => {
  const newProps = getElementProps(
    column,
    [
      Boolean(column.isSorted) && (Boolean(column.isSortedDesc) ? <DownArrow key="downArrow" /> : <UpArrow key="upArrow" />),
      <Edge key="edge" column={column} />,
      props.enableSuperheader && props.showFilters && <TableFilters key="filter">{Boolean(column.canFilter) ? column.render('Filter') : null}</TableFilters>,
    ],
    'Header',
  )
  return <TableHeaderWrapper column={column} scope="col" {...props} {...newProps} />
}

export const TableSuperheader = styled.thead<{ startingIndex?: number; enable: true } | { enable: false }>`
  border-radius: 0.1875rem 0.1875rem 0 0;
  ${({ enable, startingIndex = 1 }: { startingIndex?: number; enable: boolean }) =>
    enable
      ? `
  ${TableRow}:first-child {
    background-color: transparent;
    margin-bottom: 0.5rem;
    border-radius: none;

    & ${TableHeaderWrapper}:nth-child(n + ${startingIndex}) {
      background-color: ${Palette.neutral[0].hex};
      border: none;
      text-align: center;
      color: ${Palette.neutral[80].hex};
      box-shadow: 0 0.0625rem 0.1875rem 0 rgba(63, 63, 68, 0.15), 0 0 0 0.0625rem rgba(63, 63, 68, 0.05);
    }

    & ${TableHeaderWrapper}:nth-child(-n + ${startingIndex}) {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  ${TableRow}:nth-child(2) {
    ${TableHeaderWrapper}:first-child {
      border-radius: 0.1875rem 0 0 0;
    }

    ${TableHeaderWrapper}:last-child {
      border-radius: 0 0.1875rem 0 0;
    }
  }
  `
      : `
      & ${TableHeaderWrapper}:first-child {
        border-radius: 0.1875rem 0 0 0;
      }

      & ${TableHeaderWrapper}:last-child {
        border-radius: 0 0.1875rem 0 0;
      }
    }
  }`}
`
TableSuperheader.displayName = 'TableSuperheader'

export const TableBody = styled.tbody`
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(63, 63, 68, 0.15), 0 0 0 0.0625rem rgba(63, 63, 68, 0.05);
`
TableBody.displayName = 'TableBody'

interface TableDataWrapperProps {
  headerGroups: number[]
  rawValue: string | number
  cellType?: CellFormat
  columnOverflow?: Overflow
}
const TableDataWrapper = styled.td<TableDataWrapperProps>`
  border: none;
  border-right: 0.0625rem solid ${Palette.neutral[30].hex};
  color: ${Palette.neutral[100].hex};
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 0.625rem 1rem;
  ${({ columnOverflow }) => {
    switch (columnOverflow) {
      case 'wrap':
        return `height: auto;
        white-space: normal;`
      case 'truncate':
      default:
        return `letter-spacing: normal;
        text-overflow: ellipsis;
        white-space: nowrap;`
    }
  }}

  overflow: hidden;
  position: relative;

  & > a {
    ${textStyles.Link};
  }

  ${({ cellType, rawValue }) => {
    switch (cellType) {
      case 'percentage':
        return `text-align: right;
        color: ${rawValue <= percentageNegativeThreshold ? `${Palette.red[80].hex}` : 'inherit'};`
      case 'currency':
      case 'decimal':
      case 'number':
        return `text-align: right;
        color: ${rawValue <= numberNegativeThreshold ? `${Palette.red[80].hex}` : 'inherit'};`
      default:
        return 'text-align: left;'
    }
  }}
  ${props =>
    props.headerGroups.map(v => {
      return `&:nth-of-type(${v}) {
        border-right: 0.0625rem solid ${Palette.neutral[40].hex};
      }`
    })}

  &:last-child {
    border-right: none;
  }

  div.resizer {
    background: transparent;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    width: 0.5rem;
    z-index: 1;
  }
`
TableDataWrapper.displayName = 'TableDataWrapper'

export const TableData: FC<{ cell: Cell<any>; instance: TableInstance<any>; headerGroups: TableDataWrapperProps['headerGroups'] }> = ({ cell, instance, ...props }) => {
  const columnOverflow = cell.column.columnOverflow ?? instance.columnOverflow
  const newProps = {
    ...getElementProps(cell, [], 'Cell'),
    rawValue: cell.row.original[cell.column.id],
    cellType: cell.column.cellType,
    headerGroups: props.headerGroups,
    columnOverflow,
  }
  return <TableDataWrapper {...props} {...newProps} />
}
