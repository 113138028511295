import type { Dispatch } from 'react'
import { useCallback } from 'react'
import type { MetaCheckboxItem } from '../types'

export const useToggleCheck = <TValue extends string | number>(dispatch: Dispatch<any>) => {
  return useCallback(
    (item: MetaCheckboxItem<TValue>) => () => {
      if (item.checked === 'true') {
        dispatch({ type: 'itemDeselected', payload: item.instance })
      } else {
        dispatch({ type: 'itemSelected', payload: item.instance })
      }
    },
    [dispatch],
  )
}
