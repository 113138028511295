import type { ElementRef } from 'react'
import { useCallback, useState, useRef, useEffect } from 'react'
import { Key } from 'ts-keycode-enum'
import { useKeyDown } from '@components/forms/shared'
import useEvent from '@react-hook/event'

const mod = (x: number, m: number) => ((x % m) + m) % m
type NavigationKeys = Key.UpArrow | Key.DownArrow | Key.LeftArrow | Key.RightArrow | Key.Home | Key.End
const handleArrows = (keyCode: NavigationKeys, currentFocusedIndex: number, maxIndex: number) => {
  switch (keyCode) {
    case Key.DownArrow:
    case Key.RightArrow: {
      const newIdx = currentFocusedIndex + 1
      return newIdx > maxIndex ? 0 : newIdx
    }
    case Key.UpArrow:
    case Key.LeftArrow: {
      const newIdx = currentFocusedIndex - 1
      return newIdx < 0 ? maxIndex : newIdx
    }
    case Key.Home: {
      return 0
    }
    case Key.End: {
      return maxIndex
    }
  }
}

// If we need to do custom path matching to get the initially selected item, we can use the code from here
// https://github.com/ReactTraining/react-router/blob/84252237e86930a6c78edd7621a6dbd9516c1566/packages/react-router-dom/modules/NavLink.js#L54
const navigationKeys = [Key.UpArrow, Key.DownArrow, Key.LeftArrow, Key.RightArrow, Key.Home, Key.End]
const selectionKeys = [Key.Space, Key.Enter]
type LinkRef = ElementRef<'a'>

export const useNav = () => {
  const refs = useRef<LinkRef[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [focusedIndex, setFocusedIndex] = useState(0)

  const setIndices = (idx: number) => {
    setActiveIndex(idx)
    setFocusedIndex(idx)
  }

  useEffect(() => {
    const onClickEvent = (idx: number) => () => {
      setIndices(idx)
    }
    const currentIndex = refs.current.findIndex(i => i?.classList?.contains('active'))
    setIndices(currentIndex)
    refs.current.forEach((ref, idx) => ref?.addEventListener('click', onClickEvent(idx)))

    return refs.current.forEach((ref, idx) => ref?.removeEventListener('click', onClickEvent(idx)))
  }, [])

  const onKeyDown = useKeyDown([...navigationKeys, ...selectionKeys])(
    useCallback(
      (keyCode: number) => {
        if (selectionKeys.includes(keyCode)) {
          refs.current[focusedIndex].click()
          setActiveIndex(focusedIndex)
        } else {
          const newIdx = handleArrows(keyCode, focusedIndex, refs.current.length - 1)
          const newIndex = mod(newIdx, refs.current.length)
          if (newIndex !== focusedIndex) {
            refs.current[newIndex].focus()
            setFocusedIndex(newIndex)
          }
        }
      },
      [focusedIndex],
    ),
  )

  const getNavItemProps = useCallback(
    (idx: number) => ({
      isActive: activeIndex === idx,
      ref: (ref: LinkRef) => (refs.current[idx] = ref),
      onKeyDown,
      tabIndex: activeIndex === idx ? 0 : -1,
    }),
    [activeIndex, onKeyDown],
  )

  const getNavPanelProps = useCallback((role = 'tabpanel') => ({ role, tabIndex: 0 }), [])

  return { getNavItemProps, getNavPanelProps }
}

const keys = {
  horizontal: {
    directionKey: 'scrollLeft',
    scrollableDimensionKey: 'scrollWidth',
    renderDimensionKey: 'clientWidth',
    scrollDirectionKey: 'left',
  },
  vertical: {
    directionKey: 'scrollTop',
    scrollableDimensionKey: 'scrollHeight',
    renderDimensionKey: 'clientHeight',
    scrollDirectionKey: 'top',
  },
} as const

export const useScrollButtons = ({ direction = 'horizontal' }: { direction: 'vertical' | 'horizontal' } = { direction: 'horizontal' }) => {
  const { directionKey, renderDimensionKey, scrollableDimensionKey, scrollDirectionKey } = keys[direction]

  const ref = useRef<HTMLDivElement>(null)

  const [scrollState, setScrollState] = useState({ [directionKey]: 0, [renderDimensionKey]: 0, [scrollableDimensionKey]: 0 })
  const clientRenderSize = ref.current?.[renderDimensionKey]

  const checkScrollPosition = useCallback(() => {
    const direction = ref.current?.[directionKey] ?? 0
    const renderDimension = ref.current?.[renderDimensionKey] ?? 0
    const scrollableDimension = ref.current?.[scrollableDimensionKey] ?? 0
    setScrollState({ [directionKey]: direction, [renderDimensionKey]: renderDimension, [scrollableDimensionKey]: scrollableDimension })
  }, [directionKey, renderDimensionKey, scrollableDimensionKey])

  useEffect(() => {
    checkScrollPosition()
  }, [checkScrollPosition, clientRenderSize])

  useEvent(ref.current, 'scroll', checkScrollPosition)
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEvent(window, 'resize', checkScrollPosition)
  }

  const canScrollUp = scrollState[directionKey] > 0
  const canScrollDown = scrollState[directionKey] !== scrollState[scrollableDimensionKey] - scrollState[renderDimensionKey]

  const moveTabsScroll = (delta: number) => {
    ref.current?.scrollBy({ [scrollDirectionKey]: -delta, behavior: 'smooth' })
  }
  const scrollDown = () => {
    moveTabsScroll(((scrollState[renderDimensionKey] * 2) / 3) * -1)
  }
  const scrollUp = () => {
    moveTabsScroll((scrollState[renderDimensionKey] * 2) / 3)
  }
  return { canScrollUp, canScrollDown, scrollUp, scrollDown, ref }
}
