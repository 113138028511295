import React from 'react'
const LeftAltArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="arrow-alt-left"
      fill="currentColor"
      fillRule="evenodd"
      d="M10.8535714,15.5964286 L5.55357143,10.3107143 C5.38571429,10.1428571 5.38571429,9.87142857 5.55357143,9.70357143 L10.8535714,4.41785714 C11.0214286,4.25 11.2928571,4.25 11.4607143,4.41785714 L12.1607143,5.11785714 C12.3321429,5.28928571 12.3285714,5.56428571 12.1535714,5.72857143 L8.68571429,9.07857143 L17.5714286,9.07857143 C17.8071429,9.07857143 18,9.27142857 18,9.50714286 L18,10.5071429 C18,10.7428571 17.8071429,10.9357143 17.5714286,10.9357143 L8.68571429,10.9357143 L12.1535714,14.2821429 C12.325,14.45 12.3285714,14.725 12.1607143,14.8928571 L11.4607143,15.5928571 C11.2928571,15.7642857 11.0214286,15.7642857 10.8535714,15.5964286 L10.8535714,15.5964286 Z M3.85714286,16.4357143 L3.85714286,3.57857143 C3.85714286,3.34285714 3.66428571,3.15 3.42857143,3.15 L2.42857143,3.15 C2.19285714,3.15 2,3.34285714 2,3.57857143 L2,16.4357143 C2,16.6714286 2.19285714,16.8642857 2.42857143,16.8642857 L3.42857143,16.8642857 C3.66428571,16.8642857 3.85714286,16.6714286 3.85714286,16.4357143 Z"
    ></path>
  </svg>
)

export default LeftAltArrow
