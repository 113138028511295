import type { ComponentPropsWithRef } from 'react'
import React from 'react'
import styled from 'styled-components'
import { textStyles, Palette, fontStyles } from '@style'
import { defaultTheme } from '@themes'
import { Link } from 'react-router-dom'
import { SmallChevronRight } from '@assets'
import type { BreadcrumbProps } from './types'

const LinkorSpan = ({ to, theme: _theme, ...props }: { to?: string; theme: any }) =>
  to !== undefined ? <Link {...props} to={to} /> : <span {...(props as ComponentPropsWithRef<'span'>)} />
export const Breadcrumb = styled(LinkorSpan)<BreadcrumbProps>`
  ${textStyles.Link};
  text-decoration: none;
  ${props =>
    Boolean(props.to)
      ? `
  &:hover {
    text-decoration: underline;
    color: ${props.theme.colors.primary.hex};
  }
  `
      : `
  color: ${Palette.neutral[50].hex};
  `}
`
Breadcrumb.displayName = 'Breadcrumb'
Breadcrumb.defaultProps = {
  theme: defaultTheme,
}

export const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1.25rem 0;
  height: 1.25rem;
  align-items: center;

  ${Breadcrumb}:last-child {
    font: ${fontStyles.BodyHeavy};
    color: ${props => props.theme.colors.primary.hex};
  }
`
BreadcrumbsContainer.displayName = 'BreadcrumbsContainer'
BreadcrumbsContainer.defaultProps = {
  theme: defaultTheme,
}

export const BreadcrumbDivider = styled(SmallChevronRight)`
  ${textStyles.BodySmall};
  color: ${Palette.neutral['80'].hex};
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
`
BreadcrumbDivider.displayName = 'BreadcrumbDivider'
