import type { FC, ReactNode, ReactElement, PropsWithChildren } from 'react'
import React from 'react'
import styled from 'styled-components'
import { useRouteMatch, Route, Redirect } from 'react-router'
import { Switch } from 'react-router-dom'
import { TinyColor } from '@ctrl/tinycolor'
import { defaultTheme } from '@themes'
import { Palette, textStyles } from '@style'
import { useNav, useScrollButtons } from './hooks'
import { Button } from '@components/Button'
import { ChevronLeft, ChevronRight } from '@assets'
import { NavLink } from '@internal'
import type { ClassNameProp } from '@components/types'

const alphaBackground = new TinyColor(Palette.neutral[80].hex).setAlpha(0.1).toRgbString()

const TabLink = styled(NavLink).attrs(() => ({ role: 'tab' }))`
  border-bottom: 0.1875rem solid ${props => props.theme.neutrals.secondary.hex};
  padding: 0.8125rem 1.125rem;
  user-select: none;
  text-decoration: none;
  transition-duration: 0.25s;
  ${textStyles.Body};

  &.active {
    border-bottom: 0.1875rem solid ${Palette.neutral[70].hex};
    ${textStyles.BodyHeavy};
  }

  &:focus,
  &:hover {
    background-color: ${alphaBackground};
    border-bottom-color: transparent;

    &.active {
      background-color: ${props => props.theme.neutrals.secondary.hex};
      border-bottom-color: ${Palette.neutral[70].hex};
    }
  }

  &:focus {
    outline: 0.0625rem solid ${props => props.theme.neutrals.quinary.hex};

    &.active {
      outline: none;
    }
  }

  &:hover {
    outline: none;
  }
`
TabLink.displayName = 'TabLink'
TabLink.defaultProps = {
  theme: defaultTheme,
}

const TabArrowNavigation = styled(Button)`
  flex-shrink: 0;
  width: 1.75rem;
  height: 2.75rem;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  border-radius: 0;

  & svg {
    align-self: center;
  }
`

const TabsChildren = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;

  /* This is the only way to do this and its sad that w3 hasn't done better. */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`

const getPath = (path: string, prefix: string) => {
  const matchedPath = prefix === '/' ? '' : prefix
  return path.startsWith('/') ? `${matchedPath}${path}` : `${matchedPath}/${path}`
}

export interface TabOptions {
  /**
   * Label content for the tab
   */
  label: string | ReactNode
  /**
   * Relative path for the tab's content
   */
  path: string
}

interface TabHiddenProps {
  onKeyDown: (event: React.KeyboardEvent<HTMLAnchorElement>) => void
  tabIndex: number
}

export const Tab = React.forwardRef<HTMLAnchorElement, TabOptions & TabHiddenProps>((props, ref) => {
  // These should ideally come from getTabProps instead of explicitly generated here
  const match = useRouteMatch()
  const tabPath = getPath(props.path, match.url)
  return (
    <TabLink ref={ref} to={tabPath} tabIndex={props.tabIndex} onKeyDown={props.onKeyDown}>
      {props.label}
    </TabLink>
  )
}) as React.ForwardRefExoticComponent<PropsWithChildren<TabOptions & React.RefAttributes<HTMLAnchorElement>>>
Tab.displayName = 'Tab'

const TabsHolder = styled.div.attrs(() => ({ role: 'tablist' }))`
  display: flex;
  border-bottom: 0.0625rem solid ${props => props.theme.neutrals.tertiary.hex};
  position: relative;
  touch-action: pan-x;
  overscroll-behavior-x: auto;
`
TabsHolder.displayName = 'TabsHolder'
TabsHolder.defaultProps = {
  theme: defaultTheme,
}

const TabContent = styled.div``
TabContent.displayName = 'TabContent'

interface TabBarProps extends ClassNameProp {
  getTabProps: ReturnType<typeof useNav>['getNavItemProps']
}

const TabBar: FC<TabBarProps> = ({ children, className, getTabProps }) => {
  const newChildren = React.Children.map(children, (tab, idx) => {
    return (
      React.isValidElement(tab) &&
      React.cloneElement(tab, {
        ...getTabProps(idx),
      })
    )
  })

  const { canScrollDown, canScrollUp, scrollDown, scrollUp, ref: tabsRef } = useScrollButtons()

  return (
    <TabsHolder className={className}>
      {canScrollUp && <TabArrowNavigation buttonType="icon" aria-label="scroll left" onClick={scrollUp} icon={ChevronLeft} />}
      <TabsChildren ref={tabsRef}>{newChildren}</TabsChildren>
      {canScrollDown && <TabArrowNavigation buttonType="icon" aria-label="scroll right" onClick={scrollDown} icon={ChevronRight} />}
    </TabsHolder>
  )
}
TabBar.displayName = 'TabBar'

export interface TabsProps extends ClassNameProp {
  /**
   * List of `Tab` components
   */
  children: React.ReactNode
}

export const Tabs: FC<TabsProps> = ({ children, className }) => {
  const tabsProps = React.Children.map(children, child => (child as ReactElement).props) as Array<PropsWithChildren<TabOptions>>
  const match = useRouteMatch()
  const { getNavItemProps: getTabProps, getNavPanelProps: getTabPanelProps } = useNav()

  return (
    <>
      <TabBar getTabProps={getTabProps} className={className}>
        {children}
      </TabBar>
      <TabContent {...getTabPanelProps()}>
        <Switch>
          {tabsProps.map(tab => {
            const tabPath = getPath(tab.path, match.path)
            return <Route key={tab.path} path={tabPath} component={() => <>{tab.children}</>} />
          })}
          <Redirect to={getPath(tabsProps[0].path, match.path)} />
        </Switch>
      </TabContent>
    </>
  )
}
Tabs.displayName = 'Tabs'
