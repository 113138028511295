import React from 'react'
import { SVG } from './SVG'
const SmallChevronUp = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size={'small'}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.09526 10.2258L2.63178 10.762C2.75877 10.8889 2.96466 10.8889 3.09167 10.762L8 5.86854L12.9083 10.7619C13.0353 10.8888 13.2412 10.8888 13.3682 10.7619L13.9048 10.2258C14.0317 10.0989 14.0317 9.89312 13.9048 9.76619L8.22993 4.09517C8.10294 3.96828 7.89705 3.96828 7.77004 4.09517L2.09526 9.76622C1.96825 9.89314 1.96825 10.0989 2.09526 10.2258Z"
    />
  </SVG>
)

export default SmallChevronUp
