import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import type { TableInstance } from '../types'
import { RightAltArrow, RightArrow, LeftArrow, LeftAltArrow, Filter } from '@assets'
import { Palette, textStyles } from '@style'

const PagerButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  height: 1.25rem;
  justify-content: center;
  margin: 0 0.625rem;
  outline: inherit;
  padding: 0;
  width: 1.25rem;
  &:disabled {
    color: ${Palette.neutral[50].hex};
    cursor: not-allowed;
  }
`
PagerButton.displayName = 'PagerButton'
PagerButton.defaultProps = {
  type: 'button',
}

const PageInfo = styled.span`
  ${textStyles.Body};
  padding: 0 0.625rem;
`
PageInfo.displayName = 'PageInfo'

const StyledPagination = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
`
StyledPagination.displayName = 'StyledPagination'

// The assertions in this component are not ideal and could be fixed
// if the types for react-table inferred a bit more
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const Pagination: FC<{ instance: TableInstance<any> }> = ({ instance }) => {
  const goToFirstPage = useCallback(() => instance.gotoPage?.(0), [instance])
  const toToPreviousPage = useCallback(() => instance.previousPage?.(), [instance])
  const goToNextPage = useCallback(() => instance.nextPage?.(), [instance])
  const goToLastPage = useCallback(() => instance.gotoPage?.(instance.pageCount! - 1), [instance])

  const isFiltered = Boolean(instance.state.filters && instance.state.filters?.length > 0)
  const totalItems = instance.rows.length

  const pageInfo = useMemo(() => {
    const startingItemIndex = instance.state.pageIndex! * instance.state.pageSize! + 1

    return totalItems
      ? `${instance.itemDescriptor} ${startingItemIndex} - ${Math.min(
          instance.state.pageIndex! * instance.state.pageSize! + instance.state.pageSize!,
          totalItems!,
        )} of ${totalItems}`
      : 'No results'
  }, [instance.state.pageIndex, instance.state.pageSize, totalItems, instance.itemDescriptor])

  return (
    <StyledPagination>
      <PagerButton onClick={goToFirstPage} disabled={!instance.canPreviousPage!}>
        <LeftAltArrow />
      </PagerButton>
      <PagerButton onClick={toToPreviousPage} disabled={!instance.canPreviousPage!}>
        <LeftArrow />
      </PagerButton>
      <PageInfo>
        {pageInfo}
        {isFiltered && <Filter viewBox="0 -2 16 18" height={'.7rem'} color={Palette.neutral[80].hex} />}
      </PageInfo>
      <PagerButton onClick={goToNextPage} disabled={!instance.canNextPage!}>
        <RightArrow />
      </PagerButton>
      <PagerButton onClick={goToLastPage} disabled={!instance.canNextPage!}>
        <RightAltArrow />
      </PagerButton>
    </StyledPagination>
  )
}
Pagination.displayName = 'Pagination'
