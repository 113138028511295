import type { ReactElement, ComponentPropsWithRef } from 'react'
import React from 'react'
import styled from 'styled-components'
import tinycolor from '@ctrl/tinycolor'
import { Button } from '../Button'
import type { ValueProps } from '../forms/shared'
import { useRadioGroup } from '../forms/shared'
import { Palette } from '@style'
import type { ClassNameProp } from '@components/types'

export interface ToggleItem {
  /**
   * The button label
   */
  label: string
  /**
   * The button value
   */
  value: string
}
type ToggleButtonElement = ReactElement<ToggleGroupButtonProps & ComponentPropsWithRef<typeof Button>, typeof ToggleGroupButton>

export interface BaseToggleProps extends ClassNameProp {
  /**
   * A description for screen readers
   */
  'aria-label': string
  disabled?: boolean
  size: 'small' | 'large'
}

export interface GroupChildrenProps extends BaseToggleProps {
  /**
   * Button elements
   */
  children: Array<ToggleButtonElement>
  items?: never
}

export interface GroupConfigProps extends BaseToggleProps {
  /**
   * List of toggle configuration items
   */
  items: ToggleItem[]
  children?: never
}
export type ToggleGroupProps = (GroupChildrenProps | GroupConfigProps) & ValueProps<string>

export interface ToggleGroupButtonProps {
  value: string
}

const ToggleGroupButton = styled(Button).attrs<{ 'aria-checked'?: boolean; value: string }>(p => ({
  buttonType: p['aria-checked'] === true ? 'tertiary' : 'quaternary',
  type: 'button',
  role: 'radio',
}))<{
  ['aria-checked']?: boolean
  value: string
}>`
  background-color: ${props => (props.buttonType === 'tertiary' ? props.theme.neutrals.tertiary.hex : props.theme.neutrals.primary.hex)};
  border: none;
  color: ${props => (props.buttonType === 'tertiary' ? props.theme.neutrals.alternate.hex : Palette.neutral[80].hex)};
  height: 100%;
  min-height: 0;
  padding: ${props => (props.size === 'large' ? '0.75rem' : '.375rem .75rem')};

  :first-child {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right: none;
  }

  /** I am a comment to stop the linter **/
  :last-child {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left: none;
  }

  ${props =>
    props['aria-checked'] !== true
      ? `:not(:disabled):hover:not(:active) {
    background-color: ${props.theme.colors.septenary.hex};
    border: none;
  }
  z-index: 0;
`
      : `:not(:disabled):hover:not(:active) {
    background-color: ${props.theme.neutrals.tertiary.hex};    
    border: none;
  }
  z-index: 1;
  font-weight: 500;`}

  /** I am a comment to stop the linter **/
  :not(:disabled) {
    border: none;
    &:active {
      background-color: ${props => (props.buttonType === 'tertiary' ? props.theme.neutrals.tertiary.hex : props.theme.neutrals.primary.hex)};
      font-weight: 500;
      border: none;
    }
  }

  :not(:first-child):not(:last-child) {
    border-left: none;
    border-radius: unset;
    border-right: none;
    border: none;
  }
`
const shadow = tinycolor(Palette.neutral[100].rgb).setAlpha(0.25).toRgbString()

const ToggleButtonContainer = styled.div<{ size: 'small' | 'large'; disabled?: boolean }>`
  border-radius: 0.25rem;
  border: 0.0625rem solid ${props => (props.disabled === true ? Palette.neutral[30].disabled : Palette.neutral[30].hex)};
  box-sizing: border-box;
  display: flex;
  height: ${props => (props.size === 'large' ? '2.75rem' : '2rem')};
  margin: 0.25rem 0;
  width: fit-content;

  &:focus-within {
    box-shadow: 0 0 0.25rem ${shadow};
  }
`

const ToggleGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ToggleGroup = ({ 'aria-label': ariaLabel, ...props }: ToggleGroupProps) => {
  const { getRadioGroupProps, getRadioProps } = useRadioGroup({
    ...props,
    values: props.children ? props.children.map(i => i.props.value) : props.items.map(i => i.value),
  })
  const mappedChildren = React.Children.map(
    props.children || props.items.map<ToggleButtonElement>(({ value, label }) => <ToggleGroup.Button value={value}>{label}</ToggleGroup.Button>),
    (button, idx) => {
      return (
        React.isValidElement(button) &&
        React.cloneElement(button, {
          ...getRadioProps(idx),
          size: props.size,
        })
      )
    },
  )
  return (
    <ToggleGroupContainer className={props.className}>
      <ToggleButtonContainer role="radiogroup" size={props.size} aria-label={ariaLabel} {...getRadioGroupProps()}>
        {mappedChildren}
      </ToggleButtonContainer>
    </ToggleGroupContainer>
  )
}
ToggleGroup.displayName = 'ToggleGroup'
ToggleGroup.defaultProps = {
  size: 'small',
}
ToggleGroup.Button = ToggleGroupButton
