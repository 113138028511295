import styled, { css } from 'styled-components'
import { textStyles, Palette } from '@style'

export const Link = styled.a
  .attrs(({ sneaky = false }: { sneaky?: boolean }) => ({ sneaky }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['sneaky'].includes(prop) && defaultValidatorFn(prop),
  })<{ sneaky: boolean }>`
  ${textStyles.Link};
  ${props =>
    props.sneaky
      ? css`
          color: ${Palette.neutral[80].hex};
          :hover {
            ${textStyles.Link}
          }
        `
      : ''}
`
