import type { MutableRefObject } from 'react'
import React, { useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Key } from 'ts-keycode-enum'
import type { ControllerStateAndHelpers } from 'downshift'
import Downshift from 'downshift'
import { Times } from '@assets'
import type { BaseDropdownProps, ValueProps } from '../shared'
import {
  FieldContainer,
  ComboBoxContainer,
  DropdownButton,
  ClearButton,
  OptionsList,
  OptionListItem,
  ValidationMessage,
  ChevronButton,
  useWindowResizeHandler,
  useControlled,
  useOnClick,
} from '../shared'

import type { SelectItem } from '../../types'

const DropdownLabel = styled.span<{ isSelected: boolean }>`
  align-items: center;
  ${p => !p.isSelected && 'color: grey'};
  display: flex;
  flex-basis: 90%;
  flex-grow: 1;
  height: 2.375rem;
  line-height: 1.25rem;
  overflow: hidden;
  padding-left: 1rem;
  text-align: start;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
`

interface BaseSelectProps<TData> extends BaseDropdownProps<SelectItem<TData>> {
  /**
   * Makes a button available that clears the selection
   */
  isClearable?: boolean
}

export type SelectProps<TData> = ValueProps<SelectItem<TData>> & BaseSelectProps<TData>

interface SelectComponentProps<TData> {
  selectProps: SelectProps<TData>
  downshiftProps: ControllerStateAndHelpers<SelectItem<TData>>
  value?: SelectItem<TData>
  rootProps: any
}

const SelectComponent = <TData extends AnyObjectGenericPlaceholder>(props: SelectComponentProps<TData>) => {
  const value = props.value
  const { className, disabled = false, helpText, isClearable = true, items, label, name, placeholder, validation } = props.selectProps

  const { getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, openMenu, reset, highlightedIndex, isOpen, toggleMenu } = props.downshiftProps
  const toggleButtonOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement> & { nativeEvent: { preventDownshiftDefault: boolean } }) => {
      if (isOpen && e.keyCode === Key.Escape) {
        toggleMenu()
        e.nativeEvent.preventDownshiftDefault = true
        return
      }
    },
    [isOpen, toggleMenu],
  )
  const { onClick, ...toggleButtonProps } = getToggleButtonProps({
    tabIndex: 0,
    onKeyDown: toggleButtonOnKeyDown,
    'aria-expanded': isOpen,
  })

  const canBeCleared = useMemo(() => Boolean(value && isClearable), [isClearable, value])

  const containerRef: MutableRefObject<HTMLDivElement | undefined> = useRef()
  const refCallback = useCallback(
    r => {
      containerRef.current = r
      toggleButtonProps.ref = r
    },
    [toggleButtonProps],
  )

  const resizeHandler = useCallback(() => {
    isOpen && openMenu()
  }, [isOpen, openMenu])
  useWindowResizeHandler(resizeHandler)
  const width = containerRef.current?.offsetWidth
  const clearOnClick = useOnClick(() => {
    !disabled && reset({ selectedItem: null, inputValue: '' })
  })

  return (
    <ComboBoxContainer {...props.rootProps} className={className}>
      <FieldContainer>
        <label {...getLabelProps()}>{label}</label>
        <DropdownButton
          disabled={disabled}
          aria-disabled={disabled}
          isOpen={isOpen}
          {...toggleButtonProps}
          ref={refCallback}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            !disabled && onClick(e)
          }}
          aria-describedby={validation ? `${name}_error` : `${name}_helptext`}
          aria-invalid={!!validation}
          validation={validation}
        >
          <DropdownLabel isSelected={!!value}>{value?.label ?? placeholder}</DropdownLabel>
          {canBeCleared && (
            <ClearButton aria-label="clear selected item" role="button" aria-disabled={disabled} onClick={clearOnClick} tabIndex={-1}>
              <Times />
            </ClearButton>
          )}
          <ChevronButton as="div" disabled={disabled} aria-disabled={disabled} />
        </DropdownButton>
      </FieldContainer>
      <OptionsList validation={validation} isOpen={isOpen} {...getMenuProps()} listWidth={width}>
        {isOpen &&
          items.map((item, index) => (
            <OptionListItem isHighlighted={highlightedIndex === index} key={`${item.value}${index}`} {...getItemProps({ item, index })}>
              {item.label}
            </OptionListItem>
          ))}
      </OptionsList>
      <ValidationMessage name={name} helpText={helpText} validation={validation} />
    </ComboBoxContainer>
  )
}

export const Select = <TData extends AnyObjectGenericPlaceholder>(selectProps: SelectProps<TData>): JSX.Element => {
  const itemToString = useCallback((item: SelectItem<TData> | null) => item?.label ?? '', [])
  const [value, handleChange, isControlled] = useControlled({ ...selectProps, type: 'object' })

  // TODO: The containing div needs to be removed and the label/button/options wrapped by FieldContainer
  const controlledParams = isControlled ? { selectedItem: value } : { initialSelectedItem: value as NonNullable<typeof value> }
  return (
    <Downshift {...controlledParams} onChange={handleChange} itemToString={itemToString}>
      {(props: ControllerStateAndHelpers<SelectItem<TData>>) => {
        // We are applying the ref correctly within SelectComponent, so we're gonna suppress the error
        // https://github.com/downshift-js/downshift/issues/235
        return (
          <SelectComponent
            rootProps={props.getRootProps({} as any, { suppressRefError: true })}
            selectProps={selectProps}
            downshiftProps={props}
            // This is a hack to get around downshift's conflicting types for controlled/uncontrolled
            // We should attempt to clean it up, but it's hidden from our consumers
            value={value as NonNullable<typeof value>}
          />
        )
      }}
    </Downshift>
  )
}
Select.displayName = 'Select'
