import React, { useMemo } from 'react'
import type { TableProps, Row } from '../types'
import { TableStyle, TableRow, TableColumnHeader, TableBody, TableData, TableHead, TableTitleStyle } from '@components/Table/SimpleTable/styles'
import { Pagination, NoData, TableTitleRow, TableFilterButton } from '@components/Table/shared'
import { shouldShowPagination, areFiltersEnabled } from '../_helpers'

// eslint-disable-next-line @typescript-eslint/ban-types
export function SimpleTable<D extends AnyObjectGenericPlaceholder = {}>({ className, instance }: TableProps<D>) {
  // TODO: The react-table types need to be augmented so the page matches what we have for rows
  const internalRow = ((instance.page as unknown) as Row<D>[]) ?? instance.rows
  const filtersEnabled = useMemo(() => areFiltersEnabled(instance), [instance])
  const { style: _, ...tableProps } = instance.getTableProps()

  // useMemo doesn't do deep equality checking and we need specific properties of instance to trigger recalculation
  // eslint-disable-next-line
  const showPagination = useMemo(() => shouldShowPagination(instance), [instance, instance.hidePagination, instance.data, instance.pageCount])
  const { showFilters } = instance.state
  return (
    <div className={className}>
      <TableTitleRow>
        {Boolean(instance.tableTitle) && <TableTitleStyle>{instance.tableTitle}</TableTitleStyle>}
        {filtersEnabled && !instance.useCustomFilterButton && <TableFilterButton isFilterVisible={showFilters} onClick={instance.toggleFilters} />}
      </TableTitleRow>
      <TableStyle {...tableProps} minimumWidth={instance.totalColumnsMinWidth}>
        <TableHead>
          <TableRow enableSuperheader={true} {...instance.headerGroups[0].getHeaderGroupProps()}>
            {instance.headerGroups[0].headers.map(column => {
              return <TableColumnHeader key={`header-${column.id}`} showFilters={instance.state.showFilters} column={column} />
            })}
          </TableRow>
        </TableHead>
        <TableBody {...instance.getTableBodyProps()}>
          {internalRow.map((row, _) => {
            instance.prepareRow(row)
            return (
              <TableRow enableSuperheader={false} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <TableData key={`cell-${cell.column.id}`} cell={cell} instance={instance} />
                })}
              </TableRow>
            )
          })}
          {instance.data.length === 0 && instance.noDataMessage && <NoData>{instance.noDataMessage}</NoData>}
        </TableBody>
      </TableStyle>
      {showPagination && <Pagination instance={instance} />}
    </div>
  )
}
