import React from 'react'

const SmallTimes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg display="block" width="1rem" height="1rem" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.06155 8.00016L12.2666 4.79506L12.9276 4.1341C13.0251 4.03659 13.0251 3.87815 12.9276 3.78065L12.2204 3.07344C12.1229 2.97594 11.9644 2.97594 11.8669 3.07344L8.00089 6.9395L4.13483 3.07313C4.03733 2.97562 3.87888 2.97562 3.78138 3.07313L3.07386 3.78034C2.97636 3.87784 2.97636 4.03628 3.07386 4.13379L6.94023 8.00016L3.07386 11.8662C2.97636 11.9637 2.97636 12.1222 3.07386 12.2197L3.78107 12.9269C3.87857 13.0244 4.03701 13.0244 4.13452 12.9269L8.00089 9.06081L11.206 12.2659L11.8669 12.9269C11.9644 13.0244 12.1229 13.0244 12.2204 12.9269L12.9276 12.2197C13.0251 12.1222 13.0251 11.9637 12.9276 11.8662L9.06155 8.00016Z"
      fill="currentColor"
    />
  </svg>
)

export default SmallTimes
