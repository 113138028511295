import { useMemo } from 'react'
import { Key } from 'ts-keycode-enum'
import type { CheckboxItem, LargeFacetConfig } from '../types'
import { displayItemsSelector } from '../selectors'
import { useKeyDown } from '@components/forms/shared'
import { useAllVisibleItemsChecked } from './useAllVisibleItemsChecked'
import { useListTitle } from './useListTitle'
import { useToggleIsExpanded } from './useToggleIsExpanded'
import type { ControlledFacetValues, UncontrolledFacetValues } from './useFacetReducer'
import { useFacetReducer } from './useFacetReducer'
import { useOnFilterChange } from './useOnFilterChange'
import { useSelectAll } from './useSelectAll'
import { useToggleCheck } from './useToggleCheck'
import { useToggleVisible } from './useToggleVisible'

const ALL_ITEMS_KEY = ''
interface UseFacetConfig<TValue> extends LargeFacetConfig {
  items: CheckboxItem<TValue>[]
  title: string
  defaultValue?: TValue[]
  onChange: (values: TValue[]) => void
}

export interface UseControlledFacetConfig<TValue> extends LargeFacetConfig {
  items: CheckboxItem<TValue>[]
  title: string
  value: TValue[]
  onChange: (values: TValue[]) => void
}
const EMPTY_ARRAY: any[] = []

export const useFacet = <TValue extends number | string>(
  { bubble = true, selectionType = 'tristate', sort = true, items, onChange, title, ...rest }: UseFacetConfig<TValue> | UseControlledFacetConfig<TValue>,
  ..._hooks: any[]
) => {
  const reducerConfig: ControlledFacetValues<TValue> | UncontrolledFacetValues<TValue> =
    'value' in rest ? { items, onChange, value: rest.value } : { items, onChange, defaultValue: rest.defaultValue ?? EMPTY_ARRAY }
  const [state, dispatch] = useFacetReducer(reducerConfig, { bubble, sort, selectionType })
  const [isExpanded, setIsExpanded] = useToggleIsExpanded()

  const displayedItems = useMemo(() => displayItemsSelector({ bubble, sort })(state, isExpanded), [bubble, isExpanded, sort, state])
  const handleKeyDown = useKeyDown([Key.Space])
  const selectAll = useSelectAll(dispatch, state)

  const toggleCheck = useToggleCheck(dispatch)
  const [listTitle, titleId] = useListTitle(state, title)

  const hasFilter = state.filter !== ''
  const hasFilteredItems = displayedItems.length > 0

  const onFilterChange = useOnFilterChange(dispatch)

  const allVisibleItemsChecked = useAllVisibleItemsChecked(displayedItems)
  const toggleVisible = useToggleVisible(dispatch, displayedItems, allVisibleItemsChecked)
  const selectAllCheckboxProps = { handleKeyDown, selectAll, item: { instance: { value: ALL_ITEMS_KEY, label: `All ${title}` }, checked: state.allSelected } }

  return {
    allSelected: state.allSelected,
    allVisibleItemsChecked,
    displayedItems,
    filter: state.filter,
    handleKeyDown,
    hasFilter,
    hasFilteredItems,
    isExpanded,
    listTitle,
    onFilterChange,
    selectAll,
    selectAllCheckboxProps,
    setIsExpanded,
    showAllCheckbox: selectionType === 'noAllCheckbox' ? false : true,
    titleId,
    toggleCheck,
    toggleVisible,
    unrenderedItemsCount: state.items.list.length - displayedItems.length,
  }
}
