import type { TableProps } from './types'
import { PaginationType } from './types'
import type * as ReactTable from 'react-table'

interface Sortable {
  original: { [K: string]: number }
}

export const numericSort = <T extends Sortable>(a: T, b: T, id: string): -1 | 0 | 1 => {
  const av = a.original[id],
    bv = b.original[id]
  let returnV: -1 | 0 | 1 = 0
  if (av < bv) returnV = -1
  else if (av > bv) returnV = 1
  return returnV
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const shouldShowPagination = <D extends AnyObject = {}>(instance: TableProps<D>['instance']): boolean => {
  let showPagination = false
  if (!isPagination(instance)) {
    return false
  }
  if (instance.hidePagination === PaginationType.Never) {
    showPagination = true
  } else if (instance.hidePagination === PaginationType.NoData && instance.data.length > 0) {
    showPagination = true
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (instance.hidePagination === PaginationType.SinglePage && instance.pageCount! > 1) {
    showPagination = true
  }
  return showPagination
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const areFiltersEnabled = <D extends AnyObject = {}>(instance: TableProps<D>['instance']): boolean => {
  return isFilter(instance)
}

// This should probably use the names from the plugins instead of checking for the existence of a property
const isFilter = (instance: any): instance is ReactTable.UseFiltersInstanceProps<any> => (instance as ReactTable.UseFiltersInstanceProps<any>).setFilter !== undefined

// This should probably use the names from the plugins instead of checking for the existence of a property
const isPagination = (instance: any): instance is ReactTable.UsePaginationInstanceProps<any> => (instance as ReactTable.UsePaginationInstanceProps<any>).page !== undefined
