import React from 'react'
const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="chevron-left"
      d="M13.2634 16.8889L13.889 16.2629C14.037 16.1148 14.037 15.8746 13.889 15.7264L8.17997 10L13.889 4.27359C14.037 4.12544 14.037 3.88523 13.889 3.73705L13.2634 3.11111C13.1154 2.96296 12.8753 2.96296 12.7273 3.11111L6.11104 9.73175C5.96299 9.8799 5.96299 10.1201 6.11104 10.2683L12.7273 16.8889C12.8753 17.037 13.1154 17.037 13.2634 16.8889Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronLeft
