import React from 'react'
const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="chevron-right"
      d="M6.73657 3.11113L6.11104 3.73705C5.96299 3.88519 5.96299 4.1254 6.11104 4.27358L11.82 10L6.11104 15.7264C5.96299 15.8746 5.96299 16.1148 6.11104 16.2629L6.73657 16.8889C6.88462 17.037 7.12467 17.037 7.27275 16.8889L13.889 10.2683C14.037 10.1201 14.037 9.87992 13.889 9.73174L7.27275 3.11113C7.12467 2.96296 6.88462 2.96296 6.73657 3.11113Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronRight
