import React from 'react'

const Warning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.8199167,15.2225833 C18.3326389,16.1113056 17.6890556,17.2222222 16.665,17.2222222 L3.33483333,17.2222222 C2.30880556,17.2222222 1.66819444,16.1095833 2.17991667,15.2225833 L8.84508333,3.66625 C9.35805556,2.77711111 10.6428611,2.77872222 11.1549167,3.66625 L17.8199167,15.2225833 Z M10,12.8333333 C9.29430556,12.8333333 8.72222222,13.4054167 8.72222222,14.1111111 C8.72222222,14.8168056 9.29430556,15.3888889 10,15.3888889 C10.7056944,15.3888889 11.2777778,14.8168056 11.2777778,14.1111111 C11.2777778,13.4054167 10.7056944,12.8333333 10,12.8333333 Z M8.78686111,8.24038889 L8.99291667,12.0181667 C9.00255556,12.1949444 9.14872222,12.3333333 9.32575,12.3333333 L10.67425,12.3333333 C10.8512778,12.3333333 10.9974444,12.1949444 11.0070833,12.0181667 L11.2131389,8.24038889 C11.2235556,8.04944444 11.0715278,7.88888889 10.8803056,7.88888889 L9.11966667,7.88888889 C8.92844444,7.88888889 8.77644444,8.04944444 8.78686111,8.24038889 L8.78686111,8.24038889 Z"
    ></path>
  </svg>
)

export default Warning
