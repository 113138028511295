import React from 'react'
const Ban = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="ban-icon"
      fillRule="evenodd"
      fill="currentColor"
      d="M10 2C5.58171 2 2 5.58171 2 10C2 14.4183 5.58171 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58171 14.4183 2 10 2ZM14.562 5.43803C16.9225 7.79852 17.0475 11.48 15.0769 13.9821L6.01794 4.92306C8.52077 2.95194 12.2021 3.07813 14.562 5.43803ZM5.43803 14.562C3.07752 12.2015 2.95255 8.52 4.92306 6.01794L13.9821 15.0769C11.4792 17.0481 7.7979 16.9219 5.43803 14.562Z"
    />
  </svg>
)

export default Ban
