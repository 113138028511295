import React from 'react'
const ArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="long-arrow-down"
      fill="currentColor"
      fillRule="evenodd"
      d="M13.884375,13.234375 L13.271875,12.621875 C13.121875,12.471875 12.88125,12.475 12.7375,12.628125 L10.3125,15.15 L10.3125,4.375 C10.3125,4.16875 10.14375,4 9.9375,4 L9.0625,4 C8.85625,4 8.6875,4.16875 8.6875,4.375 L8.6875,15.15 L6.265625,12.628125 C6.11875,12.478125 5.878125,12.475 5.73125,12.621875 L5.11875,13.234375 C4.971875,13.38125 4.971875,13.61875 5.11875,13.765625 L9.2375,17.884375 C9.384375,18.03125 9.621875,18.03125 9.76875,17.884375 L13.8875,13.765625 C14.03125,13.61875 14.03125,13.38125 13.884375,13.234375 Z"
    ></path>
  </svg>
)

export default ArrowDown
