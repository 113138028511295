import React, { useState, useMemo, useCallback } from 'react'
import type { Cell } from '../../../types'
import { fuseSearch } from '@components/fuseSearch'
import type { UseComboboxProps, UseComboboxState } from 'downshift'
import { useMultipleSelection, useCombobox } from 'downshift'
import { stateReducer } from './filterStateReducer'
import type { SelectItem } from '@components/types'
import type { FuzzySearchOptions } from '@components'

export const useCheckboxFilter = <TData extends AnyObject>({ column: { preFilteredRows, setFilter, id } }: Cell<TData>, fuzzySearchOptions?: FuzzySearchOptions) => {
  const selectItems = React.useMemo<SelectItem<any>[]>(() => {
    // This should be string | number, but it gets inferred as ReactText
    const items = new Set<any>()
    preFilteredRows?.forEach(row => {
      items.add(row.values[id])
    })
    return Array.from(items.values()).map(o => ({ label: o, value: o }))
  }, [id, preFilteredRows])

  const [optionItems, setOptionItems] = useState(selectItems)

  const fuse = useMemo(() => fuseSearch(selectItems, fuzzySearchOptions), [selectItems, fuzzySearchOptions])

  const onInputValueChange = useCallback(
    ({ inputValue }: Partial<UseComboboxState<SelectItem<any>>>) => {
      setOptionItems(inputValue !== undefined ? fuse.search(inputValue).map(i => i.item) : selectItems)
    },
    [fuse, selectItems],
  )

  const {
    // getDropdownProps applies key and event handlers to the input
    // so that you can remove items with backspace/delete/whatever other magic
    addSelectedItem,
    selectedItems,
    setSelectedItems,
    reset: multiReset,
  } = useMultipleSelection<SelectItem<any>>({})

  const onStateChange: UseComboboxProps<SelectItem<any>>['onStateChange'] = ({ type, selectedItem }) => {
    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick: {
        if (selectedItem) {
          const foundItemIndex = selectedItems.findIndex(sis => sis.value === selectedItem.value)
          if (foundItemIndex >= 0) {
            const filteredItems = selectedItems.slice(0, foundItemIndex).concat(selectedItems.slice(foundItemIndex + 1))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setFilter!(filteredItems.length > 0 ? filteredItems : undefined)
            setSelectedItems(filteredItems)
            selectItem(null as any)
            setInputValue(inputValue)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setFilter!([...selectedItems, selectedItem])
            addSelectedItem(selectedItem)
            selectItem(null as any)
            setInputValue(inputValue)
          }
        }
        break
      }
      default:
        break
    }
  }
  const {
    setInputValue,
    selectItem,
    inputValue,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    reset: comboReset,
  } = useCombobox({
    items: optionItems,
    itemToString: options => options?.label || 'Nothing',
    onInputValueChange,
    onStateChange,
    stateReducer,
  })

  const reset = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setFilter!(undefined)
    setInputValue('')
    multiReset()
    comboReset()
  }

  return { reset, isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps, getInputProps, getComboboxProps, setInputValue, optionItems, selectedItems }
}
