import React from 'react'
import { SVG } from './SVG'
const ChevronUp = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size="large">
    <path
      d="M3.11113 13.2634L3.73708 13.889C3.88523 14.037 4.12544 14.037 4.27362 13.889L10 8.17997L15.7264 13.8889C15.8746 14.037 16.1148 14.037 16.2629 13.8889L16.8889 13.2634C17.037 13.1154 17.037 12.8753 16.8889 12.7272L10.2682 6.11104C10.1201 5.96299 9.87989 5.96299 9.73171 6.11104L3.11113 12.7273C2.96296 12.8753 2.96296 13.1154 3.11113 13.2634Z"
      fill="currentColor"
    />
  </SVG>
)

export default ChevronUp
