import React, { useMemo } from 'react'
import styled from 'styled-components'
import type { ListRowProps } from 'react-virtualized'
import { List, CellMeasurerCache, CellMeasurer } from 'react-virtualized'
import type { FacetCheckboxListProps } from '../shared'
import { FacetCheckbox, ALL_ITEMS_KEY } from '../shared'

const LargeFacetOption = styled(FacetCheckbox)`
  margin-bottom: 0;
  margin-top: 0;
`
LargeFacetOption.displayName = 'LargeFacetOption'

type ItemData<TValue> = Omit<FacetCheckboxListProps<TValue>, 'displayedItems'> & {
  items: FacetCheckboxListProps<TValue>['displayedItems']
}

const Row = <TValue extends number | string>(data: ItemData<TValue>, cache: CellMeasurerCache) => ({
  index,
  style,
  parent,
}: {
  index: number
  style: any
  parent: ListRowProps['parent']
}) => {
  const { items, handleKeyDown, expanded = false } = data
  const item = items[index]
  const isAllCheckbox = item.instance.value === ALL_ITEMS_KEY
  const toggleIt = isAllCheckbox ? data.selectAll : data.toggleCheck(item)
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={item.instance.label} rowIndex={index} parent={parent}>
      <LargeFacetOption style={style} key={item.instance.value} handleKeyDown={handleKeyDown} toggleIt={toggleIt} item={item} expanded={expanded} />
    </CellMeasurer>
  )
}

export const VirtualizedCheckboxList = <TValue extends number | string>({
  displayedItems,
  hasAllCheckbox,
  ...props
}: FacetCheckboxListProps<TValue> & { hasAllCheckbox: boolean }) => {
  const itemData = useMemo(() => {
    return { items: displayedItems, ...props }
  }, [displayedItems, props])
  const height = useMemo(() => 24 * (hasAllCheckbox ? 8 : 7) + 1, [hasAllCheckbox])
  const cacheRef = useMemo(
    () => new CellMeasurerCache({ fixedWidth: true, minHeight: 24 }),
    // useEffect would also work, but mutable is gross
    // eslint-disable-next-line
    [itemData],
  )
  const ItemRow = useMemo(() => Row(itemData, cacheRef), [cacheRef, itemData])
  return <List rowRenderer={ItemRow} rowCount={itemData.items.length} rowHeight={cacheRef.rowHeight} width={200} height={height} itemData={itemData} tabIndex={-1} />
}
