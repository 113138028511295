import React from 'react'
const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="chevron-down"
      fill="currentColor"
      fillRule="evenodd"
      d="M17.8522523,6.84054054 L17.1387387,6.12702703 C16.9693694,5.95765766 16.6954955,5.95765766 16.5261261,6.12702703 L10,12.6387387 L3.47387387,6.12702703 C3.3045045,5.95765766 3.03063063,5.95765766 2.86126126,6.12702703 L2.14774775,6.84054054 C1.97837838,7.00990991 1.97837838,7.28378378 2.14774775,7.45315315 L9.69369369,14.9990991 C9.86306306,15.1684685 10.1369369,15.1684685 10.3063063,14.9990991 L17.8522523,7.45315315 C18.0216216,7.28378378 18.0216216,7.00990991 17.8522523,6.84054054 Z"
    ></path>
  </svg>
)

export default ChevronDown
