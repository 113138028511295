import React from 'react'
const Info = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="solid-info"
      fill="currentColor"
      fillRule="evenodd"
      d="M10,2 C5.58203226,2 2,5.58332258 2,10 C2,14.4192581 5.58203226,18 10,18 C14.4179677,18 18,14.4192581 18,10 C18,5.58332258 14.4179677,2 10,2 Z M10,5.5483871 C10.7482581,5.5483871 11.3548387,6.15496774 11.3548387,6.90322581 C11.3548387,7.65148387 10.7482581,8.25806452 10,8.25806452 C9.25174194,8.25806452 8.64516129,7.65148387 8.64516129,6.90322581 C8.64516129,6.15496774 9.25174194,5.5483871 10,5.5483871 Z M11.8064516,13.7419355 C11.8064516,13.9557097 11.633129,14.1290323 11.4193548,14.1290323 L8.58064516,14.1290323 C8.36687097,14.1290323 8.19354839,13.9557097 8.19354839,13.7419355 L8.19354839,12.9677419 C8.19354839,12.7539677 8.36687097,12.5806452 8.58064516,12.5806452 L8.96774194,12.5806452 L8.96774194,10.516129 L8.58064516,10.516129 C8.36687097,10.516129 8.19354839,10.3428065 8.19354839,10.1290323 L8.19354839,9.35483871 C8.19354839,9.14106452 8.36687097,8.96774194 8.58064516,8.96774194 L10.6451613,8.96774194 C10.8589355,8.96774194 11.0322581,9.14106452 11.0322581,9.35483871 L11.0322581,12.5806452 L11.4193548,12.5806452 C11.633129,12.5806452 11.8064516,12.7539677 11.8064516,12.9677419 L11.8064516,13.7419355 Z"
    ></path>
  </svg>
)

export default Info
