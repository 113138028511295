import React from 'react'
import { SVG } from './SVG'
const SmallChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG {...props} size={'small'}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.9045 5.63156L13.368 5.09544C13.2406 4.96819 13.0347 4.96819 12.9073 5.09544L8 9.98818L3.09269 5.09544C2.96534 4.96819 2.7594 4.96819 2.63204 5.09544L2.09552 5.63156C1.96816 5.75882 1.96816 5.9646 2.09552 6.09186L7.76967 11.7617C7.89703 11.889 8.10297 11.889 8.23033 11.7617L13.9045 6.09186C14.0318 5.9646 14.0318 5.75882 13.9045 5.63156Z"
    />
  </SVG>
)

export default SmallChevronDown
