import { fontStyles, textStyles } from '@style'
import styled from 'styled-components'
export const LargeFacetTitleContainer = styled.div`
  display: flex;
  margin-bottom: 0.3125rem;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FacetToggle = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  ${textStyles.Link};
  font: ${fontStyles.BodySmall};
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  margin-left: -0.3rem;
`
