import React from 'react'
const ArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg role="img" display="block" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="long-arrow-up"
      fill="currentColor"
      fillRule="evenodd"
      d="M5.11 7.761l.614.614c.149.148.39.146.537-.006l2.425-2.523v10.779c0 .207.169.375.376.375h.876a.375.375 0 0 0 .376-.375V5.845l2.425 2.524a.376.376 0 0 0 .537.006l.614-.614a.375.375 0 0 0 0-.53L9.766 3.11a.376.376 0 0 0-.532 0L5.11 7.23a.375.375 0 0 0 0 .531z"
    />
  </svg>
)

export default ArrowUp
