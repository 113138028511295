import React, { useMemo } from 'react'
import warning from 'tiny-warning'
import styled from 'styled-components'
import { FacetBox, FacetTitle, FacetListBox, FacetCheckbox, ALL_ITEMS_KEY, useFacet } from '../shared'
import type { SmallFacetProps } from '../shared/types'

// This should be removed once styles get aligned correctly with LargeFacet virtualization
// to be done in the future.
const SmallFacetTitle = styled(FacetTitle)`
  margin-left: 0.1875rem;
`
export const SmallFacet = <TValue extends number | string>({
  items: inItems,
  title,
  onChange,
  config: { selectionType = 'tristate', sort = true } = { selectionType: 'tristate', sort: true },
  className,
  ...rest
}: SmallFacetProps<TValue>) => {
  warning(inItems.length <= 7, 'SmallFacet items size limit is 7. Only the first 7 items will be rendered.')
  const items = useMemo(() => inItems.slice(0, 7), [inItems])
  const { allSelected, displayedItems, handleKeyDown, listTitle, selectAll, showAllCheckbox, titleId, toggleCheck } = useFacet({
    items,
    onChange,
    title,
    selectionType,
    sort,
    bubble: false,
    ...rest,
  })

  return (
    <FacetBox aria-labelledby={titleId} className={className}>
      <SmallFacetTitle id={titleId}>{listTitle}</SmallFacetTitle>
      <FacetListBox>
        {showAllCheckbox && (
          <FacetCheckbox toggleIt={selectAll} item={{ instance: { value: ALL_ITEMS_KEY, label: `All ${title}` }, checked: allSelected }} handleKeyDown={handleKeyDown} />
        )}
        {displayedItems.map(item => {
          const toggleIt = toggleCheck(item)
          return <FacetCheckbox key={item.instance.value} handleKeyDown={handleKeyDown} toggleIt={toggleIt} item={item} />
        })}
      </FacetListBox>
    </FacetBox>
  )
}

SmallFacet.displayName = 'SmallFacet'
